import React from "react"
import { Wrapper } from "../../../components/templates/wrapper"
import Width from "../../../style/enum/width"
import { Headline } from "../../../components/parts/headline/headline"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  StaticWrapper,
  StaticTitle,
  StaticContentForRule,
  StaticDate,
} from "../components/style"
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile"

const Items = [
  {
    title: "ChatCast利用規約",
    content:
      "本利用規約（以下「本規約」と言います。）には、本サービスの利用条件及び当社と登録ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。",
  },
  {
    title: "第1条（適用）",
    content: `
      <ol>
        <li>本規約は、本サービスの利用条件及び本サービスの利用に関する当社と登録ユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。</li>
        <li>当社が当社ウェブサイト上で掲載する本サービス利用に関するルール等は、本規約の一部を構成するものとします。</li>
        <li>本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約外の説明等を優先させる特段の定めがない限り、本規約の規定が優先して適用されるものとします。</li>
       </ol>
     `,
  },
  {
    title: "第2条（定義）",
    content: `
      <p>本規約において使用する以下の用語は、それぞれ以下に定める意味を有するものとします。</p>
      <ol>
        <li>「「サービス利用契約」とは、本規約及び当社と登録ユーザーの間で締結する、本サービスの利用契約を意味します。</li>
        <li>「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。</li>
        <li>「登録ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録を完了し、当社との間でサービス利用契約を締結した個人または法人を意味します。</li>
        <li>「ゲスト」とは、登録ユーザーがチャットに招待する者を意味します。</li>
        <li>「チャット」とは、登録ユーザーが、ゲストとの間で、文字入力等（画像や動画の添付を含みますがこれらに限られません。）を通じたコミュニケーションをとり、コンテンツ（文章、画像、動画、URLその他のデータを含みますがこれらに限りません。以下同じ。）を創作若しくは取得することを意味します。</li>
        <li>「アーカイブ」とは、登録ユーザーが、チャットの全部または一部を本サービスの機能を利用して保存することまたは保存したデータを意味します。</li>
        <li>「公開データ」とは、登録ユーザーが本サービスの機能を利用して公開（以下「公開」といいます。）したチャット（リアルタイムのチャットであるか、アーカイブであるかは問いません。）内のコンテンツを意味します。</li>
        <li>「当社」とは、株式会社iDEAKITTを意味します。</li>
        <li>「当社ウェブサイト」とは、そのドメインが「chatca.st」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。</li>
        <li>「本サービス」とは、当社が提供するChatCastという名称のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。</li>
      </ol>
      `,
  },
  {
    title: "第3条（登録）",
    content: `
      <ol>
        <li>本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。 </li>
        <li>当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の本サービスの利用者としての登録は、当社が本項の通知を行ったことをもって完了したものとします</li>
        <li>前項に定める登録の完了時に、サービス利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを本規約に従い利用することができるようになります。</li>
        <li>
          当社は、登録申請者が、以下のいずれかの事由に該当する場合は、登録（登録抹消された者の再登録を含みます。）を拒否することがあり、またその理由について一切開示義務を負いません。
          <ul>
            <li>当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合</li>
            <li>未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</li>
            <li>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合</li>
            <li>過去に当社との契約に違反した者またはその関係者であると当社が判断した場合</li>
            <li>第9条に定める措置を受けたことがある場合</li>
            <li>その他、当社が登録を適当でないと判断した場合</li>
          </ul>
        </li>
      </ol>
    `,
  },
  {
    title: "第4条（登録事項の変更）",
    content: `
      <p>登録ユーザーは、第3条第1項の定めに従い当社に提供した登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。</p>
    `,
  },
  {
    title: "第5条（パスワード及びユーザーIDの管理）",
    content: `
      <ol>
        <li>登録ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等（以下「利用等」といいます。）をしてはならないものとします。</li>
        <li>パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の利用等によって生じた損害に関する責任は登録ユーザーが負うものとし、当社は一切の責任を負いません</li>
      </ol>
    `,
  },
  {
    title: "第6条（禁止事項）",
    content: `
      <p>登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。</p>
      <ol>
        <li>法令に違反する行為または犯罪行為に関連する行為</li>
        <li>当社、ゲストまたはその他の第三者に対する詐欺または脅迫行為</li>
        <li>公序良俗に反する行為</li>
        <li>
          以下のいずれかに該当し、または該当すると当社が判断する情報を公開すること
          <ul>
            <li>過度に暴力的または残虐な表現を含む情報</li>
            <li>コンピューター・ウィルスその他コンピューターに有害な影響を与えるコンピューター・プログラムを含む情報</li>
            <li>当社、ゲストまたはその他の第三者の名誉または信用を毀損する表現を含む情報</li>
            <li>過度にわいせつな表現を含む情報</li>
            <li>差別を助長する表現を含む情報</li>
            <li>自殺、自傷行為を助長する表現を含む情報</li>
            <li>薬物（法令等により規制されていないものも含みます。）の不適切な利用を助長する表現を含む情報</li>
            <li>反社会的な表現を含む情報</li>
            <li>チェーンメール等の第三者への情報の拡散を求める情報</li>
            <li>他の登録ユーザーに不快感を与える表現を含む情報</li>
            <li>面識のない異性との出会いを目的とした情報</li>
          </ul>
        </li>
        <li>本サービスのネットワークまたはシステム等に過度な負荷をかける行為</li>
        <li>本サービスの運営を妨害するおそれのある行為</li>
        <li>当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為</li>
        <li>第三者に成りすます行為</li>
        <li>他の登録ユーザーのユーザーIDまたはパスワードを利用する行為</li>
        <li>当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</li>
        <li>他の登録ユーザーの情報の収集</li>
        <li>反社会的勢力等への利益供与</li>
        <li>面識のない異性との出会いを目的とした行為</li>
        <li>前各号の行為を直接または間接に惹起し、または容易にする行為</li>
        <li>その他、当社が不適切と判断する行為</li>
      </ol>
    `,
  },
  {
    title: "第7条（本サービスの停止等）",
    content: `
      <ol>
        <li>
          当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
          <ol>
            <li>本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</li>
            <li>コンピューター、通信回線等が事故により停止した場合</li>
            <li>地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</li>
            <li>その他、当社が停止または中断を必要と判断した場合</li>
          </ol>
        </li>
        <li>当社は、本条に基づき当社が行った措置によって登録ユーザーに生じた損害について一切の責任を負いません。</li>
      </ol>
    `,
  },
  {
    title: "第8条（登録ユーザーの権利及び責任）",
    content: `
      <ol>
        <li>登録ユーザーは、自らの責任において、チャットを行い、アーカイブを作成することができます。当社は、チャットの内容及びアーカイブの作成について、一切責任を負いません。</li>
        <li>チャットまたはアーカイブに生じた知的財産権は、当該チャットまたはアーカイブを創作し、または当該創作に寄与した登録ユーザー及びゲストとの間での定めに従って帰属します。当社は、チャットまたはアーカイブに生じた知的財産権の帰属について、一切責任を負いません。3.登録ユーザーは、チャットを、自らの責任において公開することができます。当社は、公開データの内容やそこに生じる知的財産権の帰属その他の一切の事項について一切責任を負いません。</li>
      </ol>
    `,
  },
  {
    title: "第9条（公開データの削除、登録抹消等）",
    content: `
      <ol>
        <li>
          当社は、登録ユーザーが、以下のいずれかの事由に該当する場合は、事前に通知または催告することなく、当社独自の判断により、当該登録ユーザーによる本サービスの利用を一時的に停止し、または登録ユーザーとしての登録を抹消し、もしくはサービス利用契約を解除することができます。
          <ol>
            <li>本規約のいずれかの条項に違反した場合</li>
            <li>登録事項に虚偽の事実があることが判明した場合</li>
            <li>支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合</li>
            <li>12ヶ月以上本サービスの利用がない場合</li>
            <li>当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合</li>
            <li>第3条第4項に定めるいずれかの事由に該当する場合</li>
            <li>第6条に定める禁止事項を行った場合・その他、当社が本サービスの利用、登録ユーザーとしての登録、またはサービス利用契約の継続を適当でないと判断した場合</li>
          </ol>
        </li>
        <li>前項のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。</li>
        <li>当社は、本条に基づき当社が行った行為により登録ユーザーに生じた損害について一切の責任を負いません。</li>
      </ol>
    `,
  },
  {
    title: "第10条（退会）",
    content: `
      <ol>
        <li>登録ユーザーは、当社所定の方法で当社に通知することにより、本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。</li>
        <li>退会にあたり、当社に対して負っている債務が有る場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。</li>
        <li>退会後の利用者情報の取扱いについては、第14条の規定に従うものとします。</li>
      </ol>
    `,
  },
  {
    title: "第11条（本サービスの内容の変更、終了）",
    content: `
      <ol>
        <li>当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。当社が本サービスの提供を終了する場合、当社は登録ユーザーに事前に通知するものとします。</li>
        <li>当社は、本条に基づき当社が行った措置によって登録ユーザーに生じた損害について一切の責任を負いません。</li>
      </ol>
`,
  },
  {
    title: "第12条（保証の否認及び免責）",
    content: `
      <ol>
        <li>当社は、本サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令または業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。</li>
        <li>当社は、チャットの内容、アーカイブの作成及び公開並びにチャット及びアーカイブに生じた知的財産権の帰属に関して、何人に対しても何ら保証するものではなく、一切責任を負いません </li>
        <li>当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、登録ユーザーが本サービスに送信したメッセージまたは情報（チャット、アーカイブ及びそれらの公開を含みます。）の削除または消失、登録ユーザーの登録の抹消、本サービスの利用による登録データの消失または機器の故障もしくは損傷、その他本サービスに関して登録ユーザーが被った損害（以下「ユーザー損害」といいます。）につき、賠償する責任を一切負わないものとします。</li>
        <li>何らかの理由により当社が責任を負う場合であっても、当社は、ユーザー損害につき、過去12ヶ月間に登録ユーザーが当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。</li>
        <li>本サービスまたは当社ウェブサイトに関連して登録ユーザーと他の登録ユーザーまたはゲストを含む第三者との間において生じた取引、連絡、紛争等については、当社は一切責任を負いません。</li>
      </ol>
    `,
  },
  {
    title: "第13条（秘密保持）",
    content: `
      <p>登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取り扱うことを求めて開示した情報について、当社の事前の書面による承諾がある場合を除き、第三者に開示してはならないものとします。</p>
     `,
  },
  {
    title: "第14条（利用者情報の取扱い）",
    content: `
      <ol>
        <li>当社による登録ユーザーの利用者情報の取扱いについては、別途当社プライバシーポリシー（<a href="/information/privacy">http://chatca.st/information/privacy</a>）の定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取扱うことについて同意するものとします。</li>
        <li>当社は、登録ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録ユーザーはこれに異議を述べないものとします。</li>
      </ol>
    `,
  },
  {
    title: "第15条（本規約の改定等）",
    content: `
      <p>当社は、登録ユーザーの事前の承諾を得ることなく任意に、本規約を改定し、または本規約を補充する規約を定めることができます。本規約の改定または補充がなされた場合、当社は登録ユーザーに対して、当社ウェブサイトへの掲示をもって通知するものとし、当該通知後にも本サービスの利用を継続した登録ユーザーについては、改定または補充された規約を承諾したものとみなします。この場合、登録ユーザーは、改定後の規約及び補充された規約に従うものとします。</p>
    `,
  },
  {
    title: "第16条（連絡・通知）",
    content: `
      <p>本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡または通知、及び本規約の変更に関する通知その他当社から登録ユーザーに対する連絡または通知は、当社の定める方法で行うものとします。</p>
    `,
  },
  {
    title: "第17条（サービス利用契約上の地位の譲渡等）",
    content: `
      <ol>
        <li>登録ユーザーは、当社の書面による事前の承諾なく、サービス利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</li>
        <li>当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</li>
      </ol>
    `,
  },
  {
    title: "第18条（分離可能性）",
    content: `
      <p>本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>
    `,
  },
  {
    title: "第19条（準拠法及び管轄裁判所）",
    content: `
      <ol>
        <li>本規約及びサービス利用契約の準拠法は日本法とします。なお、本サービスにおいて物品の売買が発生する場合であっても、国際物品売買契約に関する国際連合条約の適用を排除することに合意します。</li>
        <li>本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。 </li>
      </ol>
    `,
  },
]

export const TOSIndex = () => (
  <Wrapper maxWidth={Width.STATIC} defaultHorizontalPadding={true}>
    <Headline>
      <FontAwesomeIcon icon={faFile} /> 利用規約
    </Headline>
    {Items.map(item => (
      <StaticWrapper>
        <StaticTitle dangerouslySetInnerHTML={{ __html: item.title }} />
        <StaticContentForRule
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      </StaticWrapper>
    ))}
    <StaticDate>2015年10月01日制定</StaticDate>
  </Wrapper>
)
