import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import { JSONRPCErrorCatch } from "../../../error/error"
import { Loading } from "../../parts/loading"
import { SpaceWithText } from "../../parts/space-with-text"
import { Episode } from "../episode"
import { Episode as EpisodeType } from "../../../rpcs/dist/EpisodeRPC"
import { IContainerProps } from "../../../pages/recent-episode/container"

// 1度の通信で取得できるエピソードの最大数
const MAXIMUM_EPISODE_READ_AT_ONE_TIME = 10

export interface IEpisodeFuncProps {
  lastEpisodeId: number | null
  page: number
}

interface IProps {
  episodes: EpisodeType[] | null
  update: (ep: EpisodeType[]) => void
  reset: () => void
  getEpisodeFunc: (props: IEpisodeFuncProps) => Promise<EpisodeType[]>
}

interface IState {
  hasMore: boolean
}

export class AutoLoadEpisodes extends React.Component<IProps, IState> {
  constructor(props: IContainerProps & IProps) {
    super(props)
    this.loadMore = this.loadMore.bind(this)
    this.getLastEpisodeId = this.getLastEpisodeId.bind(this)

    this.state = {
      hasMore: true,
    }
  }

  public componentDidMount() {
    if (this.props.episodes === null) {
      this.loadMore()
    }
  }

  public componentWillUnmount(): void {
    this.props.reset()
  }

  public render() {
    if (this.props.episodes === null) {
      return <Loading />
    }

    // エピソードがない
    if (this.props.episodes.length === 0) {
      return <SpaceWithText>エピソードがありません</SpaceWithText>
    }

    return (
      <InfiniteScroll
        pageStart={1}
        loadMore={this.loadMore}
        hasMore={this.state.hasMore}
        loader={<Loading key="loading" />}
      >
        {this.props.episodes.map(e => (
          <Episode key={e.id} episode={e} />
        ))}
      </InfiniteScroll>
    )
  }

  private async loadMore(page: number = 1) {
    const lastEpisodeId = this.getLastEpisodeId()
    const res = await this.props
      .getEpisodeFunc({
        lastEpisodeId,
        page,
      })
      .catch(JSONRPCErrorCatch)

    if (res !== -1) {
      if (
        res.length < MAXIMUM_EPISODE_READ_AT_ONE_TIME ||
        res[res.length - 1].id <= 1
      ) {
        this.setState({
          hasMore: false,
        })
      }
      this.props.update(res)
    }
  }

  private getLastEpisodeId(): number | null {
    if (this.props.episodes) {
      return this.props.episodes[this.props.episodes.length - 1].id - 1
    } else {
      return null
    }
  }
}
