import React from "react"
import styled from "styled-components"
import { Channel } from "../../../../../rpcs/dist/ChannelRPC"
import { ChannelListItem } from "./channel"

interface IProps {
  channels: Channel[]
}

export const ChannelList = ({ channels }: IProps) => (
  <List>
    {channels.map((channel, i) => (
      <ChannelListItem key={i} {...channel} />
    ))}
  </List>
)

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`
