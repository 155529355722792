import { LoginData } from "../rpcs/dist/UserRPC"

export function restoreLoginDataFromLocalStorage(): LoginData | null {
  const persisData = localStorage.getItem("persist:root")
  if (!persisData) {
    return null
  }
  const obj = JSON.parse(persisData)
  const loginData = JSON.parse(obj.loginData) as LoginData | null
  if (loginData === null) {
    return null
  }
  if (loginData.jwt && loginData.user) {
    return loginData
  }
  return null
}
