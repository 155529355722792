import React from "react"
import { User } from "../../../../rpcs/dist/UserRPC"
import { LogoSp } from "../../../parts/logo/logoSp"
import { Background } from "../components/background"
import { HeaderUserICon } from "../components/header-user-icon"
import { Notification } from "../components/notification"
import styled from "styled-components"
import { NoShrink } from "../pc/components/no-shrink"
import { ChatStartButton } from "../components/chat-start-button"
import { HeaderLogoWidthForSP } from "../style/variables"

interface IProps {
  currentUser: User
}

export const Authenticated = ({ currentUser }: IProps) => (
  <Background>
    <LogoSp width={HeaderLogoWidthForSP} />
    <Flex>
      <ChatStartButton />
      <Notification />
      <HeaderUserICon currentUser={currentUser} />
    </Flex>
  </Background>
)
const Flex = styled(NoShrink)`
  display: flex;
  align-items: center;
`
