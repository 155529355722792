import { Channel } from "../../rpcs/dist/ChannelRPC"

export interface ICreateRoomState {
  channels: Channel[] | null
}

export const InitialState: ICreateRoomState = {
  channels: null,
}

export enum CreateRoomActionTypes {
  UPDATE_CHANNELS = "@CREATE_ROOM/UPDATE_CHANNELS",
}

export const updateChannels = (channels: Channel[]) =>
  ({
    type: CreateRoomActionTypes.UPDATE_CHANNELS,
    data: {
      channels,
    },
  } as const)
