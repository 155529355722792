import React from "react"
import styled from "styled-components"
import WIDTH from "../../../style/enum/width"

interface IWrapperProps {
  maxWidth?: WIDTH
  children: React.ReactNode
  styledAs?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  className?: string
  defaultHorizontalPadding?: boolean
}

export const Wrapper = React.memo((props: IWrapperProps) => (
  <WrapperStyle {...props} as={props.styledAs}>
    {props.children}
  </WrapperStyle>
))

const WrapperStyle = styled.div<IWrapperProps>`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : WIDTH.DEFAULT)}px;
  ${({ defaultHorizontalPadding }) =>
    defaultHorizontalPadding ? "padding: 0 16px;" : ""};
`
