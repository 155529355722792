import React from "react"
import styled from "styled-components"
import character from "../../../../assets/images/character.svg"
import { Button } from "../button"
import Color from "../../../../style/enum/color"

interface IProps {
  text: string
  buttonText?: string
  buttonAction?: () => void
}

export class ButtonWithCharacter extends React.Component<IProps> {
  public render() {
    return (
      <Wrapper>
        <Character />
        <Text>{this.props.text}</Text>
        {(() => {
          if (this.props.buttonText) {
            return (
              <Button onClick={this.props.buttonAction}>
                {this.props.buttonText}
              </Button>
            )
          }
          return
        })()}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  text-align: center;
  padding: 75px 0;
`

const Character = styled.div`
  width: 85px;
  height: 85px;
  background-size: 85px auto;
  background-image: url(${character});
  background-repeat: no-repeat;
  margin: 0 auto;
`

const Text = styled.p`
  color: ${Color.DIMGRAY};
  font-weight: 700;
  font-size: 18px;
  margin: 15px 0;
`
