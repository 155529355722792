import React from "react"
import { Channel } from "../../../rpcs/dist/ChannelRPC"
import { User } from "../../../rpcs/dist/UserRPC"
import { ChannelIcon } from "../../parts/channel-icon"
import { UserIcon } from "../../parts/user-icon"

interface IProps {
  user: User | null | undefined
  channel: Channel | null | undefined
  size: number
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void
}

export const ContextIcon = (props: IProps) => {
  if (props.channel) {
    return (
      <ChannelIcon
        title={props.channel.name}
        size={props.size}
        onClick={props.onClick}
        profileImage={props.channel.profileImage}
      />
    )
  }

  if (props.user) {
    return (
      <UserIcon
        title={props.user.name}
        size={props.size}
        onClick={props.onClick}
        url={props.user.profileImage.square}
      />
    )
  }

  return null
}
