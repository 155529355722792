import { RouteComponentProps, withRouter } from "react-router"
import { FAQIndex } from "./faq"
import { CompanyIndex } from "./company"
import { TOSIndex } from "./tos"
import { PrivacyIndex } from "./privacy"
import { ContactIndex } from "./contact"

export const StaticRoutes = [
  {
    path: "faq",
    component: FAQIndex,
  },
  {
    path: "company",
    component: CompanyIndex,
  },
  {
    path: "tos",
    component: TOSIndex,
  },
  {
    path: "privacy",
    component: PrivacyIndex,
  },
  {
    path: "contact",
    component: ContactIndex,
  },
]

type IProps = RouteComponentProps<{
  name: string
}>

export default withRouter((props: IProps) =>
  StaticRoutes.find(
    route => route.path === props.match.params.name,
  )!.component(),
)
