import { IChatCastWidgetOptions } from "../../components/templates/chatcast-widget"
import { Episode } from "../../rpcs/dist/EpisodeRPC"

export enum CreateWidgetMode {
  CREATE,
  RESULT,
}

export interface ICreateWidgetState {
  widgetId: number | null
  mode: CreateWidgetMode
  episode: Episode | null
  options: Required<IChatCastWidgetOptions>
}

export const InitialState: ICreateWidgetState = {
  widgetId: null,
  mode: CreateWidgetMode.CREATE,
  episode: null,
  options: {
    channelHeader: true,
    date: true,
    profileIcon: true,
    border: true,
  },
}

export enum CreateWidgetActionTypes {
  updateWidgetId = "@CREATE_WIDGET/UPDATE_WIDGET_ID",
  updateEpisode = "@CREATE_WIDGET/UPDATE_EPISODE",
  changeMode = "@CREATE_WIDGET/CHANGE_MODE",
  updateOptions = "@CREATE_WIDGET/UPDATE_OPTIONS",
}

export const updateWidgetId = (widgetId: number) =>
  ({
    type: CreateWidgetActionTypes.updateWidgetId,
    data: {
      widgetId,
    },
  } as const)

export const updateEpisode = (episode: Episode) =>
  ({
    type: CreateWidgetActionTypes.updateEpisode,
    data: {
      episode,
    },
  } as const)

export const changeMode = (mode: CreateWidgetMode) =>
  ({
    type: CreateWidgetActionTypes.changeMode,
    data: {
      mode,
    },
  } as const)

export const updateOptions = (options: Required<IChatCastWidgetOptions>) =>
  ({
    type: CreateWidgetActionTypes.updateOptions,
    data: {
      options,
    },
  } as const)
