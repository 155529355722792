import React from "react"
import { User } from "../../../../../rpcs/dist/UserRPC"
import { UserIcon } from "../../../../parts/user-icon"
import styled from "styled-components"
import { Modal } from "./modal"

interface IProps {
  currentUser: User
}

interface IStates {
  isShowMenu: boolean
}

export class HeaderUserICon extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isShowMenu: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.setCloseEventListener = this.setCloseEventListener.bind(this)
    this.removeCloseEventListener = this.removeCloseEventListener.bind(this)
  }
  public render() {
    return (
      <Wrapper>
        <StyledUserIcon
          size={40}
          title={"設定とプロフィール"}
          url={this.props.currentUser.profileImage.square}
          onClick={this.toggleMenu}
        />
        {this.state.isShowMenu && <Modal user={this.props.currentUser} />}
      </Wrapper>
    )
  }
  private toggleMenu() {
    this.setState(
      ({ isShowMenu }: IStates) => ({
        isShowMenu: !isShowMenu,
      }),
      () => {
        if (this.state.isShowMenu) {
          this.setCloseEventListener()
        } else {
          this.removeCloseEventListener()
        }
      },
    )
  }
  private setCloseEventListener() {
    document.addEventListener("click", this.toggleMenu, false)
  }
  private removeCloseEventListener() {
    document.removeEventListener("click", this.toggleMenu, false)
  }
}
const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`
const StyledUserIcon = styled(UserIcon)`
  cursor: pointer;
`
