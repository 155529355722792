/* eslint-disable */
import "reflect-metadata"
import * as JSONRPC from "jsonrpc-compiler/lib/core/jsonrpc"
import { plainToClass } from "class-transformer"
import { readCacheIfNeeded, writeCacheIfNeeded } from "../middlewares/cache"

import { ChatRoom } from "./ChatRoomRPC"
import { Episode } from "./EpisodeRPC"
import { Channel } from "./ChannelRPC"

export class User {
  public id: string
  public profileImage: {
    large: string
    medium: string
    thum: string
    square: string
  }
  public email?: string
  public name: string
  public screenName: string
  public description?: string
  public isAnonymous: boolean
}

export class LoginData {
  public user: User
  public jwt: string
}

export class LoginInput {
  public email: string
  public password: string
}

export class RegisterFromEmailInput {
  public name: string
  public screenName: string
  public email: string
  public password: string
  public passwordConfirmation: string
}

export class RegisterFromSNSInput {
  public userId: string
  public email: string
  public name: string
  public screenName: string
  public accessToken: string
  public tokenSecret?: string
}

export class CreateChatRoomInput {
  public scheduled?: {
    // yyyy-MM-DDTHH:mm+09:00
    iso8601StartFrom: string
  }
  public additionalSeconds?: number
  public topic?: string
  public passcode?: string
}

export class Notification {
  public id: string
  public text: string
  public link?: string
  public icon: string
  public createdAt: string
}

export enum Order {
  DESC,
  ASC,
}

export class NotificationPaginationInput {
  public lastId: string
}

export class ReadNotificationInput {
  public ids: string[]
}

export class UserIdBasicInput {
  public userId: string
}

export class UserNameBasicInput {
  public userName: string
}

export class BasicPaginationInput {
  public page?: number
}

export class Empty {}

export class UserProfile extends User {
  public isFollowedByMe?: boolean
}

export class SendPasswordResetInstructionInput {
  public email: string
}

export class ResetPasswordInput {
  public newPassword: string
  public newPasswordConfirmation: string
  public code: string
}

export class ListChanneInput {
  public userId: string
}

export class UpdateUserInput {
  public profileImage?: UpdateProfileImage
  public email?: string
  public screenName?: string
  public description?: string
  public password?: {
    currentPassword: string
    newPassword: string
    newPasswordForConfirmation: string
  }
}

export class UpdateProfileImage {
  base64EncodedData: string
  contentType: string
}

export class FacebookLoginData {
  public accessToken: string
}

export enum SNSLoginStep {
  Registerd,
  Unregisterd,
}

export class TwitterLoginData {
  public accessToken: string
  public tokenSecret: string
  public userId: string
}

export class SNSLoginOutput {
  public loginData?: LoginData
  public step: SNSLoginStep
}

export class SearchUser {
  public text: string
}

export class UnreadNotificationCountOutput {
  public unreadCount: number
}

export class FetchLiveScheduleInput {
  public userId: string
}

interface IOptions {
  timeout: number
  cacheUsable: boolean
}

const optionDefaultValues: IOptions = {
  timeout: 15000,
  cacheUsable: true,
}

export class UserRPC {
  constructor(public endpoint: string, public requestHeaders: { [key: string]: string } = {}) {}

  async registerFromTwitter(input: RegisterFromSNSInput, options?: Partial<IOptions>): Promise<LoginData> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "registerFromTwitter",
        input: input,
      })
      if (cached) {
        return cached as LoginData
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.registerFromTwitter", input)

    return new Promise<LoginData>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(LoginData, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "registerFromTwitter",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.registerFromTwitter",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async registerFromFacebook(input: RegisterFromSNSInput, options?: Partial<IOptions>): Promise<LoginData> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "registerFromFacebook",
        input: input,
      })
      if (cached) {
        return cached as LoginData
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.registerFromFacebook", input)

    return new Promise<LoginData>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(LoginData, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "registerFromFacebook",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.registerFromFacebook",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async registerFromEmail(input: RegisterFromEmailInput, options?: Partial<IOptions>): Promise<LoginData> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "registerFromEmail",
        input: input,
      })
      if (cached) {
        return cached as LoginData
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.registerFromEmail", input)

    return new Promise<LoginData>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(LoginData, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "registerFromEmail",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.registerFromEmail",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async registerAsAnonymousUser(options?: Partial<IOptions>): Promise<LoginData> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    const cached = await readCacheIfNeeded({
      namespace: "UserRPC",
      method: "registerAsAnonymousUser",
    })
    if (cached) {
      return cached as LoginData
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.registerAsAnonymousUser")

    return new Promise<LoginData>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(LoginData, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "registerAsAnonymousUser",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.registerAsAnonymousUser",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async loginFromEmail(input: LoginInput, options?: Partial<IOptions>): Promise<LoginData> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "loginFromEmail",
        input: input,
      })
      if (cached) {
        return cached as LoginData
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.loginFromEmail", input)

    return new Promise<LoginData>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(LoginData, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "loginFromEmail",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.loginFromEmail",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async createChatRoom(input?: CreateChatRoomInput, options?: Partial<IOptions>): Promise<ChatRoom> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "createChatRoom",
        input: input,
      })
      if (cached) {
        return cached as ChatRoom
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.createChatRoom", input)

    return new Promise<ChatRoom>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoom, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "createChatRoom",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.createChatRoom",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listNotifications(input?: NotificationPaginationInput, options?: Partial<IOptions>): Promise<Notification[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "listNotifications",
        input: input,
      })
      if (cached) {
        return cached as Notification[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.listNotifications", input)

    return new Promise<Notification[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Notification, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "listNotifications",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.listNotifications",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async fetchRecentNotificationsFrom(
    input: NotificationPaginationInput,
    options?: Partial<IOptions>,
  ): Promise<Notification[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "fetchRecentNotificationsFrom",
        input: input,
      })
      if (cached) {
        return cached as Notification[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.fetchRecentNotificationsFrom", input)

    return new Promise<Notification[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Notification, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "fetchRecentNotificationsFrom",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.fetchRecentNotificationsFrom",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async bulkReadNotifications(input: ReadNotificationInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "bulkReadNotifications",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.bulkReadNotifications", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "bulkReadNotifications",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.bulkReadNotifications",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async unreadNotificationCount(options?: Partial<IOptions>): Promise<UnreadNotificationCountOutput> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    const cached = await readCacheIfNeeded({
      namespace: "UserRPC",
      method: "unreadNotificationCount",
    })
    if (cached) {
      return cached as UnreadNotificationCountOutput
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.unreadNotificationCount")

    return new Promise<UnreadNotificationCountOutput>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(UnreadNotificationCountOutput, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "unreadNotificationCount",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.unreadNotificationCount",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async follow(input: UserIdBasicInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "follow",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.follow", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "follow",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.follow",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async unfollow(input: UserIdBasicInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "unfollow",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.unfollow", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "unfollow",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.unfollow",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listFollowers(input: UserIdBasicInput, options?: Partial<IOptions>): Promise<User[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "listFollowers",
        input: input,
      })
      if (cached) {
        return cached as User[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.listFollowers", input)

    return new Promise<User[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(User, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "listFollowers",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.listFollowers",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listFolloweds(input: UserIdBasicInput, options?: Partial<IOptions>): Promise<User[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "listFolloweds",
        input: input,
      })
      if (cached) {
        return cached as User[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.listFolloweds", input)

    return new Promise<User[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(User, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "listFolloweds",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.listFolloweds",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listDrafts(input: BasicPaginationInput, options?: Partial<IOptions>): Promise<Episode[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "listDrafts",
        input: input,
      })
      if (cached) {
        return cached as Episode[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.listDrafts", input)

    return new Promise<Episode[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "listDrafts",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.listDrafts",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listChannels(input?: ListChanneInput, options?: Partial<IOptions>): Promise<Channel[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "listChannels",
        input: input,
      })
      if (cached) {
        return cached as Channel[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.listChannels", input)

    return new Promise<Channel[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Channel, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "listChannels",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.listChannels",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async profile(input?: UserNameBasicInput, options?: Partial<IOptions>): Promise<UserProfile> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "profile",
        input: input,
      })
      if (cached) {
        return cached as UserProfile
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.profile", input)

    return new Promise<UserProfile>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(UserProfile, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "profile",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.profile",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listFollowingChannels(input?: UserIdBasicInput, options?: Partial<IOptions>): Promise<Channel[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "listFollowingChannels",
        input: input,
      })
      if (cached) {
        return cached as Channel[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.listFollowingChannels", input)

    return new Promise<Channel[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Channel, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "listFollowingChannels",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.listFollowingChannels",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async sendPasswordResetInstruction(
    input: SendPasswordResetInstructionInput,
    options?: Partial<IOptions>,
  ): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "sendPasswordResetInstruction",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.sendPasswordResetInstruction", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "sendPasswordResetInstruction",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.sendPasswordResetInstruction",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async resetPassword(input: ResetPasswordInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "resetPassword",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.resetPassword", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "resetPassword",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.resetPassword",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async updateUser(input: UpdateUserInput, options?: Partial<IOptions>): Promise<User> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "updateUser",
        input: input,
      })
      if (cached) {
        return cached as User
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.updateUser", input)

    return new Promise<User>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(User, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "updateUser",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.updateUser",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async loginWithFacebook(input: FacebookLoginData, options?: Partial<IOptions>): Promise<SNSLoginOutput> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "loginWithFacebook",
        input: input,
      })
      if (cached) {
        return cached as SNSLoginOutput
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.loginWithFacebook", input)

    return new Promise<SNSLoginOutput>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(SNSLoginOutput, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "loginWithFacebook",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.loginWithFacebook",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async loginWithTwitter(input: TwitterLoginData, options?: Partial<IOptions>): Promise<SNSLoginOutput> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "loginWithTwitter",
        input: input,
      })
      if (cached) {
        return cached as SNSLoginOutput
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.loginWithTwitter", input)

    return new Promise<SNSLoginOutput>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(SNSLoginOutput, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "loginWithTwitter",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.loginWithTwitter",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async myFeed(input?: BasicPaginationInput, options?: Partial<IOptions>): Promise<Episode[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "myFeed",
        input: input,
      })
      if (cached) {
        return cached as Episode[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.myFeed", input)

    return new Promise<Episode[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "myFeed",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.myFeed",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async searchUsers(input: SearchUser, options?: Partial<IOptions>): Promise<User[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "searchUsers",
        input: input,
      })
      if (cached) {
        return cached as User[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.searchUsers", input)

    return new Promise<User[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(User, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "searchUsers",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.searchUsers",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async unsubscribe(options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    const cached = await readCacheIfNeeded({
      namespace: "UserRPC",
      method: "unsubscribe",
    })
    if (cached) {
      return cached as Empty
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.unsubscribe")

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "unsubscribe",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.unsubscribe",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async fetchLiveScheduleFor(input: FetchLiveScheduleInput, options?: Partial<IOptions>): Promise<ChatRoom[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "UserRPC",
        method: "fetchLiveScheduleFor",
        input: input,
      })
      if (cached) {
        return cached as ChatRoom[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "UserRPC.fetchLiveScheduleFor", input)

    return new Promise<ChatRoom[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoom, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "UserRPC",
              method: "fetchLiveScheduleFor",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "UserRPC.fetchLiveScheduleFor",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }
}
