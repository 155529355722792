import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faComment,
  faCompass,
  faEdit,
  faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { Draft } from "./pages/draft"
import { Episode } from "./pages/episode"
import { FollowChannel } from "./pages/follow-channel"
import { MyChannel } from "./pages/my-channel"
import { Myfeed } from "./pages/myfeed"

export enum UserPagePathNames {
  Episode = "",
  Myfeed = "",
  MyChannel = "my-channel",
  FollowChannel = "follow-channel",
  Draft = "draft",
}

export interface ITabData {
  title: string
  path: UserPagePathNames
  icon: IconProp
  component: React.ReactNode
}

export const TabData = (isMe: boolean = false): ITabData[] => {
  const tabs = []

  if (isMe) {
    tabs.push({
      title: "マイフィード",
      path: UserPagePathNames.Myfeed,
      icon: faComment,
      component: <Myfeed />,
    })
  } else {
    tabs.push({
      title: "エピソード",
      path: UserPagePathNames.Episode,
      icon: faComment,
      component: <Episode />,
    })
  }

  tabs.push(
    {
      title: "マイチャンネル",
      path: UserPagePathNames.MyChannel,
      icon: faCompass,
      component: <MyChannel isMe={isMe} />,
    },
    {
      title: "フォローチャンネル",
      path: UserPagePathNames.FollowChannel,
      icon: faStarHalfAlt,
      component: <FollowChannel />,
    },
  )

  if (isMe) {
    tabs.push({
      title: "下書き",
      path: UserPagePathNames.Draft,
      icon: faEdit,
      component: <Draft />,
    })
  }

  return tabs
}
