import React from "react"
import styled, { css } from "styled-components"
import Color from "../../../../../style/enum/color"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCog,
  faCompass,
  faDoorOpen,
  faStarHalfAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { media } from "../../../../../style/const/media-query"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import H from "history"
import { User } from "../../../../../rpcs/dist/UserRPC"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle"

interface IProps {
  user: User
}

interface IItemProps {
  to: H.LocationDescriptor<any>
  icon: IconProp
  title: string
}

const MenuItems = (name: string): IItemProps[] => [
  {
    to: `/user/${name}`,
    icon: faUser,
    title: "マイページ",
  },
  {
    to: `/user/${name}/my-channel`,
    icon: faCompass,
    title: "マイチャンネル",
  },
  {
    to: `/user/${name}/follow-channel`,
    icon: faStarHalfAlt,
    title: "フォローチャンネル",
  },
  {
    to: `/channel/new`,
    icon: faPlusCircle,
    title: "チャンネル新規作成",
  },
  {
    to: "/setting",
    icon: faCog,
    title: "設定",
  },
  {
    to: "/logout",
    icon: faDoorOpen,
    title: "ログアウト",
  },
]

export const Modal = ({ user }: IProps) => (
  <Wrapper>
    <Profile to={`/user/${user.name}`}>
      <ScreenName>{user.screenName}</ScreenName>
      <Name>@{user.name}</Name>
    </Profile>
    {MenuItems(user.name).map(({ to, icon, title }, i) => (
      <Item key={i} to={to} icon={icon} title={title} />
    ))}
  </Wrapper>
)
const Item = ({ to, icon, title }: IItemProps) => (
  <StyledLink to={to}>
    <StyledFontAwesomeIcon icon={icon} fixedWidth={true} />
    {title}
  </StyledLink>
)
const Wrapper = styled.div`
  position: absolute;
  top: 53px;
  right: -5px;
  width: 200px;
  border-radius: 5px;
  color: ${Color.WHITE};
  background-color: ${Color.BLACK};
  padding: 10px 0;
  &:before {
    content: "";
    position: absolute;
    top: -16px;
    right: 14px;
    width: 0;
    height: 0;
    border-top: 8px solid ${Color.TRANSPARENT};
    border-bottom: 8px solid ${Color.BLACK};
    border-left: 10px solid ${Color.TRANSPARENT};
    border-right: 10px solid ${Color.TRANSPARENT};
  }
`
const Profile = styled(Link)`
  display: block;
  padding: 0 10px 10px;
  border-bottom: 1px solid ${Color.DIMGRAY};
  :hover {
    color: ${Color.WHITE};
    text-decoration: none;
  }
`
const ScreenName = styled.div`
  color: ${Color.WHITE};
  font-weight: bold;
  font-size: 1rem;
`
const Name = styled.div`
  color: ${Color.LIGHT_GRAY};
  font-size: 0.9rem;
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: -2px;
  font-size: 0.8rem;
  vertical-align: middle;
  margin-right: 15px;
`
const StyledLink = styled(Link)`
  display: block;
  padding: 8px 10px;
  font-size: 1rem;
  color: ${Color.WHITE};
  :hover {
    color: ${Color.WHITE};
    background-color: ${Color.BRAND};
    text-decoration: none;
  }
  ${media.tablet(css`
    :hover {
      color: inherit;
      background-color: ${Color.TRANSPARENT};
    }
  `)};
`
