import React from "react"
import { Button } from "../../../../parts/buttons/button"
import Color from "../../../../../style/enum/color"
import { Link } from "react-router-dom"
import styled from "styled-components"
export class Login extends React.Component {
  public render() {
    return (
      <div>
        <StyledLink to="/signin">
          <Button color={Color.BRAND}>ログイン</Button>
        </StyledLink>
        <StyledLink to="/signup">
          <Button color={Color.WHITE} backgroundColor={Color.BRAND}>
            新規登録
          </Button>
        </StyledLink>
      </div>
    )
  }
}

const StyledLink = styled(Link)`
  & + & {
    margin-left: 10px;
  }
`
