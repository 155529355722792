import React from "react"
import styled from "styled-components"
import Color from "../../../../style/enum/color"
import { ModalWrapper } from "../../../parts/modal"
import { container, IContainerProps } from "./container"

export const ImageModal = container((props: IContainerProps) => {
  if (props.image) {
    return (
      <StyledModalWrapper onClick={() => props.actions.removeImageModal()}>
        <ImageWrapper>
          <img alt={"画像"} src={props.image} />
        </ImageWrapper>
      </StyledModalWrapper>
    )
  }
  return null
})

const StyledModalWrapper = styled(ModalWrapper)`
  display: flex;
  justify-content: safe center;
  align-items: safe center;
`

const ImageWrapper = styled.div`
  max-width: 1280px;
  padding: 5px;
  background-color: ${Color.WHITE};
  border-radius: 5px;
  margin: 5px;
`
