const siteName = "ChatCast"

export const UpdateTitle = (title?: string) => {
  let newTitle: string
  if (!title) {
    newTitle = `${siteName}`
  } else {
    newTitle = `${title} | ${siteName}`
  }

  document.title = newTitle
}
