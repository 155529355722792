import { Content } from "../../../rpcs/dist/EpisodeRPC"

export const TextFromUnparsedJson = (json: string): string => {
  try {
    let text = ""
    const contents = JSON.parse(json) as Content[]
    for (const content of contents) {
      text += content.blocks.map(b => b.text).join("")
      if (text.length > 1000) {
        return text
      }
    }
    return text
  } catch (e) {
    return ""
  }
}
