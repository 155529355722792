import { unionBy } from "lodash-es"
import uniqBy from "lodash-es/uniqBy"
import { combineReducers } from "redux"
import { ChatRoomActions } from "../../../actions/actions"
import { ChatRoomAction } from "../../../actions/pages/chat-room/actions"
import { ContentWithUser, IGuestMessage } from "../../../types/data-types"
import {
  ChatRoom,
  ChatRoomJoinQueue,
  PersonalitySession,
} from "../../../rpcs/dist/ChatRoomRPC"
import { Types } from "../../../rpcs/dist/EpisodeRPC"
import { User } from "../../../rpcs/dist/UserRPC"

const basicInfo = (state: ChatRoom, action: ChatRoomActions): ChatRoom => {
  switch (action.type) {
    case ChatRoomAction.RoomInfoUpdated:
    case ChatRoomAction.ShowUpUnlockPage:
      return action.data

    case ChatRoomAction.BasicInfoFromPoller:
      Object.keys(action.data).forEach(key => {
        if (action.data[key] !== undefined) {
          state[key] = action.data[key]
        }
      })
      return state

    case ChatRoomAction.DidRemaningTimeUpdated:
      Object.keys(action.data).map(key => (state[key] = action.data[key]))
      return state

    case ChatRoomAction.Reset:
      // @ts-ignore
      return {}

    default:
      return state || {}
  }
}

const timelineMessages = (
  state: ContentWithUser[] = [],
  action: ChatRoomActions,
): ContentWithUser[] => {
  switch (action.type) {
    case ChatRoomAction.SyncMainAddedMessagesUntilUnsubscribe:
      return unionBy(state.concat(action.data), "uuid")

    case ChatRoomAction.SendMainMessage:
      return unionBy(state.concat(action.data), "uuid")

    case ChatRoomAction.SyncMainRemovedMessagesUntilUnsubscribe:
      const removedUuids = action.data.map(c => c.uuid)
      return state.filter(s => !removedUuids.includes(s.uuid))

    case ChatRoomAction.SyncMainModifiedMessagesUntilUnsubscribe:
      const modifiedContentUuids = action.data.map(mc => mc.uuid)
      const newState = state.map(s => {
        if (modifiedContentUuids.includes(s.uuid)) {
          return action.data.find(mc => mc.uuid === s.uuid)!
        }
        return s
      })
      return newState

    case ChatRoomAction.SyncUsersInformationUntilUnsubscribe:
      const modifiedUser = action.data
      return state.map(s => {
        if (s.user!.id === modifiedUser.id) {
          s.user = modifiedUser
        }
        if (s.type === Types.Pickup && s.pickedUser!.id === modifiedUser.id) {
          s.pickedUser = modifiedUser
        }
        return s
      })

    case ChatRoomAction.Reset:
      return []

    default:
      return state
  }
}

const guestMessages = (
  state: IGuestMessage[] = [],
  action: ChatRoomActions,
): IGuestMessage[] => {
  switch (action.type) {
    case ChatRoomAction.SyncGuestMessagesUntilUnsubscribe:
    case ChatRoomAction.SendGuestMessage:
      return uniqBy(state.concat(action.data), "uuid")

    case ChatRoomAction.SyncUsersInformationUntilUnsubscribe:
      return state.map(s => {
        if (s.userId === action.data.id) {
          s.user = action.data
        }
        return s
      })

    case ChatRoomAction.Reset:
      return []

    default:
      return state
  }
}

const personalitySessions = (
  state: PersonalitySession[] = [],
  action: ChatRoomActions,
): PersonalitySession[] => {
  switch (action.type) {
    case ChatRoomAction.DidPersonalitySessionsUpdated:
      return action.data

    case ChatRoomAction.DidPersonalitySessionRemoved:
      return state.filter(p => p.user.id !== action.data.id)

    case ChatRoomAction.DidPersonalitySessionAdded:
      return state.concat(action.data)

    case ChatRoomAction.SyncUsersInformationUntilUnsubscribe:
      return state.map(s => {
        if (s.user.id === action.data.id) {
          s.user = action.data
        }
        return s
      })

    case ChatRoomAction.Reset:
      return []

    default:
      return state
  }
}

const totalViews = (state: number = 0, action: ChatRoomActions): number => {
  switch (action.type) {
    case ChatRoomAction.DidNumOfTotalViewsUpdated:
      return action.data

    case ChatRoomAction.Reset:
      return 0

    default:
      return state
  }
}

const currentViews = (state: number = 0, action: ChatRoomActions): number => {
  switch (action.type) {
    case ChatRoomAction.DidNumOfCurrentViewsUpdated:
      return action.data

    case ChatRoomAction.Reset:
      return 0

    default:
      return state
  }
}

const typingUsers = (state: User[] = [], action: ChatRoomActions): User[] => {
  switch (action.type) {
    case ChatRoomAction.DidSomeUsersTyped:
      return action.data

    case ChatRoomAction.Reset:
      return []

    default:
      return state
  }
}

const joinQueue = (
  state: ChatRoomJoinQueue[] = [],
  action: ChatRoomActions,
): ChatRoomJoinQueue[] => {
  switch (action.type) {
    case ChatRoomAction.DidNewJoinQueueUpdated:
      return action.data

    case ChatRoomAction.DidJoinQueuePopped:
      const user = action.data
      return state.filter(s => s.user.id !== user.id)

    case ChatRoomAction.Reset:
      return []

    default:
      return state
  }
}

const hasPendingJoinRequest = (
  state: boolean = false,
  action: ChatRoomActions,
): boolean => {
  switch (action.type) {
    case ChatRoomAction.DidPendingJoinRequestStatusUpdated:
      return action.data

    case ChatRoomAction.Reset:
      return false

    default:
      return state
  }
}

const hasValidPrivilegeToAccess = (
  state: boolean = false,
  action: ChatRoomActions,
): boolean => {
  switch (action.type) {
    case ChatRoomAction.AllowToAccess:
      return true

    case ChatRoomAction.Reset:
      return false

    default:
      return state
  }
}

export const syncData = combineReducers({
  basicInfo,
  timelineMessages,
  guestMessages,
  personalitySessions,
  totalViews,
  currentViews,
  typingUsers,
  joinQueue,
  hasPendingJoinRequest,
  hasValidPrivilegeToAccess,
})
