import { restoreLoginDataFromLocalStorage } from "../../models/LoginData"
import LogRocket from "logrocket"
import { isProduction, isProductionWidget } from "../check-env"

export const SetupLogrocket = () => {
  if (isProduction() && !isProductionWidget()) {
    LogRocket.init("0gb5ms/chatcast-v2")
    const loginData = restoreLoginDataFromLocalStorage()
    if (loginData) {
      if (loginData && loginData.user) {
        const user = loginData.user
        LogRocket.identify(user.id, {
          name: user.name,
          email: user.email || "",
          screenName: user.screenName,
        })
      }
    }
  }
}
