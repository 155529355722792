import { Metadata } from "../../../rpcs/dist/EpisodeRPC"
import { User } from "../../../rpcs/dist/UserRPC"

export const AdjustUserInfoFromCustomUserInfoList = (
  users: User[],
  userList: Metadata["customUserInfoList"],
): User[] => {
  return users.map(user => {
    return {
      ...user,
      ...userList.find(u => u.id === user.id),
    }
  })
}
