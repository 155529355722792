import { Episode } from "../../rpcs/dist/EpisodeRPC"

export interface IEpisode {
  episodes: Episode[]
}

export enum EpisodeActionTypes {
  UPDATE_EPISODE = "@TAG/UPDATE_EPISODE",
  RESET_EPISODE = "@TAG/RESET_EPISODE",
}

export const updateEpisodes = (data: Episode[]) =>
  ({
    type: EpisodeActionTypes.UPDATE_EPISODE,
    data,
  } as const)

export const resetEpisodes = () =>
  ({
    type: EpisodeActionTypes.RESET_EPISODE,
    data: null,
  } as const)
