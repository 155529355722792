import { ValidationGenerator } from "../../../../../form/validation/validation-generator"
import { UserValidationSetting } from "../../../../../form/validation/validation-setting"
import { IFormField } from "./form-meta"

export const validate = (values: Partial<IFormField>) => {
  const errors: Partial<IFormField> = {}

  const UserNameVal = ValidationGenerator(
    values.name,
    UserValidationSetting.name,
  )
  const UserScreenNameVal = ValidationGenerator(
    values.screenName,
    UserValidationSetting.screenName,
  )
  const UserEmailVal = ValidationGenerator(
    values.email,
    UserValidationSetting.email,
  )
  const UserPasswordVal = ValidationGenerator(
    values.password,
    UserValidationSetting.password,
  )
  const UserPasswordConfirmationVal = ValidationGenerator(
    values.passwordConfirmation,
    UserValidationSetting.password,
  )

  if (UserNameVal) {
    errors.name = UserNameVal
  }

  if (UserScreenNameVal) {
    errors.screenName = UserScreenNameVal
  }

  if (UserEmailVal) {
    errors.email = UserEmailVal
  }

  if (UserPasswordVal) {
    errors.password = UserPasswordVal
  }

  if (UserPasswordConfirmationVal) {
    errors.passwordConfirmation = UserPasswordConfirmationVal
  }

  if (
    UserPasswordVal === null &&
    UserPasswordConfirmationVal === null &&
    values.password !== values.passwordConfirmation
  ) {
    errors.passwordConfirmation = "パスワードが合致しませんでした"
  }

  if (!values.agreementCheck) {
    errors.agreementCheck = "登録するには同意する必要があります"
  }

  return errors
}
