import { ChannelActions } from "../../../actions/actions"
import {
  ChannelActionTypes,
  IChannelState,
  InitialState,
} from "../../../actions/pages/channelActionCreator"

export const channel = (
  state: IChannelState = InitialState,
  action: ChannelActions,
): IChannelState => {
  switch (action.type) {
    case ChannelActionTypes.FETCH_CHANNEL_PROFILE:
      return {
        ...state,
        ...action.data,
      }

    case ChannelActionTypes.PARTIAL_UPDATE_CHANNEL_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile!,
          ...action.data,
        },
      }

    case ChannelActionTypes.APPEND_CHANNEL_FOLLOWERS:
      return {
        ...state,
        followers: (state.followers || []).concat(action.data.followers),
      }

    case ChannelActionTypes.UNMOUNT_CHANNEL_FOLLOWERS:
      return {
        ...state,
        followers: null,
      }

    case ChannelActionTypes.ADD_EPISODES:
      return {
        ...state,
        episodes: (state.episodes || []).concat(action.data.episodes || []),
      }

    case ChannelActionTypes.ADD_DRAFTS:
      return {
        ...state,
        drafts: (state.drafts || []).concat(action.data.drafts || []),
      }

    case ChannelActionTypes.UPDATE_LIVE_SCHEDULES:
      return {
        ...state,
        liveSchedules: action.data.liveSchedules,
      }

    case ChannelActionTypes.RESET_EPISODES:
      return {
        ...state,
        episodes: null,
      }

    case ChannelActionTypes.RESET_DRAFTS:
      return {
        ...state,
        drafts: null,
      }

    case ChannelActionTypes.RESET:
      return InitialState

    default:
      return state
  }
}
