import React from "react"
import styled from "styled-components"
import { Field, WrappedFieldProps } from "redux-form"
import Color from "../../../../../style/enum/color"

export interface ICheckboxProps<T = string> {
  name: T
  component: React.ReactNode
}

export const AuthAgreementCheck = (props: WrappedFieldProps) => (
  <Wrapper>
    <label>
      <Field name={props.input.name} component={Checkbox} />{" "}
      <a href={"/information/tos"} target={"_blank"} rel="noopener noreferrer">
        利用規約
      </a>
      と
      <a
        href={"/information/privacy"}
        target={"_blank"}
        rel="noopener noreferrer"
      >
        プライバシーポリシー
      </a>
      に同意する
      {props.meta.touched && props.meta.error && (
        <Error>{props.meta.error}</Error>
      )}
    </label>
  </Wrapper>
)

const Wrapper = styled.div`
  font-size: 0.7rem;
  margin-top: 20px;
  margin-bottom: -25px;
  text-align: center;
`

const Error = styled.div`
  color: ${Color.RED};
  font-size: 0.8rem;
  margin-top: 3px;
`

const Checkbox = (props: WrappedFieldProps) => {
  return (
    <input
      name={props.input.name}
      type="checkbox"
      checked={!!props.input.value}
      onChange={props.input.onChange}
    />
  )
}
