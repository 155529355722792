import { ModalsActions } from "../actions/actions"
import {
  IModals,
  initModals,
  ModalsAction,
} from "../actions/modalsActionCreator"

export const modals = (
  state: IModals = initModals,
  action: ModalsActions,
): IModals => {
  switch (action.type) {
    case ModalsAction.AUTH_UPDATE:
    case ModalsAction.SET_IMAGE_URL:
    case ModalsAction.REMOVE_IMAGE_URL:
    case ModalsAction.SET_USER_DATA:
    case ModalsAction.REMOVE_USER_DATA:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}
