import React from "react"
import { ChannelIcon } from "../../../../../components/parts/channel-icon"
import styled, { css } from "styled-components"
import { Channel } from "../../../../../rpcs/dist/ChannelRPC"
import { media } from "../../../../../style/const/media-query"
import Color from "../../../../../style/enum/color"
import { Link } from "react-router-dom"

export const ChannelListItem = React.memo((channel: Channel) => (
  <ChannelItem to={`/channel/${channel.name}`}>
    <Icon>
      <ChannelIcon
        size={"auto"}
        title={channel.screenName}
        profileImage={channel.profileImage}
      />
    </Icon>
    <Title>{channel.screenName}</Title>
  </ChannelItem>
))

const Icon = styled.div`
  display: flex;
`

const ChannelItem = styled(Link)`
  width: calc(${100 / 4}% - ${(15 * 5) / 4}px);
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 15px;
  box-sizing: content-box;
  ${media.tablet(css`
    width: calc(${100 / 3}% - ${(15 * 4) / 3}px);
  `)}
  ${media.phone(css`
    width: calc(${100 / 2}% - ${(15 * 3) / 2}px);
  `)}
`

const Title = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  margin: 4px 0;
  color: ${Color.LIGHT_BLACK};
`
