import React from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { IModals, updateModal } from "../../../actions/modalsActionCreator"
import { RootState, ReduxAction } from "../../../reducers"
import { ImageModal } from "./image"
import { SignInModal } from "./signin"
import { SignUpModal } from "./signup"
import { UserModal } from "./user"

export interface IModalContainerProps {
  actions: ActionDispatcher
  modals: RootState["modals"]
}

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public updateModal(data: Partial<IModals>) {
    this.dispatch(updateModal(data))
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch),
})

const mapStateToProps = ({ modals }: RootState) => ({
  modals,
})

const Component = React.memo((props: IModalContainerProps) => (
  <>
    {props.modals.signin && <SignInModal {...props} />}
    {props.modals.signup && <SignUpModal {...props} />}
    {props.modals.image && <ImageModal />}
    {props.modals.user && <UserModal />}
  </>
))

export const Modals = connect(mapStateToProps, mapDispatchToProps)(Component)
