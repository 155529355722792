import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { TextFromUnparsedJson } from "../../../libs/converter/text-from-unparsed-json"
import { EpisodeUrl } from "../../../libs/internal-url"
import dayjs from "dayjs"
import { Channel } from "../../../rpcs/dist/ChannelRPC"
import {
  Episode as EpisodeType,
  Visiblity,
} from "../../../rpcs/dist/EpisodeRPC"
import { User } from "../../../rpcs/dist/UserRPC"
import { DraftMark } from "../../parts/draft-mark"
import Color from "../../../style/enum/color"
import { EpisodeTitle } from "../../parts/episode-title"
import { ContextIcon } from "../context-icon"

interface IProps {
  episode: EpisodeType
}

export class Episode extends React.Component<IProps> {
  public render() {
    const ep = this.props.episode

    const author: User | Channel | undefined = ep.channel || ep.user

    if (!author) {
      return null
    }

    return (
      <Wrapper>
        {ep.visiblity === Visiblity.Draft ? <StyledDraftMark /> : null}
        <Link to={EpisodeUrl(ep)}>
          <ContributorWrapper>
            <ContextIcon user={ep.user} channel={ep.channel} size={40} />
            <ContributorName>{author.screenName}</ContributorName>
          </ContributorWrapper>
        </Link>
        <Link to={EpisodeUrl(ep)}>
          <Title>
            <EpisodeTitle title={ep.title} />
          </Title>
          <Description>{TextFromUnparsedJson(ep.content)}</Description>
          <Date>
            {(ep.publishedAt &&
              dayjs(ep.publishedAt).format("YYYY/MM/DD HH:mm")) ||
              "未投稿"}
          </Date>
        </Link>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  position: relative;
  padding: 16px;
  word-break: break-word;
  border-bottom: 1px solid ${Color.LIGHT_GRAY};
  & + & {
    border-top: 1px solid ${Color.LIGHT_GRAY};
    margin-top: -1px;
  }
  a {
    text-decoration: none;
  }
`

const StyledDraftMark = styled(DraftMark)`
  position: absolute;
  top: 16px;
  right: 16px;
`

const ContributorWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ContributorName = styled.div`
  margin-left: 16px;
  font-size: 0.8rem;
  color: ${Color.BLACK_OLIVE};
`

const Title = styled.h2`
  color: ${Color.BLACK};
  font-size: 1rem;
  font-weight: 700;
  margin: 16px 0 8px;
`

const Description = styled.div`
  color: ${Color.DIMGRAY};
  font-size: 0.85rem;
  margin: 8px 0 8px;
  text-align: justify;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  line-height: 1.4rem;
  max-height: 2.8rem;
`

const Date = styled.div`
  color: ${Color.DIMGRAY};
  font-size: 0.8rem;
  font-weight: 300;
`
