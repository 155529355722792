import { combineReducers } from "redux"
import { EditorActions } from "../../../actions/actions"
import {
  EditorActionTypes,
  IEditorState,
  InitialState,
} from "../../../actions/pages/editor/editorActionCreator"
import { AdjustUserInfoFromCustomUserInfoList } from "../../../libs/converter/adjust-user-info-from-custom-user-info-list"

type FetchDataState = IEditorState["fetchData"]

const episode = (
  state = InitialState.fetchData.episode,
  action: EditorActions,
): FetchDataState["episode"] => {
  switch (action.type) {
    case EditorActionTypes.FETCH_EPISODE_DATA:
      return action.data
    case EditorActionTypes.LEAVE_THE_PAGE:
      return InitialState.fetchData.episode
    default:
      return state
  }
}

const channels = (
  state = InitialState.fetchData.channels,
  action: EditorActions,
): FetchDataState["channels"] => {
  switch (action.type) {
    case EditorActionTypes.FETCH_CHANNEL_DATA:
      return action.data
    case EditorActionTypes.LEAVE_THE_PAGE:
      return InitialState.fetchData.channels
    default:
      return state
  }
}

const anonymousUsers = (
  state = InitialState.fetchData.anonymousUsers,
  action: EditorActions,
): FetchDataState["anonymousUsers"] => {
  switch (action.type) {
    case EditorActionTypes.FETCH_EPISODE_DATA:
      const user = (action.data.chatRoom.personalitySessions || [])
        .map(u => u.user)
        .filter(u => u.isAnonymous)
      const userList =
        (action.data.metadata && action.data.metadata.customUserInfoList) || []
      return AdjustUserInfoFromCustomUserInfoList(user, userList)
    case EditorActionTypes.UPDATE_ANONYMOUS_USER_PROFILE_IMAGE:
      return state!.map(u => (u.id === action.data.id ? action.data : u))
    case EditorActionTypes.LEAVE_THE_PAGE:
      return InitialState.fetchData.anonymousUsers
    default:
      return state
  }
}

export const fetchData = combineReducers({
  episode,
  channels,
  anonymousUsers,
})
