import { ChannelProfile } from "../../rpcs/dist/ChannelRPC"
import { ChatRoom } from "../../rpcs/dist/ChatRoomRPC"
import { Episode } from "../../rpcs/dist/EpisodeRPC"
import { User } from "../../rpcs/dist/UserRPC"

export interface IChannelState {
  profile: ChannelProfile | null
  episodes: Episode[] | null
  drafts: Episode[] | null
  followers: User[] | null
  liveSchedules: ChatRoom[]
}

export const InitialState: IChannelState = {
  profile: null,
  episodes: null,
  drafts: null,
  followers: null,
  liveSchedules: [],
}

export enum ChannelActionTypes {
  FETCH_CHANNEL_PROFILE = "@CHANNEL/FETCH_CHANNEL_PROFILE",
  PARTIAL_UPDATE_CHANNEL_PROFILE = "@CHANNEL/PARTIAL_UPDATE_CHANNEL_PROFILE",
  APPEND_CHANNEL_FOLLOWERS = "@CHANNEL/APPEND_CHANNEL_FOLLOWERS",
  UNMOUNT_CHANNEL_FOLLOWERS = "@CHANNEL/UNMOUNT_CHANNEL_FOLLOWERS",
  UPDATE_CHANNEL_FOLLOWERS = "@CHANNEL/UPDATE_CHANNEL_FOLLOWERS",
  ADD_EPISODES = "@CHANNEL/ADD_EPISODES",
  ADD_DRAFTS = "@CHANNEL/ADD_DRAFTS",
  UPDATE_LIVE_SCHEDULES = "@CHANNEL/UPDATE_LIVE_SCHEDULES",
  RESET_EPISODES = "@CHANNEL/RESET_EPISODES",
  RESET_DRAFTS = "@CHANNEL/RESET_DRAFTS",
  RESET = "@CHANNEL/RESET",
}

export const fetchChannelProfile = (channelProfile: ChannelProfile) =>
  ({
    type: ChannelActionTypes.FETCH_CHANNEL_PROFILE,
    data: {
      profile: channelProfile,
    },
  } as const)

export const appendChannelFollowers = (followers: User[]) =>
  ({
    type: ChannelActionTypes.APPEND_CHANNEL_FOLLOWERS,
    data: {
      followers,
    },
  } as const)

export const unmountChannelFollowers = () =>
  ({
    type: ChannelActionTypes.UNMOUNT_CHANNEL_FOLLOWERS,
    data: null,
  } as const)

export const partialUpdateChannelProfile = (
  partialChannelProfile: Partial<ChannelProfile>,
) =>
  ({
    type: ChannelActionTypes.PARTIAL_UPDATE_CHANNEL_PROFILE,
    data: partialChannelProfile,
  } as const)

export const addEpisodes = (episodes: IChannelState["episodes"]) =>
  ({
    type: ChannelActionTypes.ADD_EPISODES,
    data: {
      episodes,
    },
  } as const)

export const addDrafts = (episodes: IChannelState["drafts"]) =>
  ({
    type: ChannelActionTypes.ADD_DRAFTS,
    data: {
      drafts: episodes,
    },
  } as const)

export const updateLiveSchedules = (liveSchedules: ChatRoom[]) =>
  ({
    type: ChannelActionTypes.UPDATE_LIVE_SCHEDULES,
    data: {
      liveSchedules,
    },
  } as const)

export const resetEpisodes = () =>
  ({
    type: ChannelActionTypes.RESET_EPISODES,
    data: {
      episodes: null,
    },
  } as const)

export const resetDrafts = () =>
  ({
    type: ChannelActionTypes.RESET_DRAFTS,
    data: {
      drafts: null,
    },
  } as const)

export const reset = () =>
  ({
    type: ChannelActionTypes.RESET,
    data: {},
  } as const)
