import styled from "styled-components"
import Color from "../../../../style/enum/color"

export const Wrapper = styled.div`
  margin: 5px 0;
  padding: 5px;
  color: ${Color.DIMGRAY};
`

export const Title = styled.label<{ active: boolean }>`
  display: block;
  font-size: 0.8rem;
  text-indent: 3px;
  margin: 0;
  color: ${({ active }) => (active ? Color.BRAND : Color.DIMGRAY)};
`

export const FieldWrapper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  transition: 0.3s;
  border: 1px solid ${({ active }) => (active ? Color.BRAND : Color.LIGHT_GRAY)};
  border-radius: 5px;
  overflow: hidden;
  input {
    width: 100%;
    height: 35px;
  }
  textarea {
    width: 100%;
    min-height: 70px;
    padding: 5px;
  }
`

export const IconWrapper = styled.div<{ active: boolean }>`
  width: 40px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  transition: 0.3s;
  color: ${({ active }) => (active ? Color.BRAND : Color.DIMGRAY)};
`

export const Error = styled.div`
  color: ${Color.RED};
  font-size: 0.8rem;
  margin-top: 3px;
`
