import React from "react"
import { ThrowError } from "../../../../error/error"
import { NotificationActionDispatcher } from "../../../../libs/notifications"
import { IFormField } from "../../auth/signup/form/form-meta"
import { SignIn } from "../../auth/signin"
import { ModalWrapper } from "../../../parts/modal"
import { container, IAuthContainer } from "../../auth/components/container"
import { IModalContainerProps } from "../index"

type IProps = IAuthContainer & IModalContainerProps

class Component extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.showSignUp = this.showSignUp.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  public render() {
    return (
      <ModalWrapper>
        <SignIn
          onClickSignUpButton={this.showSignUp}
          submit={this.submit}
          onClose={this.onClose}
          isModal={true}
        />
      </ModalWrapper>
    )
  }

  private showSignUp() {
    this.props.actions.updateModal({
      signup: true,
      signin: false,
    })
  }

  private onClose() {
    this.props.actions.updateModal({
      signin: false,
    })
  }

  private submit = (values: IFormField) => {
    this.props.sessionActions.loginFromEmail(
      {
        email: values.email,
        password: values.password,
      },
      (success, e) => {
        if (success) {
          NotificationActionDispatcher.LogNotification("ログインしました")
        } else {
          ThrowError(e)
        }
      },
    )
  }
}

export const SignInModal = container(Component)
