import Color from "../../../../style/enum/color"

export interface IColorScheme {
  color: Color
  backgroundColor: Color
  borderColor: Color
  borderTopColor: Color
}

export const ErrorColorScheme: IColorScheme = {
  color: Color.RED,
  backgroundColor: Color.THIN_RED,
  borderColor: Color.RED,
  borderTopColor: Color.RED,
}

export const WarningColorScheme: IColorScheme = {
  color: Color.ORANGE,
  backgroundColor: Color.THIN_ORANGE,
  borderColor: Color.ORANGE,
  borderTopColor: Color.ORANGE,
}

export const LogColorScheme: IColorScheme = {
  color: Color.BRAND,
  backgroundColor: Color.THIN_BRAND,
  borderColor: Color.BRAND,
  borderTopColor: Color.LIGHT_BRAND,
}

export const SuccessColorScheme: IColorScheme = {
  color: Color.GREEN,
  backgroundColor: Color.THIN_GREEN,
  borderColor: Color.GREEN,
  borderTopColor: Color.THIN_GREEN,
}
