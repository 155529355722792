import React from "react"
import styled from "styled-components"
import { ISendButtonProps, SendButton } from "../../../send-button"

export const AuthSendButton = (props: ISendButtonProps) => (
  <Wrapper>
    <SendButton {...props} />
  </Wrapper>
)

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`
