import { ChatRoom } from "../../rpcs/dist/ChatRoomRPC"
import { Episode } from "../../rpcs/dist/EpisodeRPC"

export interface ITopState {
  recentEpisodes: Episode[] | null
  recommendEpisodes: Episode[] | null
  myFeedEpisodes: Episode[] | null
  lives: {
    chatRoom: ChatRoom[] | null
    updatedAt: number | null
  }
}

export const InitialState: ITopState = {
  recentEpisodes: null,
  recommendEpisodes: null,
  myFeedEpisodes: null,
  lives: {
    chatRoom: null,
    updatedAt: null,
  },
}

export enum TopActionTypes {
  UPDATE_RECENT_EPISODES = "@TOP/UPDATE_RECENT_EPISODES",
  UPDATE_RECOMMEND_EPISODES = "@TOP/UPDATE_RECOMMEND_EPISODES",
  UPDATE_MYFEED_EPISODES = "@TOP/UPDATE_MYFEED_EPISODES",
  UPDATE_LIVE = "@TOP/UPDATE_LIVE",
  RESET = "@TOP/RESET",
}

export const updateRecentEpisodes = (episodes: ITopState["recentEpisodes"]) =>
  ({
    type: TopActionTypes.UPDATE_RECENT_EPISODES,
    data: {
      recentEpisodes: episodes,
    },
  } as const)

export const updateRecommendEpisodes = (
  episodes: ITopState["recommendEpisodes"],
) =>
  ({
    type: TopActionTypes.UPDATE_RECOMMEND_EPISODES,
    data: {
      recommendEpisodes: episodes,
    },
  } as const)

export const updateMyFeedEpisodes = (episodes: ITopState["myFeedEpisodes"]) =>
  ({
    type: TopActionTypes.UPDATE_MYFEED_EPISODES,
    data: {
      myFeedEpisodes: episodes,
    },
  } as const)

export const updateLives = (live: ChatRoom[]) =>
  ({
    type: TopActionTypes.UPDATE_LIVE,
    data: {
      lives: {
        chatRoom: live,
        updatedAt: Date.now(),
      },
    },
  } as const)

export const reset = () =>
  ({
    type: TopActionTypes.RESET,
    data: {},
  } as const)
