import { connect } from "react-redux"
import { Dispatch } from "redux"
import {
  IUserState,
  reset,
  addDrafts,
  addEpisodes,
  updateFollowChannels,
  updateMyChannels,
  updateUserProfile,
  resetEpisodes,
  resetDrafts,
  updateUserFollowInfo,
  addMyFeeds,
} from "../../actions/pages/userActionCreator"
import { getCurrentUser } from "../../libs/get-current-user"
import { RootState, ReduxAction } from "../../reducers"
import { IRootPagesState } from "../../reducers/pages"
import { User, UserProfile } from "../../rpcs/dist/UserRPC"

export interface IContainerProps {
  currentUser: User | null
  user: IRootPagesState["user"]
  actions: ActionDispatcher
}

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public updateUserProfile(user: IUserState["profile"]) {
    this.dispatch(updateUserProfile(user))
  }

  public updateUserFollowInfo(isFollowing: UserProfile["isFollowedByMe"]) {
    this.dispatch(updateUserFollowInfo(isFollowing))
  }

  public updateMyChannels(channels: IUserState["myChannels"]) {
    this.dispatch(updateMyChannels(channels))
  }

  public updateFollowChannels(channels: IUserState["followChannels"]) {
    this.dispatch(updateFollowChannels(channels))
  }

  public addEpisodes(episodes: NonNullable<IUserState["episodes"]>) {
    this.dispatch(addEpisodes(episodes))
  }

  public addMyFeeds(episodes: NonNullable<IUserState["episodes"]>) {
    this.dispatch(addMyFeeds(episodes))
  }

  public addDrafts(episodes: NonNullable<IUserState["drafts"]>) {
    this.dispatch(addDrafts(episodes))
  }

  public resetEpisodes() {
    this.dispatch(resetEpisodes())
  }

  public resetDrafts() {
    this.dispatch(resetDrafts())
  }

  public reset() {
    this.dispatch(reset())
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch),
})

const mapStateToProps = ({ switchingUser, loginData, pages }: RootState) => ({
  currentUser: getCurrentUser(loginData, switchingUser),
  user: pages.user,
})

export const container = connect(mapStateToProps, mapDispatchToProps)
