import { User } from "../../rpcs/dist/UserRPC"

export const isAuthenticatedFromUser = (
  user: User | null,
  includeAuthenticatedAnonymous: boolean = false,
): boolean => {
  // 非ログインユーザー
  if (user === null) {
    return false
  }
  // 匿名ログインユーザー (含める場合)
  if (user.isAnonymous && includeAuthenticatedAnonymous) {
    return true
  }
  // 匿名ログインユーザー (含めない場合)
  if (user.isAnonymous) {
    return false
  }
  // 通常ログインユーザー
  return true
}
