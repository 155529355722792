import React from "react"
import styled from "styled-components"
import { Debug } from "../components/parts/debug"
import { ThrowError } from "../error/error"
import Color from "../style/enum/color"

// tslint:disable-next-line:no-empty-interface
interface IProps {}

interface IState {
  hasError: boolean
  errorInfo: string
  errorString: string
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  public static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    }
  }

  constructor(props: IProps) {
    super(props)
    this.state = {
      hasError: false,
      errorInfo: "",
      errorString: "",
    }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info)
    ThrowError(error)
    this.setState({
      errorString: error.message,
      errorInfo: JSON.stringify(errorInfo, null, "  "),
    })
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>処理エラーが発生しました</h1>
          <p>
            エラーは管理者に通知されました。
            <br />
            サポートが必要な方は
            <a href={"/information/contact"}>お問い合わせ</a>
            よりお願いいたします。
            <br />
            <a href="/">トップページへ移動する</a>
          </p>
          <Debug>
            <StyledErrorSteing>{this.state.errorString}</StyledErrorSteing>
            <StyledPre>
              <code
                dangerouslySetInnerHTML={{
                  __html: this.state.errorInfo.split("\\n").join("<br/>"),
                }}
              />
            </StyledPre>
          </Debug>
        </>
      )
    }

    return this.props.children
  }
}

const StyledErrorSteing = styled.div`
  padding: 24px;
  background-color: ${Color.THIN_BRAND};
`

const StyledPre = styled.pre`
  padding: 24px;
  background-color: ${Color.LIGHT_GRAY};
`
