import { connect } from "react-redux"
import { Dispatch } from "redux"
import {
  firebaseSnsLoginStateChange,
  SNSLoginState,
} from "../../../../../actions/firebaseSNSLoginStateActionCreator"
import { doLogin } from "../../../../../actions/loginDataActionCreator"
import { RootState, ReduxAction } from "../../../../../reducers"
import { LoginData } from "../../../../../rpcs/dist/UserRPC"

export interface IContainerProps {
  firebaseSNSLoginState: RootState["firebaseSNSLoginState"]
  actions: ActionDispatcher
}

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public firebaseSnsLoginStateChange(state: SNSLoginState) {
    this.dispatch(firebaseSnsLoginStateChange(state))
  }

  public doLogin(loginData: LoginData) {
    this.dispatch(doLogin(loginData))
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch),
})

const mapStateToProps = ({
  firebaseSNSLoginState,
}: RootState): Omit<IContainerProps, "actions"> => ({
  firebaseSNSLoginState,
})

export const container = connect(mapStateToProps, mapDispatchToProps)
