import React from "react"
import { Login } from "./components/login"
import { LogoPc } from "../../../parts/logo/logoPc"
// import { SearchBox } from "./components/search-box"
import { Background } from "../components/background"
import { NoShrink } from "./components/no-shrink"
import { HeaderLogoSize } from "../style/variables"
export const UnAuthenticated = () => (
  <Background>
    <NoShrink>
      <LogoPc width={HeaderLogoSize} />
    </NoShrink>
    {/*<SearchBoxWrapper>*/}
    {/*  <SearchBox />*/}
    {/*</SearchBoxWrapper>*/}
    <NoShrink>
      <Login />
    </NoShrink>
  </Background>
)
// const SearchBoxWrapper = styled.div`
//   flex-basis: 400px;
//   flex-shrink: 1;
//   padding: 0 10px;
// `
