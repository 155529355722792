import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Button } from "../../../../../components/parts/buttons/button"
import { Loading } from "../../../../../components/parts/loading"
import { SpaceWithText } from "../../../../../components/parts/space-with-text"
import { Episode } from "../../../../../components/templates/episode"
import { Wrapper } from "../../../../../components/templates/wrapper"
import { JSONRPCErrorCatch } from "../../../../../error/error"
import { isAuthenticatedFromUser } from "../../../../../libs/is-authenticated-from-user"
import { JSONRPCClientBuilder } from "../../../../../request/JSONRPCClientBuilder"
import { UserRPC } from "../../../../../rpcs/dist/UserRPC"
import Width from "../../../../../style/enum/width"
import { IContainerProps } from "../../../container"
import { NeedsLogin } from "./components/needs-login"

export class MyFeed extends React.Component<IContainerProps> {
  constructor(props: IContainerProps) {
    super(props)
    this.getMyFeedIfneeded = this.getMyFeedIfneeded.bind(this)
  }

  public componentDidMount() {
    this.getMyFeedIfneeded()
  }

  public render() {
    if (!isAuthenticatedFromUser(this.props.currentUser)) {
      return <NeedsLogin />
    }

    const { myFeedEpisodes } = this.props.top
    const currentUser = this.props.currentUser!

    // 読み込み中
    if (!myFeedEpisodes) {
      return <Loading verticalPadding={"15vh"} />
    }

    // エピソードがない
    if (myFeedEpisodes.length === 0) {
      return (
        <SpaceWithText>
          <p>エピソードがありません</p>
        </SpaceWithText>
      )
    }
    return (
      <Wrapper maxWidth={Width.EPISODE_LIST}>
        {myFeedEpisodes.map(e => (
          <Episode key={e.id} episode={e} />
        ))}
        <ReadMore>
          <Link to={`/user/${currentUser.name}/`}>
            <Button>もっと見る</Button>
          </Link>
        </ReadMore>
      </Wrapper>
    )
  }

  private async getMyFeedIfneeded() {
    if (!this.props.top.myFeedEpisodes && this.props.currentUser !== null) {
      const userRPC = JSONRPCClientBuilder.build(UserRPC)
      const res = await userRPC.myFeed({ page: 0 }).catch(JSONRPCErrorCatch)
      if (res !== -1) {
        this.props.actions.updateMyFeedEpisodes(res)
      }
    }
  }
}

const ReadMore = styled.div`
  text-align: center;
  padding: 64px;
`
