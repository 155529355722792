import styled from "styled-components"
import Color from "../../../style/enum/color"

export const StaticWrapper = styled.div`
  margin: 48px 0;
`

export const StaticTitle = styled.p`
  font-weight: bold;
  color: ${Color.LIGHT_BLACK};
`

export const StaticContent = styled.p`
  color: ${Color.DIMGRAY};
  margin: 8px 0;
`

export const StaticContentForRule = styled.div`
  color: ${Color.DIMGRAY};
  font-size: 0.85rem;
  line-height: 1.8;
`

export const StaticDate = styled.div`
  color: ${Color.DIMGRAY};
  font-size: 0.85rem;
  text-align: right;
  margin-bottom: 100px;
`
