import { Episode } from "../../rpcs/dist/EpisodeRPC"

export interface IEpisode {
  episodes: Episode[]
}

export enum EpisodeActionTypes {
  UPDATE_EPISODE = "@RECENT_EPISODE/UPDATE_EPISODE",
  RESET_EPISODE = "@RECENT_EPISODE/RESET_EPISODE",
}

export const updateEpisodes = (data: Episode[]) => ({
  type: EpisodeActionTypes.UPDATE_EPISODE,
  data,
})

export const resetEpisodes = () => ({
  type: EpisodeActionTypes.RESET_EPISODE,
  data: null,
})
