import { TagActions } from "../../../actions/actions"
import { EpisodeActionTypes } from "../../../actions/pages/tagActionCreator"
import { Episode } from "../../../rpcs/dist/EpisodeRPC"

export const tag = (state: Episode[], action: TagActions): Episode[] | null => {
  switch (action.type) {
    case EpisodeActionTypes.UPDATE_EPISODE:
      return (state || []).concat(action.data || [])
    case EpisodeActionTypes.RESET_EPISODE:
      return null
    default:
      return state || null
  }
}
