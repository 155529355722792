enum Color {
  WHITE = "#ffffff",
  BLACK = "#262626",
  BLACK_OLIVE = "#323232",
  LIGHT_BLACK = "#454545",
  BRAND = "#22a7f0",
  LIGHT_BRAND = "#a3daf9",
  THIN_BRAND = "#ecf7fe",
  DIMGRAY = "#73757a",
  LIGHT_GRAY = "#d8d8d8",
  THIN_GRAY = "#f7f7f7",
  GREEN = "#00bb00",
  THIN_GREEN = "#ddffdd",
  ORANGE = "#ff8c00",
  THIN_ORANGE = "#ffefd5",
  RED = "#ff0000",
  REMOVE = "#d9534f",
  UNSUBSCRIBE_NOT_CHECK_RED = "#ff8888",
  LIKE = "#F0435A",
  THIN_RED = "#ffeeee",
  TWITTER = "#00aced",
  FACEBOOK = "#3b5998",
  LINE = "#00c300",
  HATENA = "#008fde",
  MODAL_BACKGROUND = "rgba(128, 128, 128, 0.7)",
  COMMENT_BODER = "#eeeeee",
  SERIF_BODER = "#eeeeee",
  TRANSPARENT = "transparent",
}

export default Color
