export interface IValidation {
  isRequired: boolean
  length?: {
    min: number
    max: number
  }
  pattern?: {
    regexp: RegExp
    error: string
  }
}

export interface IValidationGroup {
  [key: string]: IValidation
}

// 英数字_のパターン
const alphanumericPlusUnderScoreRegexp = {
  regexp: /^[A-Za-z0-9_]+$/,
  error: "半角英数字のみ使用可能です",
}

// メールのパターン
const emailRegexp = {
  regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  error: "メールアドレスの形式が間違っています",
}

export const ChannelValidationSetting: IValidationGroup = {
  name: {
    isRequired: true,
    length: {
      min: 3,
      max: 16,
    },
    pattern: alphanumericPlusUnderScoreRegexp,
  },
  screenName: {
    isRequired: true,
    length: {
      min: 1,
      max: 32,
    },
  },
  description: {
    isRequired: false,
    length: {
      min: 0,
      max: 400,
    },
  },
}

export const UserValidationSetting: IValidationGroup = {
  name: {
    isRequired: true,
    length: {
      min: 3,
      max: 16,
    },
    pattern: alphanumericPlusUnderScoreRegexp,
  },
  screenName: {
    isRequired: true,
    length: {
      min: 1,
      max: 32,
    },
  },
  email: {
    isRequired: true,
    pattern: emailRegexp,
  },
  description: {
    isRequired: false,
    length: {
      min: 0,
      max: 400,
    },
  },
  password: {
    isRequired: true,
    length: {
      min: 8,
      max: 64,
    },
  },
}

export const SurveyValidationSetting: IValidationGroup = {
  title: {
    isRequired: true,
    length: {
      min: 1,
      max: 64,
    },
  },
  answer: {
    isRequired: true,
    length: {
      min: 1,
      max: 32,
    },
  },
}
