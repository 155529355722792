import React from "react"
import { Wrapper } from "../../../components/templates/wrapper"
import { SUPPORT_MAIL_ADDRESS } from "../../../config/generalInformation"
import Width from "../../../style/enum/width"
import { Headline } from "../../../components/parts/headline/headline"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  StaticWrapper,
  StaticTitle,
  StaticContentForRule,
  StaticDate,
} from "../components/style"
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile"

const Items = [
  {
    title: "ChatCast プライバシーポリシー",
    content: `株式会社iDEAKITT（以下「当社」といいます。）は、当社の提供する「ChatCast」のウェブサイト及びアプリケーションサービス（以下併せて「本サービス」といいます。）をご利用される皆様（以下「ユーザー」といいます。）のプライバシーを尊重する為に、個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定め、利用者情報の適切な取り扱いに取り組んでまいります。`,
  },
  {
    title: "1.当社が取得する利用者情報について",
    content: `
      当社は、ユーザーが本サービスを利用する場合、個人の氏名、生年月日、住所その他の特定の個人を識別することができる情報（以下「個人情報」といいます。）の提供を受け、又は取得することがあります。
      また、当社は、本サービスの利用に関連して、電話番号、メールアドレス、ユーザーＩＤ、パスワード、クレジットカード番号、端末情報、ログ情報、クッキー（Cookie）等の情報（以下個人情報と併せて「利用者情報」といいます。）を取得することがあります。
      当社は、利用者情報を本ポリシーに従って取り扱い、本ポリシーに定める目的のために利用することができます。
`,
  },
  {
    title: "2.取得した利用者情報の利用目的について",
    content: `
      <p>当社は、利用者情報を以下の目的で利用することができるものとします。</p>
      <ul>
        <li>本サービス及び本サービスに関連する情報の提供のため</li>
        <li>ユーザーの本人確認のため</li>
        <li>本サービスの運営上必要な事項の通知または連絡（電子メールによるものを含みます。）</li>
        <li>当社及び第三者の商品等の広告または宣伝（電子メールによるものを含みます。）</li>
        <li>本サービスからご希望のユーザーに対するメールマガジンの送信</li>
        <li>本サービス上で、個人を特定できない範囲における統計情報の作成および利用</li>
        <li>本サービスまたはその提供に関連する新規開発に必要なデータの解析や分析</li>
        <li>ユーザーの行動、性別、アクセス履歴などを用いたターゲティング広告の配信</li>
        <li>契約や法律等に基づく権利の行使や義務の履行のため</li>
        <li>アフターサービス、各種問い合わせ対応のため</li>
      </ul>
    `,
  },
  {
    title: "3.取得した情報の第三者提供について",
    content: `
      <p>当社は、利用者情報のうち個人情報については、事前に当該個人情報の提供者（以下「個人情報提供者」といいます。）の同意を得ないで、第三者に提供または共有することはありません。ただし、次に掲げる場合はこの限りではありません。</p>
      <ul>
        <li>個人情報提供者の同意があった場合</li>
        <li>人の生命、身体または財産の保護のために必要があり、かつ、個人情報提供者の同意を得るのが困難な場合</li>
        <li>本サイトのサイト上での会員の行為が利用規約やガイドラインなどに反し、本サイトの権利、財産やサービス等を保護するため必要と認められる場合</li>
        <li>裁判所、検察庁、警察、税務署、弁護士会またはこれらに準じた権限を持つ機関から開示を求められた場合</li>
        <li>本サービスの提供において当社が必要と判断した際、弁護士等、当社に対して秘密保持義務を負う者に対して開示する場合</li>
        <li>合併、営業譲渡その他の事由による事業の承継の際に、事業を承継する者に対して開示する場合</li>
        <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合</li>
        <li>その他、個人情報の保護に関する法律その他法令により認められると当社が合理的に判断した場合</li>
      </ul>
    `,
  },
  {
    title: "4.クッキー（Cookie）について",
    content: `
      本サービスでは、クッキーと呼ばれる技術を利用しています。
      クッキーとは、特定のテキスト情報を各ユーザーの利用する通信機器に一時的にデータとして保持させ、接続の度にそのデータを基にユーザーを識別する仕組みをいいます。
      クッキーの利用を許可するかどうかは、各ユーザーのブラウザで設定できます。必要に応じて設定を確認してください。ただし、本サービスでは、クッキーの利用を前提としたサービスを提供しているため、クッキーの利用を許可しない場合、当社の一部のサービスを受けられないことがあります。
      また本サービス上で、広告表示回数の計測等のため、当社以外の第三者企業の広告システムによってクッキーが利用されることがあります。
    `,
  },
  {
    title: "5.個人情報の安全管理について",
    content: `
      本ポリシーは本サービスの利用時に適用されるものとし、当社では、個人情報の漏洩または毀損の防止ならびにその他個人情報の安全管理が行われるよう、個人情報の適切な監督を行います。
      当社では、業務遂行に必要な範囲内で、権限を与えられた者のみが個人情報を取り扱います。個人情報の取扱いを外部に委託する場合には、機密保持契約を締結のうえ委託先を監督します。
      尚、当社は個人情報への不正アクセス、紛失、破壊、改ざん及び漏洩などを予防するとともに、万一の場合には速やかな是正措置を行います。
    `,
  },
  {
    title: "6.個人情報の修正・削除等について",
    content: `
      ⑴ 当社は、個人情報提供者から、①個人情報が真実でないという理由によってその内容の修正を求められた場合、及び②あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により収集されたものであるという理由によって、その利用の停止を求められた場合には、ご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、当該個人情報の内容の修正又は利用停止を行い、その旨を個人情報提供者に通知します。なお、合理的な理由に基づいて修正又は利用停止を行わない旨の決定をしたときは、個人情報提供者に対しその旨を通知いたします。<br />
      ⑵ 当社は、個人情報提供者から、提供済の個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、個人情報提供者ご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨を個人情報提供者に通知します。<br />
      ⑶ 個人情報の保護に関する法律その他の法令により、当社が訂正等又は利用停止等の義務を負わない場合は、本項の規定は適用されません。
    `,
  },
  {
    title: "7.本ポリシーの変更について",
    content: `
      当社は、利用者情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを変更することがあります。またその際は、最新のプライバシーポリシーを本サービスに掲載いたします。本ポリシーの変更は、変更後の本ポリシーが本サービスに掲載され、閲覧可能となった時点で有効となります。
      本サービスを都度ご確認の上、本ポリシーをご理解いただくようお願いします。`,
  },
  {
    title: "8.お問い合わせ窓口について",
    content: `
      <p>ご意見、ご質問等のお申出、その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。</p>
      <p>
        株式会社iDEAKITT  窓口：個人情報管理責任窓口<br>
        E-mail：${SUPPORT_MAIL_ADDRESS}
       </p>
    `,
  },
]

export const PrivacyIndex = () => (
  <Wrapper maxWidth={Width.STATIC} defaultHorizontalPadding={true}>
    <Headline>
      <FontAwesomeIcon icon={faFile} /> プライバシーポリシー
    </Headline>
    {Items.map((item, i) => (
      <StaticWrapper key={i}>
        <StaticTitle dangerouslySetInnerHTML={{ __html: item.title }} />
        <StaticContentForRule
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      </StaticWrapper>
    ))}
    <StaticDate>2015年10月01日制定</StaticDate>
  </Wrapper>
)
