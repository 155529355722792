import React, { useCallback } from "react"
import styled, { css } from "styled-components"
import { media } from "../../../style/const/media-query"
import Color from "../../../style/enum/color"

interface IProps {
  children: React.ReactNode
  onBackgroundClick?: (ev: React.MouseEvent<HTMLDivElement>) => void
}

export const Modal = (props: IProps) => {
  const onBackgroundClick = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      if (ev.target === ev.currentTarget && props.onBackgroundClick) {
        props.onBackgroundClick(ev)
      }
    },
    // eslint-disable-next-line
    [],
  )

  return (
    <ModalWrapper onClick={onBackgroundClick}>
      <Inner>{props.children}</Inner>
    </ModalWrapper>
  )
}

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 2;
  background-color: ${Color.MODAL_BACKGROUND};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: scroll;
  padding: 16px;
`

const Inner = styled.div`
  position: relative;
  width: 100%;
  max-width: 480px;
  background-color: ${Color.WHITE};
  padding: 16px;
  border-radius: 4px;
  margin: 10vh auto;
  ${media.phone(css`
    width: calc(100% - 32px);
    margin: 10vh 16px;
  `)}
`

export const ModalTitle = styled.h3`
  text-align: center;
  font-size: 1.3rem;
  color: ${Color.DIMGRAY};
  font-weight: normal;
  margin-bottom: 8px;
`

export const ModalDescription = styled.div`
  color: ${Color.BLACK};
  margin: 16px 0;
`
