import { Component } from "react"
import { Field, WrappedFieldProps } from "redux-form"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  Title,
  Wrapper,
  FieldWrapper,
  IconWrapper,
  Error,
} from "../style-components/style"

export interface IStyledFieldProps<T = string> {
  name: T
  title: string
  icon: IconProp
  type: "text" | "email" | "password"
  placeholder: string
  component?: React.ReactNode
}

type IProps = IStyledFieldProps & WrappedFieldProps

interface IState {
  onFocus: boolean
}

export default class StyledInputField extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      onFocus: false,
    }
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  public render() {
    const props = this.props

    return (
      <Wrapper>
        <Title active={this.state.onFocus} htmlFor={props.name}>
          {props.title}
        </Title>
        <FieldWrapper active={this.state.onFocus}>
          <IconWrapper active={this.state.onFocus}>
            <FontAwesomeIcon icon={props.icon} />
          </IconWrapper>
          <Field
            name={props.input.name}
            component={props.component || "input"}
            type={props.type}
            placeholder={props.placeholder}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
        </FieldWrapper>
        {props.meta.touched && props.meta.error && (
          <Error>{props.meta.error}</Error>
        )}
      </Wrapper>
    )
  }

  private onFocus() {
    this.setState({
      onFocus: true,
    })
  }

  private onBlur() {
    this.setState({
      onFocus: false,
    })
  }
}
