import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import logo_sp from "../../../assets/images/logo_sp.svg"

interface IProps {
  width: number
}

export class LogoSp extends React.Component<IProps> {
  public render() {
    return (
      <Link to="/">
        <StyledLogoPc width={this.props.width} />
      </Link>
    )
  }
}

const StyledLogoPc = styled.div<IProps>`
  width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  background-size: ${({ width }) => width}px auto;
  background-image: url(${logo_sp});
  background-repeat: no-repeat;
`
