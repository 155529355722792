import React from "react"
import { ChatRoom } from "../../rpcs/dist/ChatRoomRPC"
import { Live } from "./components/tab/live/live"
import { NewEpisode } from "./components/tab/new-episode/"
import { MyFeed } from "./components/tab/myfeed"
import { PopularEpisode } from "./components/tab/popular-episode/"
import { IContainerProps } from "./container"

export enum TopPagePathNames {
  NewEpisode = "",
  MyFeed = "myfeed",
  Live = "live",
  PopularEpisode = "popular_episode",
}

export interface ITabData {
  title: string
  path: TopPagePathNames
  component: React.ReactNode
}

const geLiveCount = (chatRoom: ChatRoom[] | null) => {
  if (chatRoom) {
    const startedLives = chatRoom
      .filter(r => r.startedAt !== null)
      .filter(r => new Date(r.startedAt!).getTime() < new Date().getTime())
    if (startedLives.length > 0) {
      return `(${startedLives.length})`
    }
  }
  return ``
}

export const TabData = (props: IContainerProps): ITabData[] => {
  return [
    {
      title: "新着",
      path: TopPagePathNames.NewEpisode,
      component: <NewEpisode {...props} />,
    },
    {
      title: "マイフィード",
      path: TopPagePathNames.MyFeed,
      component: <MyFeed {...props} />,
    },
    {
      title: `ライブ ${geLiveCount(props.top.lives.chatRoom)}`,
      path: TopPagePathNames.Live,
      component: <Live {...props} />,
    },
    {
      title: "おすすめ",
      path: TopPagePathNames.PopularEpisode,
      component: <PopularEpisode {...props} />,
    },
  ]
}
