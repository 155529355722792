import { Channel } from "../../rpcs/dist/ChannelRPC"
import { User } from "../../rpcs/dist/UserRPC"

interface IProps {
  user?: User | null
  channel?: Channel | null
}

export class ContextAccount {
  private props: IProps
  constructor(user?: User | null, channel?: Channel | null) {
    this.props = {
      user,
      channel,
    }
  }

  public getAccountUrl(): string {
    if (this.props.channel) {
      return `/channel/${this.props.channel.name}`
    }
    if (this.props.user) {
      return `/user/${this.props.user.name}`
    }
    return ""
  }

  public getAttribute(name: keyof User & keyof Channel) {
    if (this.props.channel) {
      return this.props.channel[name]
    }
    if (this.props.user) {
      return this.props.user[name]
    }
    return ""
  }
}
