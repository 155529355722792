import { LoginDataActions } from "../actions/actions"
import {
  InitialState,
  LoginDataAction,
  LoginDataState,
} from "../actions/loginDataActionCreator"
import { REHYDRATE } from "redux-persist"

const loginData = (
  state: LoginDataState = InitialState,
  action: LoginDataActions,
): LoginDataState => {
  switch (action.type) {
    case LoginDataAction.Load:
    case LoginDataAction.Save:
    case LoginDataAction.LoginFromEmail:
    case LoginDataAction.RegisterFromEmail:
    case LoginDataAction.RegisterFromTwitter:
    case LoginDataAction.RegisterFromFacebook:
      return action.data

    case LoginDataAction.UpdateUserInfo:
      if (state) {
        return {
          ...state,
          ...action.data,
        }
      }
      return state

    case LoginDataAction.Logout:
      localStorage.removeItem("persist:root")
      return null

    case REHYDRATE:
      if (!action.payload) {
        return null
      }
      if (JSON.stringify(action.payload.loginData) === "{}") {
        return null
      }
      return action.payload.loginData || null

    default:
      return state
  }
}
export default loginData
