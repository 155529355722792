import {
  PRODUCTION_DOMAIN,
  PRODUCTION_DOMAIN_JP,
} from "../../config/generalInformation"

export const isProduction = (): boolean => {
  return (
    window.location.host === PRODUCTION_DOMAIN ||
    window.location.host === PRODUCTION_DOMAIN_JP
  )
}

export const isProductionWidget = (): boolean => {
  return window.location.href.substr(0, 27) === "https://chatcast.jp/widget/"
}
