import ReactGA from "react-ga"
import { GoogleAnalyticsTrackingId } from "../../config/generalInformation"
import { RouteComponentProps } from "react-router-dom"
import { isProduction } from "../check-env"

export const SetupGa = () => {
  if (isProduction()) {
    ReactGA.initialize(GoogleAnalyticsTrackingId)
  }
}

export const GaPageView = (props: RouteComponentProps) => {
  if (isProduction()) {
    ReactGA.pageview(props.location.pathname)
  }
}
