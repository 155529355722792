import { ChannelPagePathNames } from "../pages/channel/pages/top/components/tab/data"
// import { SearchIndex, SearchPath } from "../pages/search/"
import values from "lodash-es/values"
import React from "react"
// import { RouteComponentProps } from "react-router"
import { ComponentClass } from "react"
import { StaticRoutes } from "../pages/static/routes"
import { TopPagePathNames } from "../pages/top/data"
import { UserPagePathNames } from "../pages/user/components/tab/data"

import "../optimisations/RingBuffer"

export interface IDefaultRoute {
  path?: string
  exact?: boolean
  component: () => Promise<IRouteModule>
  isShowHeader?: boolean
  isShowFooter?: boolean
  authenticationRequired?: boolean
}

const defaultRoute: Required<Omit<IDefaultRoute, "component">> = {
  path: "",
  exact: true,
  isShowHeader: true,
  isShowFooter: true,
  authenticationRequired: false,
}

export interface IRouteModule {
  default: React.ReactNode | ComponentClass
}

const routes: IDefaultRoute[] = [
  // トップ
  {
    path: `/:path(${values(TopPagePathNames).join("|")})?`,
    component: () => import("../pages/top"),
  },
  // トップ
  {
    path: `/recent_episodes`,
    component: () => import("../pages/recent-episode"),
  },
  // ルーム作成
  {
    path: "/create_room",
    component: () => import("../pages/create-room"),
    isShowHeader: false,
    isShowFooter: false,
    authenticationRequired: true,
  },
  // ライブ
  {
    path: "/:type(user|channel)/:name/live/:displayId",
    component: () => import("../pages/chat-room"),
    isShowHeader: false,
    isShowFooter: false,
  },
  // オフエア
  {
    path: "/live/:displayId/offair",
    component: () => import("../pages/chat-room/components/offair"),
    isShowHeader: true,
    isShowFooter: true,
  },
  // エピソード
  {
    path: "/(user|channel)/:name/episode/:displayId/",
    component: () => import("../pages/episode"),
  },
  // {
  //   path: "/:context(user|channel)/:name/episode/:displayId/like",
  //   component: () => import("../pages/episode/pages/like"),
  // },
  // エディット
  {
    path: "/(user|channel)/:name/episode/:displayId/edit/",
    component: () => import("../pages/editor/"),
    isShowHeader: false,
    isShowFooter: false,
    authenticationRequired: true,
  },
  // ウィジェット
  {
    path: "/(user|channel)/:name/episode/:displayId/widget/",
    component: () => import("../pages/create-widget"),
    authenticationRequired: true,
  },
  // ユーザー(エピソード・チャンネル・下書き)
  {
    path: `/user/:name/:path(${values(UserPagePathNames).join("|")})?`,
    component: () => import("../pages/user"),
  },
  // チャンネル追加
  {
    path: `/channel/new`,
    component: () => import("../pages/channel/pages/new"),
    authenticationRequired: true,
  },
  // チャンネル
  {
    path: `/channel/:name/:path(${values(ChannelPagePathNames).join("|")})?`,
    component: () => import("../pages/channel"),
  },
  // チャンネルフォロワー
  {
    path: `/channel/:name/follower`,
    component: () => import("../pages/channel/pages/follower"),
  },
  // チャンネル設定
  {
    path: `/channel/:name/setting`,
    component: () => import("../pages/channel/pages/setting"),
    authenticationRequired: true,
  },
  // 通知
  {
    path: "/notification",
    component: () => import("../pages/notification"),
    authenticationRequired: true,
  },
  // タグ
  {
    path: "/tag/:name",
    component: () => import("../pages/tag"),
  },
  // 設定
  {
    path: "/setting",
    component: () => import("../pages/user-setting"),
    authenticationRequired: true,
  },
  // 検索
  // {
  //   path: SearchPath.Home,
  //   component: SearchIndex,
  // },
  // {
  //   path: SearchPath.HeaderUserICon,
  //   component: SearchIndex,
  // },
  // {
  //   path: SearchPath.Channel,
  //   component: SearchIndex,
  // },
  // {
  //   path: SearchPath.Episode,
  //   component: SearchIndex,
  // },
  // ウィジェット
  {
    path: `/widget/:id`,
    component: () => import("../pages/widget"),
    isShowHeader: false,
    isShowFooter: false,
  },
  // ログイン
  {
    path: "/signin",
    component: () => import("../pages/auth/signin"),
    isShowHeader: false,
    isShowFooter: false,
  },
  // 新規登録
  {
    path: "/signup",
    component: () => import("../pages/auth/signup"),
    isShowHeader: false,
    isShowFooter: false,
  },
  // Facebook oauth callbacks
  {
    path: "/auth/facebook/callback",
    component: () => import("../pages/auth/platforms/facebook"),
    isShowHeader: false,
    isShowFooter: false,
  },
  // Twitter oauth callbacks
  {
    path: "/auth/twitter/callback",
    component: () => import("../pages/auth/platforms/twitter"),
    isShowHeader: false,
    isShowFooter: false,
  },
  // Password reset
  {
    path: "/password-reset",
    component: () => import("../pages/auth/password-reset"),
    isShowHeader: false,
    isShowFooter: false,
  },
  // Password edit
  {
    path: "/password-edit/:key",
    component: () => import("../pages/auth/password-edit"),
    isShowHeader: false,
    isShowFooter: false,
  },
  // Logout
  {
    path: "/logout",
    component: () => import("../pages/logout"),
  },
  // 静的ページ
  {
    path: `/information/:name(${StaticRoutes.map(route => route.path).join(
      "|",
    )})`,
    component: () => import("../pages/static/routes"),
  },
  // 404 Not found
  {
    exact: false,
    component: () => import("../pages/error/404"),
  },
]

export default routes.map(
  (route): IDefaultRoute => ({
    ...defaultRoute,
    ...route,
  }),
)
