import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/free-solid-svg-icons"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { JSONRPCErrorCatch } from "../../../../../error/error"
import { JSONRPCClientBuilder } from "../../../../../request/JSONRPCClientBuilder"
import { UserRPC } from "../../../../../rpcs/dist/UserRPC"
import Color from "../../../../../style/enum/color"
import { media } from "../../../../../style/const/media-query"
import { container, IContainerProps } from "./container"

export class Component extends React.Component<IContainerProps> {
  private timerId: number

  constructor(props: IContainerProps) {
    super(props)
    this.fetchUnreadCount = this.fetchUnreadCount.bind(this)
  }

  public componentDidMount(): void {
    this.fetchUnreadCount()
  }

  public componentWillUnmount(): void {
    clearTimeout(this.timerId)
  }

  public render() {
    return (
      <Wrapper to={"/notification"}>
        <Count unreadCount={this.props.unreadCount} />
        <StyledIcon icon={faBell} />
        <Title>お知らせ</Title>
      </Wrapper>
    )
  }

  private async fetchUnreadCount() {
    const notificationRPC = JSONRPCClientBuilder.build(UserRPC)
    const res = await notificationRPC
      .unreadNotificationCount()
      .catch(JSONRPCErrorCatch)
    if (res !== -1) {
      this.props.actions.update(res.unreadCount)
    }
    this.timerId = setTimeout(this.fetchUnreadCount, 60 * 1000)
  }
}

export const Notification = container(Component)

const Count = ({ unreadCount }: Pick<IContainerProps, "unreadCount">) => {
  if (unreadCount === 0) {
    return null
  }
  if (unreadCount > 99) {
    return <StyledCount>99+</StyledCount>
  }
  return <StyledCount>{unreadCount}</StyledCount>
}

const WrapperPadding = 20
const MobileWrapperPadding = 12
const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 ${WrapperPadding}px;
  color: ${Color.BRAND};
  :hover {
    color: ${Color.BRAND};
    text-decoration: none;
  }
  ${media.phone(css`
    padding: 0 ${MobileWrapperPadding}px;
  `)};
`
const StyledCount = styled.div`
  position: absolute;
  top: -5px;
  left: ${WrapperPadding + 20}px;
  color: ${Color.WHITE};
  background-color: ${Color.RED};
  font-size: 0.5rem;
  padding: 0 2px;
  border-radius: 5px;
  min-width: 15px;
  text-align: center;
  ${media.phone(css`
    left: ${MobileWrapperPadding + 20}px;
  `)};
`
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.4rem;
  margin-bottom: 3px;
`
const Title = styled.div`
  font-size: 0.6rem;
  line-height: 1;
`
