import React from "react"
import {
  AutoLoadEpisodes,
  IEpisodeFuncProps,
} from "../../../../../../components/templates/auto-load-episodes"
import { JSONRPCClientBuilder } from "../../../../../../request/JSONRPCClientBuilder"
import { Episode as EpisodeType } from "../../../../../../rpcs/dist/EpisodeRPC"
import { UserRPC } from "../../../../../../rpcs/dist/UserRPC"
import { container, IContainerProps } from "../../../../container"

type IProps = IContainerProps

interface IState {
  hasMore: boolean
}

export class Component extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.getEpisodeFunc = this.getEpisodeFunc.bind(this)
    this.update = this.update.bind(this)
    this.reset = () => this.reset.bind(this)
  }

  public render() {
    const { myFeeds } = this.props.user

    // エピソードがある
    return (
      <AutoLoadEpisodes
        episodes={myFeeds || null}
        update={this.update}
        reset={this.reset}
        getEpisodeFunc={this.getEpisodeFunc}
      />
    )
  }

  private update(res: EpisodeType[]) {
    this.props.actions.addMyFeeds(res)
  }

  private reset() {
    this.props.actions.resetEpisodes()
  }

  private getEpisodeFunc(props: IEpisodeFuncProps) {
    const userRPC = JSONRPCClientBuilder.build(UserRPC)
    return userRPC.myFeed({
      page: props.page,
    })
  }
}

export const Myfeed = container(Component)
