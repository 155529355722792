import { ChatRoomActions, UnlockTokenActions } from "../actions/actions"
import { ChatRoomAction } from "../actions/pages/chat-room/actions"
import { REHYDRATE } from "redux-persist"
import { IUnlockToken } from "../actions/unlockTokenActionCreator"

export const unlockTokens = (
  state: IUnlockToken[] = [],
  action: ChatRoomActions | UnlockTokenActions,
): IUnlockToken[] => {
  switch (action.type) {
    case ChatRoomAction.DidUnlockedRoom:
      const newState = state.filter(s => s.displayId !== action.data.displayId)
      newState.push(action.data)
      return newState

    case REHYDRATE:
      if (!action.payload || !action.payload.unlockTokens) {
        return []
      }
      return action.payload.unlockTokens

    default:
      return state
  }
}
