import { connect } from "react-redux"
import { Dispatch } from "redux"
import {
  addDrafts,
  addEpisodes,
  resetDrafts,
  resetEpisodes,
} from "../../../../../../actions/pages/channelActionCreator"
import { RootState, ReduxAction } from "../../../../../../reducers"
import { IRootPagesState } from "../../../../../../reducers/pages"
import { Episode } from "../../../../../../rpcs/dist/EpisodeRPC"

export interface IContainerProps {
  episodes: Episode[] | null
  drafts: Episode[] | null
  actions: ActionDispatcher
}

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public updateEpisodes(episodes: IRootPagesState["channel"]["episodes"]) {
    this.dispatch(addEpisodes(episodes))
  }

  public updateDrafts(episodes: IRootPagesState["channel"]["drafts"]) {
    this.dispatch(addDrafts(episodes))
  }

  public resetEpisodes() {
    this.dispatch(resetEpisodes())
  }

  public resetDrafts() {
    this.dispatch(resetDrafts())
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch),
})

const mapStateToProps = ({
  pages,
}: RootState): Omit<IContainerProps, "actions"> => ({
  episodes: pages.channel.episodes,
  drafts: pages.channel.drafts,
})

export const container = connect(mapStateToProps, mapDispatchToProps)
