import React from "react"
import styled, { css } from "styled-components"
import Color from "../../../style/enum/color"

interface IProps {
  size: number | "custom"
  title: string
  noBorder?: boolean
  url: string
  onClick?: (ev: React.MouseEvent) => void
}

export class UserIcon extends React.Component<IProps> {
  public render() {
    return <StyledIcon {...this.props} />
  }
}

const border = ({ noBorder }: IProps) => {
  if (noBorder) {
    return css``
  } else {
    return css`
      border: 1px solid ${Color.LIGHT_GRAY};
    `
  }
}

const sizeStyle = ({ size }: IProps) => {
  if (size === "custom") {
    return css``
  } else {
    return css`
      width: ${size}px;
      height: ${size}px;
    `
  }
}

const StyledIcon = styled.div<IProps>`
  ${border};
  ${sizeStyle};
  border-radius: 100%;
  background-size: cover;
  flex-shrink: 0;
  background-image: url(${({ url }) => url});
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
`
