import { combineReducers } from "redux"
import * as actions from "../../../actions/pages/editor/editorActionCreator"
import {
  EditorActionTypes,
  IEditorState,
  InitialState,
} from "../../../actions/pages/editor/editorActionCreator"
import { ActionType } from "../../../types/ActionsType"
import contents from "./contents"

export type Actions = ActionType<typeof actions>
type FetchDataState = IEditorState["currentEditingState"]

const title = (
  state = InitialState.currentEditingState.title,
  action: Actions,
): FetchDataState["title"] => {
  switch (action.type) {
    case EditorActionTypes.FETCH_EPISODE_DATA:
      return action.data.title
    case EditorActionTypes.UPDATE_TITLE:
      return action.data.title
    case EditorActionTypes.LEAVE_THE_PAGE:
      return InitialState.currentEditingState.title
    default:
      return state
  }
}

const tags = (
  state = InitialState.currentEditingState.tags,
  action: Actions,
): FetchDataState["tags"] => {
  switch (action.type) {
    case EditorActionTypes.FETCH_EPISODE_DATA:
      return (action.data.tags || []).map(tag => tag.tagName)
    case EditorActionTypes.ADD_TAG:
      return (state || []).concat(action.data.tag)
    case EditorActionTypes.REMOVE_TAG:
      return (state || []).filter(tag => tag !== action.data.tag)
    case EditorActionTypes.LEAVE_THE_PAGE:
      return InitialState.currentEditingState.tags
    default:
      return state
  }
}

const selectedChannelId = (
  state = InitialState.currentEditingState.selectedChannelId,
  action: Actions,
): FetchDataState["selectedChannelId"] => {
  switch (action.type) {
    case EditorActionTypes.FETCH_EPISODE_DATA:
      return action.data.channel ? action.data.channel.id : null
    case EditorActionTypes.SELECT_POST_ACCOUNT:
      return action.data
    case EditorActionTypes.LEAVE_THE_PAGE:
      return InitialState.currentEditingState.selectedChannelId
    default:
      return state
  }
}

const surveyModal = (
  state = InitialState.currentEditingState.surveyModal,
  action: Actions,
): FetchDataState["surveyModal"] => {
  switch (action.type) {
    case EditorActionTypes.CLICK_MENU_SURVEY:
      return action.data
    case EditorActionTypes.CLOSE_SURVEY_MODAL:
      return null
    case EditorActionTypes.LEAVE_THE_PAGE:
      return InitialState.currentEditingState.surveyModal
    default:
      return state
  }
}

const isSending = (
  state = InitialState.currentEditingState.isSending,
  action: Actions,
): FetchDataState["isSending"] => {
  switch (action.type) {
    case EditorActionTypes.SENDING:
      return true
    case EditorActionTypes.SENDING_FAILED:
      return false
    case EditorActionTypes.LEAVE_THE_PAGE:
      return InitialState.currentEditingState.isSending
    default:
      return state
  }
}

export const currentEditingState = combineReducers({
  title,
  tags,
  contents,
  selectedChannelId,
  surveyModal,
  isSending,
})
