export class RingBuffer<T> {
  private items: T[] = []

  private pointer = 0

  constructor(private maxBufferingCount: number = 20) {}

  public find(where: (item: T) => boolean): T | undefined {
    for (const item of this.items) {
      if (where(item)) {
        return item
      }
    }
    return undefined
  }

  public push(value: T) {
    if (this.items.length >= this.maxBufferingCount) {
      this.items.pop()
      this.items.unshift(value)
    } else {
      this.items.push(value)
    }
  }

  public pushOrReplace(value: T, key: keyof T) {
    this.items.forEach((item, index) => {
      if (this.items[index][key] === value[key]) {
        this.items.splice(index, 1)
      }
    })
    this.push(value)
  }

  public next(): IteratorResult<T | null> {
    if (this.pointer < this.items.length) {
      return {
        done: false,
        value: this.items[this.pointer++],
      }
    } else {
      this.pointer = 0
      return {
        done: true,
        value: null,
      }
    }
  }

  public [Symbol.iterator](): IterableIterator<T | null> {
    return this
  }
}
