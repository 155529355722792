import {
  ErrorColorScheme,
  IColorScheme,
  LogColorScheme,
  SuccessColorScheme,
  WarningColorScheme,
} from "../components/templates/alert/color-scheme"

export interface IAlert {
  uid: number
  title?: string
  description?: string
  autoDismiss: number
  colorScheme: IColorScheme
  createdAt: number
}

export type AlertState = IAlert[]
export const InitialState = []

export enum AlertAction {
  PUSH = "@ALERT/PUSH",
  POP = "@ALERT/POP",
}

const generateData = (
  data: Partial<IAlert>,
  ColorScheme: IColorScheme,
): IAlert => ({
  ...data,
  uid: Math.random(),
  autoDismiss: data.autoDismiss || 5000,
  createdAt: Date.now(),
  colorScheme: ColorScheme,
})

export const SuccessAlertPushActionCreator = (data: Partial<IAlert>) =>
  ({
    type: AlertAction.PUSH,
    data: generateData(data, SuccessColorScheme),
  } as const)

export const ErrorAlertPushActionCreator = (data: Partial<IAlert>) =>
  ({
    type: AlertAction.PUSH,
    data: generateData(data, ErrorColorScheme),
  } as const)

export const WarningAlertPushActionCreator = (data: Partial<IAlert>) =>
  ({
    type: AlertAction.PUSH,
    data: generateData(data, WarningColorScheme),
  } as const)

export const LogAlertPushActionCreator = (data: Partial<IAlert>) =>
  ({
    type: AlertAction.PUSH,
    data: generateData(data, LogColorScheme),
  } as const)

export const AlertPopActionCreator = (uid: number) =>
  ({
    type: AlertAction.POP,
    data: {
      uid,
    },
  } as const)
