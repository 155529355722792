import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes"
import React from "react"
import styled from "styled-components"
import Color from "../../../style/enum/color"
import { IAlert } from "../../../actions/alertActionCreator"
import { IColorScheme } from "./color-scheme"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ANIMATION_TIME = 300

interface IProps extends IAlert {
  remove: (uid: number) => void
}

export class Item extends React.Component<IProps> {
  private ref = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }

  public shouldComponentUpdate() {
    return false
  }

  public componentDidMount() {
    setTimeout(this.show, 30)
    setTimeout(this.hide, this.props.autoDismiss - ANIMATION_TIME * 2)
  }

  public render() {
    return (
      <Wrapper {...this.props} ref={this.ref}>
        <Close onClick={this.hide}>
          <FontAwesomeIcon icon={faTimes} />
        </Close>
        <Title {...this.props.colorScheme}>{this.props.title}</Title>
        {this.props.description && (
          <Description>
            {this.props.description.split("\n").map((line, i, all) => (
              <React.Fragment key={i}>
                {line}
                {all.length - 1 !== i && <br />}
              </React.Fragment>
            ))}
          </Description>
        )}
      </Wrapper>
    )
  }

  private show() {
    const el = this.ref.current
    if (el) {
      el.style.transform = `translateX(0)`
    }
  }

  private hide() {
    const el = this.ref.current
    if (el) {
      el.style.opacity = `0`
      setTimeout(() => {
        this.props.remove(this.props.uid)
      }, ANIMATION_TIME)
    }
  }
}

const Wrapper = styled.div<IAlert>`
  position: relative;
  width: 250px;
  background-color: ${({ colorScheme }) => colorScheme.backgroundColor};
  border-top: 3px solid ${({ colorScheme }) => colorScheme.borderColor};
  padding: 8px 20px 8px 8px;
  margin: 8px;
  box-sizing: border-box;
  transform: translateX(120%);
  transition: 0.3s;
  overflow: hidden;
`

const Title = styled.div<IColorScheme>`
  color: ${({ color }) => color};
  font-size: 0.8rem;
  font-weight: bold;
`

const Description = styled.div`
  color: ${Color.DIMGRAY};
  font-size: 0.8rem;
`

const Close = styled.button`
  position: absolute;
  top: 5px;
  right: 0;
  appearance: none;
  border: none;
  background-color: ${Color.TRANSPARENT};
  font-size: 0.8rem;
  color: ${Color.DIMGRAY};
  cursor: pointer;
`
