import { faEnvelope, faUnlock, faUser } from "@fortawesome/free-solid-svg-icons"
import { RegisterFromEmailInput } from "../../../../../rpcs/dist/UserRPC"
import StyledInputField from "../../../../parts/styled-field/styled-input-field"
import { IStyledFieldProps } from "../../../../parts/styled-field/styled-input-field"
import {
  AuthAgreementCheck,
  ICheckboxProps,
} from "../../components/auth-agreement-check"

export const FormId = "SignUpIndex"

export type IFormField = Pick<
  RegisterFromEmailInput,
  "name" | "screenName" | "email" | "password" | "passwordConfirmation"
> & {
  agreementCheck: boolean | string
}

export const formData: Array<
  | IStyledFieldProps<
      keyof Pick<
        RegisterFromEmailInput,
        "name" | "screenName" | "email" | "password" | "passwordConfirmation"
      >
    >
  | ICheckboxProps<"agreementCheck">
> = [
  {
    name: "name",
    title: "ユーザーID",
    icon: faUser,
    type: "text",
    placeholder: "例: chatcast",
    component: StyledInputField,
  },
  {
    name: "screenName",
    title: "ユーザー名",
    icon: faUser,
    type: "text",
    placeholder: "例: チャットキャスト",
    component: StyledInputField,
  },
  {
    name: "email",
    title: "メールアドレス",
    icon: faEnvelope,
    type: "email",
    placeholder: "例: mail@chatca.st",
    component: StyledInputField,
  },
  {
    name: "password",
    title: "パスワード",
    icon: faUnlock,
    type: "password",
    placeholder: "8文字以上の半角英数字",
    component: StyledInputField,
  },
  {
    name: "passwordConfirmation",
    title: "パスワード確認",
    icon: faUnlock,
    type: "password",
    placeholder: "パスワードを再入力",
    component: StyledInputField,
  },
  {
    name: "agreementCheck",
    component: AuthAgreementCheck,
  },
]
