import { Episode } from "../../rpcs/dist/EpisodeRPC"
import { IParsedEpisode } from "../../types/data-types"

export interface IEpisodePageState {
  episode: IParsedEpisode | null
  recommendEpisodes: Episode[] | null
}

export const InitialState: IEpisodePageState = {
  episode: null,
  recommendEpisodes: null,
}

export enum EpisodeActionTypes {
  FETCH_EPISODE = "@EPISODE/FETCH_EPISODE",
  UPDATE_RECOMMEND_EPISODES = "@EPISODE/UPDATE_RECOMMEND_EPISODES",
  CLICK_LIKE = "@EPISODE/CLICK_LIKE",
  RESET = "@EPISODE/RESET",
}

export const fetchEpisode = (data: IParsedEpisode) =>
  ({
    type: EpisodeActionTypes.FETCH_EPISODE,
    data,
  } as const)

export const updateRecommendEpisodes = (data: Episode[]) =>
  ({
    type: EpisodeActionTypes.UPDATE_RECOMMEND_EPISODES,
    data,
  } as const)

export const clickLike = (isLike: boolean) =>
  ({
    type: EpisodeActionTypes.CLICK_LIKE,
    isLike,
  } as const)

export const reset = () =>
  ({
    type: EpisodeActionTypes.RESET,
    data: null,
  } as const)
