import { AlertActions } from "../actions/actions"
import {
  AlertAction,
  AlertState,
  InitialState,
} from "../actions/alertActionCreator"

export const alert = (
  state: AlertState = InitialState,
  action: AlertActions,
): AlertState => {
  switch (action.type) {
    case AlertAction.PUSH:
      return state.concat([action.data])
    case AlertAction.POP:
      return state.filter(s => s.uid !== action.data.uid)
    default:
      return state
  }
}
