import React from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faComments } from "@fortawesome/free-solid-svg-icons"
import Color from "../../../../style/enum/color"
import { Button } from "../../../parts/buttons/button"
import { media } from "../../../../style/const/media-query"

export const ChatStartButton = () => (
  <Wrapper to={"/create_room"}>
    <StyledButton backgroundColor={Color.BRAND} color={Color.WHITE}>
      <StyledIcon icon={faComments} /> <span>チャット開始</span>
    </StyledButton>
  </Wrapper>
)
const Wrapper = styled(Link)`
  color: ${Color.WHITE};
  :hover {
    text-decoration: none;
  }
`
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.2rem;
  margin-right: 7px;
`
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  ${media.phone(css`
    font-size: 0.8rem;
    padding-right: 8px;
    padding-left: 8px;
  `)};
`
