import store from "../config/configureStore"
import { configForCurrentEnv } from "../config/environment"

export class JSONRPCClientBuilder {
  public static build<T>(RequestClass: {
    new (endpoints: string, headers?: { [key: string]: string }): T
  }) {
    const { loginData, switchingUser } = store.getState()

    if (!loginData) {
      return new RequestClass(configForCurrentEnv.endpoints.api)
    }

    const headers: any = {
      Authorization: `JWT ${loginData.jwt}`,
    }

    if (loginData.user.isAnonymous) {
      headers["X-IS-Anonymous"] = "1"
    }

    if (switchingUser.token && switchingUser.debug) {
      headers["X-UsrSw-Token"] = switchingUser.token
    }

    return new RequestClass(configForCurrentEnv.endpoints.api, headers)
  }
}
