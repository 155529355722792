/* eslint-disable */
import "reflect-metadata"
import * as JSONRPC from "jsonrpc-compiler/lib/core/jsonrpc"
import { plainToClass } from "class-transformer"
import { readCacheIfNeeded, writeCacheIfNeeded } from "../middlewares/cache"

import { User } from "./UserRPC"
import { Channel } from "./ChannelRPC"
import { Type } from "class-transformer"
import { Episode } from "./EpisodeRPC"

function autoImplment<T>(): new () => T {
  return class {} as any
}
export class ChatRoom {
  public id: number
  public displayId: string
  @Type(() => User)
  public owner?: User
  @Type(() => Channel)
  public channel?: Channel
  @Type(() => PersonalitySession)
  public personalitySessions?: PersonalitySession[]
  public topic: string
  public locked: boolean
  public startedAt?: Date
  public closedAt?: Date
  public additionalTime: number
  public remainingTime: number
}

export class PersonalitySession {
  @Type(() => ChatRoom)
  public chatRoom: ChatRoom
  @Type(() => User)
  public user: User
  public lastOnlinedAt: Date
}

export class ChatRoomJoinQueue {
  public id: string
  @Type(() => ChatRoom)
  public chatRoom: ChatRoom
  @Type(() => User)
  public user: User
}

export enum SurveySituation {
  Chat = 1,
  Episode = 2,
}

export enum SurveyModel {
  Select = 1,
  Scale = 2,
}

export class ChatRoomSurvey {
  public id: number
  @Type(() => ChatRoom)
  public chatRoom: ChatRoom
  @Type(() => ChatRoomSurveyQuestion)
  public chatRoomSurveyQuestions: ChatRoomSurveyQuestion[]
  public surveyModel: SurveyModel
  public surveySituation: SurveySituation
  public subject: string
}

export class ChatRoomSurveyQuestion {
  public id: number
  @Type(() => ChatRoomSurvey)
  public chatRoomSurvey: ChatRoomSurvey
  public title: string | null
  public numOfAnswers: number
  public averageOfAnswersForScaleModel: number
}

export class ChatRoomSurveyAnswer {
  public id: number
  @Type(() => ChatRoomSurveyQuestion)
  public chatRoomSurveyQuestion: ChatRoomSurveyQuestion
  @Type(() => User)
  public user: User
  public value: number | null
}

export class Empty {}

export class UpdateLastOnlinedAtInput {
  public displayId: string
  public anonymous?: {
    jwt: string
  }
}

export class IsLockedRoomInput {
  public displayId: string
}

export class BaseChatRoomFetchInput {
  public displayId: string
}

export class IsLockedRoomOutput extends BaseChatRoomFetchInput {
  public value: boolean
}

export class FetchRoomInfoInput extends BaseChatRoomFetchInput {
  public unlockToken?: string
}

export class IssueCustomTokenForFireStoreInput {
  public displayId: string
  // ロックルーム用のトークン
  public unlockToken?: string
}

export class IssueCustomTokenForFireStoreOutput {
  public t: string
}

export class AcceptJoinRequestInput {
  public displayId: string
  public userId: string
}

export class RejectJoinRequestInput {
  public displayId: string
  public userId: string
}

export class RemoveFromPersonalityInput {
  public displayId: string
  public userId: string
}

export class BasicInput {
  public displayId: string
}

export class UpdateRoomInfoInput extends autoImplment<Partial<ChatRoom>>() {
  passcode?: string
}

export class AttemptToUnlockRoomInput extends BasicInput {
  public displayId: string
  public passcode: string
}

export class AttemptToUnlockRoomOutput {
  public chatRoom: ChatRoom
  public token: string
}

export class CloseRoomOutput {
  public chatRoom: ChatRoom
  public episode: Episode
}

export class CreateSurveyInput {
  public displayId: string
  public subject: string
  public surveySituation: SurveySituation
  public surveyModel: SurveyModel
  public questions?: {
    title: string
  }[]
}

export class VoteAnswerForSurveyInput {
  public chatRoomSurveyQuestionId: number
  public value?: number
}

export class IsAlreadyVotedAnswerForSurveyInput {
  public chatRoomSurveyId: number
}

export class FetchSurveyInfoInput {
  public chatRoomSurveyId: number
}

export class IsAlreadyVotedAnswerForSurveyOutput {
  public isVoted: boolean
  public chatRoomSurveyQuestionId?: number
  public value?: number
}

interface IOptions {
  timeout: number
  cacheUsable: boolean
}

const optionDefaultValues: IOptions = {
  timeout: 15000,
  cacheUsable: true,
}

export class ChatRoomRPC {
  constructor(public endpoint: string, public requestHeaders: { [key: string]: string } = {}) {}

  async isLockedRoom(input: IsLockedRoomInput, options?: Partial<IOptions>): Promise<IsLockedRoomOutput> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "isLockedRoom",
        input: input,
      })
      if (cached) {
        return cached as IsLockedRoomOutput
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.isLockedRoom", input)

    return new Promise<IsLockedRoomOutput>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(IsLockedRoomOutput, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "isLockedRoom",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.isLockedRoom",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async fetchRoomInfo(input: FetchRoomInfoInput, options?: Partial<IOptions>): Promise<ChatRoom> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "fetchRoomInfo",
        input: input,
      })
      if (cached) {
        return cached as ChatRoom
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.fetchRoomInfo", input)

    return new Promise<ChatRoom>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoom, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "fetchRoomInfo",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.fetchRoomInfo",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async updateRoomInfo(input: UpdateRoomInfoInput, options?: Partial<IOptions>): Promise<ChatRoom> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "updateRoomInfo",
        input: input,
      })
      if (cached) {
        return cached as ChatRoom
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.updateRoomInfo", input)

    return new Promise<ChatRoom>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoom, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "updateRoomInfo",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.updateRoomInfo",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async issueCustomTokenForFireStore(
    input: IssueCustomTokenForFireStoreInput,
    options?: Partial<IOptions>,
  ): Promise<IssueCustomTokenForFireStoreOutput> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "issueCustomTokenForFireStore",
        input: input,
      })
      if (cached) {
        return cached as IssueCustomTokenForFireStoreOutput
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.issueCustomTokenForFireStore", input)

    return new Promise<IssueCustomTokenForFireStoreOutput>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(IssueCustomTokenForFireStoreOutput, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "issueCustomTokenForFireStore",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.issueCustomTokenForFireStore",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async sendJoinRequest(input: BasicInput, options?: Partial<IOptions>): Promise<ChatRoomJoinQueue> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "sendJoinRequest",
        input: input,
      })
      if (cached) {
        return cached as ChatRoomJoinQueue
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.sendJoinRequest", input)

    return new Promise<ChatRoomJoinQueue>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoomJoinQueue, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "sendJoinRequest",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.sendJoinRequest",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async cancelJoinRequest(input: BasicInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "cancelJoinRequest",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.cancelJoinRequest", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "cancelJoinRequest",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.cancelJoinRequest",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async acceptJoinRequest(input: AcceptJoinRequestInput, options?: Partial<IOptions>): Promise<PersonalitySession> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "acceptJoinRequest",
        input: input,
      })
      if (cached) {
        return cached as PersonalitySession
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.acceptJoinRequest", input)

    return new Promise<PersonalitySession>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(PersonalitySession, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "acceptJoinRequest",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.acceptJoinRequest",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async rejectJoinRequest(input: RejectJoinRequestInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "rejectJoinRequest",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.rejectJoinRequest", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "rejectJoinRequest",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.rejectJoinRequest",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async removeFromPersonality(input: RemoveFromPersonalityInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "removeFromPersonality",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.removeFromPersonality", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "removeFromPersonality",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.removeFromPersonality",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async openRoom(input: BasicInput, options?: Partial<IOptions>): Promise<ChatRoom> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "openRoom",
        input: input,
      })
      if (cached) {
        return cached as ChatRoom
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.openRoom", input)

    return new Promise<ChatRoom>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoom, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "openRoom",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.openRoom",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async closeRoom(input: BasicInput, options?: Partial<IOptions>): Promise<CloseRoomOutput> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "closeRoom",
        input: input,
      })
      if (cached) {
        return cached as CloseRoomOutput
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.closeRoom", input)

    return new Promise<CloseRoomOutput>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(CloseRoomOutput, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "closeRoom",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.closeRoom",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async attemptToUnlockRoom(
    input: AttemptToUnlockRoomInput,
    options?: Partial<IOptions>,
  ): Promise<AttemptToUnlockRoomOutput> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "attemptToUnlockRoom",
        input: input,
      })
      if (cached) {
        return cached as AttemptToUnlockRoomOutput
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.attemptToUnlockRoom", input)

    return new Promise<AttemptToUnlockRoomOutput>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(AttemptToUnlockRoomOutput, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "attemptToUnlockRoom",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.attemptToUnlockRoom",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async fetchLiveChatRooms(options?: Partial<IOptions>): Promise<ChatRoom[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    const cached = await readCacheIfNeeded({
      namespace: "ChatRoomRPC",
      method: "fetchLiveChatRooms",
    })
    if (cached) {
      return cached as ChatRoom[]
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.fetchLiveChatRooms")

    return new Promise<ChatRoom[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoom, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "fetchLiveChatRooms",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.fetchLiveChatRooms",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async createSurvey(input: CreateSurveyInput, options?: Partial<IOptions>): Promise<ChatRoomSurvey> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "createSurvey",
        input: input,
      })
      if (cached) {
        return cached as ChatRoomSurvey
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.createSurvey", input)

    return new Promise<ChatRoomSurvey>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoomSurvey, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "createSurvey",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.createSurvey",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async voteAnswerForSurvey(input: VoteAnswerForSurveyInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "voteAnswerForSurvey",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.voteAnswerForSurvey", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "voteAnswerForSurvey",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.voteAnswerForSurvey",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async isAlreadyVotedAnswerForSurvey(
    input: IsAlreadyVotedAnswerForSurveyInput,
    options?: Partial<IOptions>,
  ): Promise<IsAlreadyVotedAnswerForSurveyOutput> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "isAlreadyVotedAnswerForSurvey",
        input: input,
      })
      if (cached) {
        return cached as IsAlreadyVotedAnswerForSurveyOutput
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.isAlreadyVotedAnswerForSurvey", input)

    return new Promise<IsAlreadyVotedAnswerForSurveyOutput>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(IsAlreadyVotedAnswerForSurveyOutput, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "isAlreadyVotedAnswerForSurvey",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.isAlreadyVotedAnswerForSurvey",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async fetchSurveyInfo(input: FetchSurveyInfoInput, options?: Partial<IOptions>): Promise<ChatRoomSurvey> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChatRoomRPC",
        method: "fetchSurveyInfo",
        input: input,
      })
      if (cached) {
        return cached as ChatRoomSurvey
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChatRoomRPC.fetchSurveyInfo", input)

    return new Promise<ChatRoomSurvey>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoomSurvey, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChatRoomRPC",
              method: "fetchSurveyInfo",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChatRoomRPC.fetchSurveyInfo",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }
}
