import React from "react"
import { Button } from "../../../../parts/buttons/button"
import Color from "../../../../../style/enum/color"
import { Link } from "react-router-dom"
import styled from "styled-components"
export class Login extends React.Component {
  public render() {
    return (
      <div>
        <StyledLink to="/signin">
          <Button color={Color.BRAND}>
            <FontSizeSmall>ログイン</FontSizeSmall>
          </Button>
        </StyledLink>
        <StyledLink to="/signup">
          <Button color={Color.WHITE} backgroundColor={Color.BRAND}>
            <FontSizeSmall>新規登録</FontSizeSmall>
          </Button>
        </StyledLink>
      </div>
    )
  }
}
const FontSizeSmall = styled.div`
  font-size: 0.8rem;
  line-height: 1;
`

const StyledLink = styled(Link)`
  & + & {
    margin-left: 5px;
  }
`
