import { RingBuffer } from "../optimisations/RingBuffer"
import { Episode } from "../rpcs/dist/EpisodeRPC"

export interface IRPCResultHistory {
  EpisodeRPC: {
    fetchEpisodeDetail: RingBuffer<Episode>
    recommends: {
      lastUpdated?: Date
      items: Episode[]
    }
  }
}

export enum RPCResultHistoryAction {
  Update = "@RPCResultHistory/UPDATE",
}

export const update = (histories: IRPCResultHistory | null) =>
  ({
    type: RPCResultHistoryAction.Update,
    data: histories,
  } as const)
