import {
  ErrorAlertPushActionCreator,
  LogAlertPushActionCreator,
  SuccessAlertPushActionCreator,
  WarningAlertPushActionCreator,
} from "../../actions/alertActionCreator"
import store from "../../config/configureStore"

export class NotificationActionDispatcher {
  public static SuccessNotification(str: string) {
    store.dispatch(
      SuccessAlertPushActionCreator({
        description: str,
      }),
    )
  }

  public static ErrorNotification(str: string) {
    store.dispatch(
      ErrorAlertPushActionCreator({
        description: str,
      }),
    )
  }

  public static WarningNotification(str: string) {
    store.dispatch(
      WarningAlertPushActionCreator({
        description: str,
      }),
    )
  }

  public static LogNotification(str: string) {
    store.dispatch(
      LogAlertPushActionCreator({
        description: str,
      }),
    )
  }
}
