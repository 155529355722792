import React from "react"
import styled from "styled-components"
import { Wrapper } from "../../../components/templates/wrapper"
import Width from "../../../style/enum/width"

export const LoadError = () => {
  return (
    <StyledWrapper maxWidth={Width.DEFAULT}>
      <h1>読み込みに失敗しました</h1>
      <p>
        リロードしても同様の画面が表示される場合は時間をおいて再度アクセス下さい
      </p>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Wrapper)`
  padding: 30vh 16px;
`
