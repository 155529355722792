import { combineReducers } from "redux"
import { EpisodeActions } from "../../../actions/actions"
import {
  EpisodeActionTypes,
  IEpisodePageState,
  InitialState,
} from "../../../actions/pages/episodeActionCreator"

export const episode = (
  state: IEpisodePageState["episode"] = InitialState.episode,
  action: EpisodeActions,
): IEpisodePageState["episode"] => {
  switch (action.type) {
    case EpisodeActionTypes.FETCH_EPISODE:
      return action.data
    case EpisodeActionTypes.CLICK_LIKE:
      if (state) {
        return {
          ...state,
          isLiked: action.isLike,
          numberOfLikes: action.isLike
            ? state.numberOfLikes! + 1
            : state.numberOfLikes! - 1,
        }
      }
      return state
    case EpisodeActionTypes.RESET:
      return InitialState.episode
    default:
      return state
  }
}

export const recommendEpisodes = (
  state: IEpisodePageState["recommendEpisodes"] = InitialState.recommendEpisodes,
  action: EpisodeActions,
): IEpisodePageState["recommendEpisodes"] => {
  switch (action.type) {
    case EpisodeActionTypes.UPDATE_RECOMMEND_EPISODES:
      return action.data
    case EpisodeActionTypes.RESET:
      return InitialState.recommendEpisodes
    default:
      return state
  }
}

export const episodeReducer = combineReducers({
  episode,
  recommendEpisodes,
})
