import React from "react"
import { Loading } from "../../../../../components/parts/loading"
import { SpaceWithText } from "../../../../../components/parts/space-with-text"
import { Episode } from "../../../../../components/templates/episode"
import { Wrapper } from "../../../../../components/templates/wrapper"
import { JSONRPCErrorCatch } from "../../../../../error/error"
import { JSONRPCClientBuilder } from "../../../../../request/JSONRPCClientBuilder"
import { EpisodeRPC } from "../../../../../rpcs/dist/EpisodeRPC"
import Width from "../../../../../style/enum/width"
import { IContainerProps } from "../../../container"

export class PopularEpisode extends React.Component<IContainerProps> {
  constructor(props: IContainerProps) {
    super(props)
    this.getRecommendEpisodeIfneeded = this.getRecommendEpisodeIfneeded.bind(
      this,
    )
  }

  public componentDidMount() {
    this.getRecommendEpisodeIfneeded()
  }

  public render() {
    const { recommendEpisodes } = this.props.top

    // 読み込み中
    if (!recommendEpisodes) {
      return <Loading verticalPadding={"15vh"} />
    }

    // エピソードがない
    if (recommendEpisodes.length === 0) {
      return (
        <SpaceWithText>
          <p>エピソードがありません</p>
        </SpaceWithText>
      )
    }

    return (
      <Wrapper maxWidth={Width.EPISODE_LIST}>
        {recommendEpisodes.map(e => (
          <Episode key={e.id} episode={e} />
        ))}
      </Wrapper>
    )
  }

  private async getRecommendEpisodeIfneeded() {
    if (!this.props.top.recommendEpisodes) {
      const episodeRPC = JSONRPCClientBuilder.build(EpisodeRPC)
      const res = await episodeRPC.recommends().catch(JSONRPCErrorCatch)
      if (res !== -1) {
        this.props.actions.updateRecommendEpisodes(res)
      }
    }
  }
}
