import store from "../../config/configureStore"
import { SimpleEpisodeInput, Episode } from "../dist/EpisodeRPC"
import { RingBuffer } from "../../optimisations/RingBuffer"
import { update } from "../../actions/RPCResultHistoryAction"

enum RPC {
  FetchEpisodeDetail = "EpisodeRPC.fetchEpisodeDetail",
  Recommends = "EpisodeRPC.recommends",
}

export async function readCacheIfNeeded(rpc: {
  namespace: string
  method: string
  input?: any
}): Promise<any | null> {
  const rpcNameString = `${rpc.namespace}.${rpc.method}`

  switch (rpcNameString) {
    case RPC.FetchEpisodeDetail:
      {
        const input = rpc.input as SimpleEpisodeInput
        const histories = store.getState().rpcResultHistories
        if (
          histories &&
          histories.EpisodeRPC &&
          histories.EpisodeRPC.fetchEpisodeDetail
        ) {
          for (const episode of histories.EpisodeRPC.fetchEpisodeDetail) {
            if (episode && episode.displayId === input.displayId) {
              return episode
            }
          }
        }
      }
      break

    case RPC.Recommends:
      {
        const histories = store.getState().rpcResultHistories
        if (
          histories &&
          histories.EpisodeRPC &&
          histories.EpisodeRPC.recommends
        ) {
          if (histories.EpisodeRPC.recommends.lastUpdated) {
            const t = Math.floor(
              histories.EpisodeRPC.recommends.lastUpdated.getTime() / 1000,
            )
            const now = Math.floor(new Date().getTime() / 1000)
            if (now - t < 1800) {
              return histories.EpisodeRPC.recommends.items
            }
          }
        }
      }
      break

    default:
      break
  }

  return null
}

export function writeCacheIfNeeded(
  rpc: { namespace: string; method: string },
  result: any,
) {
  let histories = store.getState().rpcResultHistories
  if (!histories) {
    histories = {
      EpisodeRPC: {
        fetchEpisodeDetail: new RingBuffer<Episode>(),
        recommends: {
          lastUpdated: undefined,
          items: [],
        },
      },
    }
  }

  const rpcNameString = `${rpc.namespace}.${rpc.method}`

  switch (rpcNameString) {
    case RPC.FetchEpisodeDetail:
      histories.EpisodeRPC.fetchEpisodeDetail!.pushOrReplace(result, "id")
      break

    case RPC.Recommends:
      histories.EpisodeRPC.recommends = {
        lastUpdated: new Date(),
        items: result,
      }
      break

    default:
      break
  }

  store.dispatch(update(histories))
}
