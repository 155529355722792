import React from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { ContextIcon } from "../../../../../../components/templates/context-icon"
import { ContextAccount } from "../../../../../../libs/context-account"
import { ChatRoomUrl } from "../../../../../../libs/internal-url"
import { ChatRoom } from "../../../../../../rpcs/dist/ChatRoomRPC"
import { media } from "../../../../../../style/const/media-query"
import Color from "../../../../../../style/enum/color"
import dayjs from "dayjs"

interface IProps {
  chatRoom: ChatRoom
}

export const LiveItem = (props: IProps) => {
  const chatRoom = props.chatRoom
  const contextAccount = new ContextAccount(chatRoom.owner, chatRoom.channel)

  const isStarted =
    new Date(chatRoom.startedAt!).getTime() < new Date().getTime()

  // 開始前
  if (!isStarted) {
    return (
      <Wrapper to={ChatRoomUrl(chatRoom)}>
        <ContextIcon
          user={chatRoom.owner}
          channel={chatRoom.channel}
          size={80}
        />
        <FutureInformation>
          <FutureStartedAt>{`${dayjs(chatRoom.startedAt!).format(
            "MM月DD日 HH:mm",
          )}に配信予定`}</FutureStartedAt>
          <Title>{chatRoom.topic || "No Title"}</Title>
          <Owner>{contextAccount.getAttribute("screenName")}</Owner>
        </FutureInformation>
      </Wrapper>
    )
  }

  // 開始待ち
  if (chatRoom.startedAt === null) {
    return (
      <Wrapper to={ChatRoomUrl(chatRoom)}>
        <ContextIcon
          user={chatRoom.owner}
          channel={chatRoom.channel}
          size={80}
        />
        <Information>
          <WaitForStart>開始待ち</WaitForStart>
          <Title>{chatRoom.topic || "No Title"}</Title>
          <Owner>{contextAccount.getAttribute("screenName")}</Owner>
          <StartedAt>{CalcStartedAt(chatRoom.startedAt)}</StartedAt>
        </Information>
      </Wrapper>
    )
  }

  // 開始後
  return (
    <Wrapper to={ChatRoomUrl(chatRoom)}>
      <ContextIcon user={chatRoom.owner} channel={chatRoom.channel} size={80} />
      <Information>
        <NowOnAir>配信中</NowOnAir>
        <Title>{chatRoom.topic || "No Title"}</Title>
        <Owner>{contextAccount.getAttribute("screenName")}</Owner>
        <StartedAt>{CalcStartedAt(chatRoom.startedAt)}</StartedAt>
      </Information>
    </Wrapper>
  )
}

const CalcStartedAt = (startedAt?: Date | null) => {
  if (startedAt) {
    return `${dayjs(Date.now()).diff(dayjs(startedAt), "m")}分前に配信開始`
  }
  return `開始を待っています`
}

const Wrapper = styled(Link)`
  display: flex;
  width: 50%;
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
  ${media.tablet(css`
    width: 100%;
  `)}
`

const NowOnAir = styled.div`
  border: 1px solid ${Color.RED};
  color: ${Color.RED};
  font-size: 0.6rem;
  border-radius: 5px;
  padding: 2px 6px;
  display: inline-block;
  line-height: 1;
  position: absolute;
  top: 0;
`

const WaitForStart = styled(NowOnAir)`
  border: 1px solid ${Color.BRAND};
  color: ${Color.BRAND};
`

const Title = styled.div`
  font-size: 1rem;
  padding: 4px 0;
  line-height: 1.2;
  color: ${Color.BLACK};
`

const Owner = styled.div`
  font-size: 0.8rem;
  color: ${Color.DIMGRAY};
`

const Information = styled.div`
  position: relative;
  padding: 16px 16px 0;
`

const FutureInformation = styled(Information)`
  padding-top: 4px;
`

const StartedAt = styled.div`
  font-size: 0.8rem;
  color: ${Color.DIMGRAY};
`

const FutureStartedAt = styled(StartedAt)`
  font-size: 0.8rem;
  color: ${Color.BLACK};
`
