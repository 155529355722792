import firebase from "firebase/app"
import "firebase/auth"
import React from "react"
import styled from "styled-components"
import { SNSLoginState } from "../../../../../../../actions/firebaseSNSLoginStateActionCreator"
import { ThrowError } from "../../../../../../../error/error"
import { NotificationActionDispatcher } from "../../../../../../../libs/notifications"
import { JSONRPCClientBuilder } from "../../../../../../../request/JSONRPCClientBuilder"
import { LoginData, UserRPC } from "../../../../../../../rpcs/dist/UserRPC"
import Color from "../../../../../../../style/enum/color"
import { Loading } from "../../../../../../parts/loading"
import { IProps } from "../../index"

export class WaitingForSnsConnect extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.doSignInOrSignUp = this.doSignInOrSignUp.bind(this)
    this.facebookLogin = this.facebookLogin.bind(this)
    this.twitterLogin = this.twitterLogin.bind(this)
  }

  public componentDidMount() {
    this.doSignInOrSignUp()
  }

  public render() {
    return (
      <FullScreenModal>
        <StyledLoading text={"SNS連携中"} />
      </FullScreenModal>
    )
  }

  private async doSignInOrSignUp() {
    try {
      switch (this.props.firebaseSNSLoginState) {
        case SNSLoginState.FaceBookLoginStarted:
          await this.facebookLogin()
          break

        case SNSLoginState.TwitterLoginStarted:
          await this.twitterLogin()
          break
      }
    } catch (e) {
      ThrowError(e)
      this.props.actions.firebaseSnsLoginStateChange(SNSLoginState.InitialState)
    }
  }

  private async facebookLogin() {
    const result = await firebase.auth().getRedirectResult()
    if (result.additionalUserInfo && result.additionalUserInfo.profile && result.credential) {
      const cred = result.credential as firebase.auth.OAuthCredential
      const rpc = JSONRPCClientBuilder.build(UserRPC)
      const loginResult = await rpc.loginWithFacebook({
        accessToken: cred.accessToken!,
      })

      this.props.actions.firebaseSnsLoginStateChange(SNSLoginState.FaceBookLoginCallbacked)
      switch (loginResult.step) {
        case 0: {
          const loginData: LoginData = loginResult.loginData!
          this.props.actions.doLogin(loginData)
          NotificationActionDispatcher.LogNotification("ログインしました")
          this.props.history.push("/")
          break
        }

        case 1:
          this.props.history.push({
            pathname: "/auth/facebook/callback",
            search:
              "?p=" +
              new Buffer(
                JSON.stringify({
                  email: result.user!.email,
                  name: null,
                  screenName: result.user!.displayName,
                  accessToken: cred.accessToken,
                  userId: (result.additionalUserInfo!.profile as any).id,
                }),
              ).toString("base64"),
          })
          break
      }
    } else {
      throw new Error("認証に失敗しました。時間をおいて再度試してください")
    }
  }

  private async twitterLogin() {
    const result = await firebase.auth().getRedirectResult()
    const cred = result.credential as firebase.auth.OAuthCredential
    const rpc = JSONRPCClientBuilder.build(UserRPC)
    const loginResult = await rpc.loginWithTwitter({
      accessToken: cred.accessToken!,
      tokenSecret: cred.secret!,
      userId: (result.additionalUserInfo!.profile as any).id_str,
    })

    this.props.actions.firebaseSnsLoginStateChange(SNSLoginState.TwitterLoginCallbacked)
    switch (loginResult.step) {
      case 0: {
        const loginData: LoginData = loginResult.loginData!
        this.props.actions.doLogin(loginData)
        NotificationActionDispatcher.LogNotification("ログインしました")
        this.props.history.push("/")
        break
      }

      case 1: {
        const profile = result.additionalUserInfo!.profile as any
        this.props.history.push({
          pathname: "/auth/twitter/callback",
          search:
            "?p=" +
            new Buffer(
              JSON.stringify({
                email: null,
                name: profile.screen_name,
                screenName: profile.name,
                userId: profile.id_str,
                accessToken: cred.accessToken,
                tokenSecret: cred.secret,
              }),
            ).toString("base64"),
        })
      }
    }
  }
}

const FullScreenModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Color.MODAL_BACKGROUND};
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLoading = styled(Loading)`
  color: ${Color.WHITE};
`
