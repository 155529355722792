import { combineReducers } from "redux"
import { ChatRoomLocalDataActions } from "../../../actions/actions"
import {
  ChatRoomLocalDataAction,
  IChatRoomLocalData,
  InitialState,
} from "../../../actions/pages/chat-room/localDataActionCreator"

const isInitialized = (
  state: IChatRoomLocalData["isInitialized"] = InitialState.isInitialized,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["isInitialized"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.UpdateIsInitialized:
      return action.data

    case ChatRoomLocalDataAction.Reset:
      return InitialState.isInitialized

    default:
      return state
  }
}

const mode = (
  state: IChatRoomLocalData["mode"] = InitialState.mode,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["mode"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.ChangeMode:
      return action.data

    case ChatRoomLocalDataAction.Reset:
      return InitialState.mode

    default:
      return state
  }
}

const generalError = (
  state: IChatRoomLocalData["generalError"] = null,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["generalError"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.GeneralError:
      return action.data

    case ChatRoomLocalDataAction.Reset:
      return null

    default:
      return state
  }
}

const previewImage = (
  state: IChatRoomLocalData["previewImage"] = InitialState.previewImage,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["previewImage"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.UpdatePreviewImage:
      return action.data

    case ChatRoomLocalDataAction.ResetPreviewImage:
      return null

    case ChatRoomLocalDataAction.Reset:
      return InitialState.previewImage

    default:
      return state
  }
}

const removeConfirm = (
  state: IChatRoomLocalData["removeConfirm"] = InitialState.removeConfirm,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["removeConfirm"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.UpdateRemoveConfirm:
      return action.data

    case ChatRoomLocalDataAction.ResetRemoveConfirm:
      return null

    case ChatRoomLocalDataAction.Reset:
      return InitialState.removeConfirm

    default:
      return state
  }
}

const endConfirm = (
  state: IChatRoomLocalData["endConfirm"] = InitialState.endConfirm,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["endConfirm"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.ShowEndConfirm:
      return true
    case ChatRoomLocalDataAction.HideEndConfirm:
      return false
    case ChatRoomLocalDataAction.Reset:
      return InitialState.endConfirm

    default:
      return state
  }
}

const surveyModal = (
  state: IChatRoomLocalData["surveyModal"] = InitialState.surveyModal,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["surveyModal"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.ShowSurveyModal:
      return true
    case ChatRoomLocalDataAction.HideSurveyModal:
      return false
    default:
      return state
  }
}

const isSaving = (
  state: IChatRoomLocalData["isSaving"] = InitialState.isSaving,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["isSaving"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.Saving:
      return true

    case ChatRoomLocalDataAction.Reset:
      return InitialState.isSaving

    default:
      return state
  }
}

const selectedDesktopTab = (
  state: IChatRoomLocalData["selectedDesktopTab"] = InitialState.selectedDesktopTab,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["selectedDesktopTab"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.SelectedDesktopTab:
      return action.data

    case ChatRoomLocalDataAction.Reset:
      return InitialState.selectedDesktopTab

    default:
      return state
  }
}

const selectedMobileTab = (
  state: IChatRoomLocalData["selectedMobileTab"] = InitialState.selectedMobileTab,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["selectedMobileTab"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.SelectedMobileTab:
      return action.data

    case ChatRoomLocalDataAction.Reset:
      return InitialState.selectedMobileTab

    default:
      return state
  }
}

const batch = (
  state: IChatRoomLocalData["batch"] = InitialState.batch,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["batch"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.PartialUpdateBatch:
      return { ...state, ...action.data }

    case ChatRoomLocalDataAction.Reset:
      return InitialState.batch

    default:
      return state
  }
}

const serifMemo = (
  state: IChatRoomLocalData["serifMemo"] = InitialState.serifMemo,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["serifMemo"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.AddSerifMemoFromCsv:
      return state.concat(...action.data)

    case ChatRoomLocalDataAction.AddSingleSerifMemoFromForm:
      return state.concat(action.data)

    case ChatRoomLocalDataAction.PickupSerifMemo: {
      const newState = [...state]
      newState[newState.findIndex((s) => s.uuid === action.data.uuid)] = {
        ...newState[newState.findIndex((s) => s.uuid === action.data.uuid)],
        picked: true,
      }

      return newState
    }

    case ChatRoomLocalDataAction.Reset:
      return InitialState.serifMemo

    default:
      return state
  }
}

const chatForm = (
  state: IChatRoomLocalData["chatForm"] = InitialState.chatForm,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["chatForm"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.OnChangeChatTextareaValue:
      return {
        ...state,
        value: action.data,
      }
    case ChatRoomLocalDataAction.PickupSerifMemo:
      return {
        ...state,
        value: action.data.text,
      }

    case ChatRoomLocalDataAction.OnSendChatFormText:
      return {
        ...state,
        isSending: true,
      }

    case ChatRoomLocalDataAction.failedToSendChatFormText:
      return {
        ...state,
        isSending: false,
      }

    case ChatRoomLocalDataAction.ClearChatTextareaValue:
      return {
        ...state,
        value: "",
        isSending: false,
      }

    case ChatRoomLocalDataAction.Reset:
      return InitialState.chatForm

    default:
      return state
  }
}

const feature = (
  state: IChatRoomLocalData["feature"] = InitialState.feature,
  action: ChatRoomLocalDataActions,
): IChatRoomLocalData["feature"] => {
  switch (action.type) {
    case ChatRoomLocalDataAction.OnToggleSerifMemoFeature:
      return {
        ...state,
        serifMemo: !state.serifMemo,
      }

    case ChatRoomLocalDataAction.Reset:
      return InitialState.feature

    default:
      return state
  }
}

export const localData = combineReducers({
  isInitialized,
  mode,
  generalError,
  previewImage,
  removeConfirm,
  surveyModal,
  endConfirm,
  isSaving,
  selectedDesktopTab,
  selectedMobileTab,
  batch,
  serifMemo,
  chatForm,
  feature,
})
