import { ContentWithUser } from "../../../types/data-types"
import { Content, Episode, Types } from "../../../rpcs/dist/EpisodeRPC"
import { User } from "../../../rpcs/dist/UserRPC"

export const constructContentWithUser = (ep: Episode): ContentWithUser[] => {
  let users: User[] = []
  let pickedUsers: User[] = []

  if (ep.chatRoom.personalitySessions) {
    users = ep.chatRoom.personalitySessions.map(p => p.user)
  }

  if (ep.pickedUsers) {
    pickedUsers = ep.pickedUsers
  }

  // 匿名ユーザーに記事別アイコンが設定されていれば更新
  if (ep.metadata && ep.metadata.customUserInfoList) {
    ep.metadata.customUserInfoList.forEach(cu => {
      users.forEach(u => {
        if (u.id === cu.id) {
          u.profileImage = cu.profileImage!
        }
      })
      pickedUsers.forEach(pu => {
        if (pu.id === cu.id) {
          pu.profileImage = cu.profileImage!
        }
      })
    })
  }

  // ユーザー情報の付与
  return (JSON.parse(ep.content) as Content[]).map(c => {
    const content: ContentWithUser = c
    if (content.type === Types.Message) {
      content.user = users.find(u => u.id === content.userId)
    }
    if (content.type === Types.Pickup) {
      content.user = users.find(u => u.id === content.userId)
      content.pickedUser = pickedUsers.find(u => u.id === content.pickedUserId)
    }
    return content
  })
}
