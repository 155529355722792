import { Dispatch, bindActionCreators } from "redux"
import { connect } from "react-redux"
import sessionActionCreators, {
  ISessionActionCreator,
} from "../../../../actions/sessionActionCreator"

export interface IAuthContainer {
  sessionActions: ISessionActionCreator
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
})

export const container = connect(null, mapDispatchToProps)
