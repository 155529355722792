import React, { ComponentClass } from "react"
import { RouteComponentProps } from "react-router"
import { Loading } from "../../components/parts/loading"
import { NeedsLogin } from "../../components/templates/needs-login"
import store from "../../config/configureStore"
import { ThrowError } from "../../error/error"
import { getCurrentUser } from "../../libs/get-current-user"
import { IDefaultRoute, IRouteModule } from "../routes"
import { LoadError } from "./components/load-error"

interface IState {
  container: ComponentType | null
  hasError: boolean
}

type ComponentType = React.ComponentType<{}>

export const AsyncLoader = (
  route: IDefaultRoute,
): ComponentClass<RouteComponentProps, IState> =>
  class Component extends React.Component<RouteComponentProps, IState> {
    constructor(props: RouteComponentProps) {
      super(props)
      this.state = {
        container: null,
        hasError: false,
      }
    }

    public componentDidMount(): void {
      this.asyncLoadIfneeded()
    }

    public render() {
      if (this.state.hasError) {
        return <LoadError />
      }

      if (route.authenticationRequired) {
        const { loginData, switchingUser } = store.getState()
        const currentUser = getCurrentUser(loginData, switchingUser)
        if (currentUser === null) {
          return <NeedsLogin />
        }
      }

      if (this.state.container) {
        return <this.state.container {...this.props} />
      }
      return <Loading text="スクリプト読み込み中" />
    }

    private asyncLoadIfneeded() {
      if (!this.state.container) {
        route
          .component()
          .then((module: IRouteModule) => module.default)
          .then((container: ComponentType) => {
            this.setState({
              container,
            })
          })
          .catch(e => {
            ThrowError(new Error("読み込みに失敗しました"))
            this.setState({
              hasError: true,
            })
          })
      }
    }
  }
