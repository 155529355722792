import React from "react"
import styled from "styled-components"
import fontSize from "../../../../style/const/font-size"
import ButtonColor from "../../../../style/enum/button-color"
import Color from "../../../../style/enum/color"

export type IButtonProps = {
  color?: Color
  borderColor?: Color
  backgroundColor?: Color
  small?: boolean
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  css?: any
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"]
} & React.AllHTMLAttributes<HTMLButtonElement>

export const Button = (props: IButtonProps) => (
  <ButtonStyle {...props}>{props.children}</ButtonStyle>
)

const Margin = 5

export const ButtonStyle = styled.button<IButtonProps>`
  display: inline-block;
  font-size: ${fontSize.NORMAL};
  margin: ${Margin}px 0;
  border-radius: 5px;
  cursor: pointer;
  color: ${({ color }) => color || ButtonColor.BRAND};
  border: 1px solid ${({ borderColor }) => borderColor || ButtonColor.BRAND};
  padding: ${({ small }) => (small ? `3px 10px` : `7px 14px`)};
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  & + & {
    margin-left: ${Margin}px;
  }
`
