import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faComment, faEdit } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { Channel } from "../../../../../../rpcs/dist/ChannelRPC"
import { Draft } from "./draft"
import { Episodes } from "./episode"

export enum ChannelPagePathNames {
  Episode = "",
  Draft = "draft",
}

export interface ITabData {
  title: string
  path: ChannelPagePathNames
  icon: IconProp
  component: React.ReactNode
}

export const TabData = (
  isMember: boolean = false,
  channel: Channel,
): ITabData[] => {
  const tabs = [
    {
      title: "エピソード",
      path: ChannelPagePathNames.Episode,
      icon: faComment,
      component: <Episodes channel={channel} />,
    },
  ]
  if (isMember) {
    tabs.push({
      title: "下書き",
      path: ChannelPagePathNames.Draft,
      icon: faEdit,
      component: <Draft channel={channel} />,
    })
  }
  return tabs
}
