import React from "react"
import styled, { css } from "styled-components"
import Color from "../../../../../style/enum/color"
import { media } from "../../../../../style/const/media-query"
import { LogoPc } from "../../../../parts/logo/logoPc"

interface IProps {
  title: string
  children: React.ReactNode
  isModal?: boolean
}

export const AuthFrame = ({ title, children, isModal }: IProps) => (
  <Wrapper isModal={isModal || false}>
    <LogoWrapper>
      <LogoPc width={180} />
    </LogoWrapper>
    <Title>{title}</Title>
    <div>{children}</div>
  </Wrapper>
)

const Wrapper = styled.div<{
  isModal: boolean
}>`
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: 50px auto;
  padding: 15px 20px 20px;
  border: 1px solid ${Color.LIGHT_GRAY};
  background-color: ${Color.WHITE};
  border-radius: 5px;
  ${({ isModal }) => {
    if (isModal) {
      return media.phone(css`
        margin-left: 16px;
        margin-right: 16px;
        width: auto;
      `)
    } else {
      return media.phone(css`
        margin: 0;
        border: none;
      `)
    }
  }}};
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 15px;
`

const Title = styled.div`
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 5px 0 15px;
`
