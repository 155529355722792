import { Channel } from "../../rpcs/dist/ChannelRPC"
import { Episode } from "../../rpcs/dist/EpisodeRPC"
import { UserProfile } from "../../rpcs/dist/UserRPC"

export interface IUserState {
  profile: UserProfile | null
  myChannels: Channel[] | null
  followChannels: Channel[] | null
  episodes: Episode[] | null
  myFeeds: Episode[] | null
  drafts: Episode[] | null
}

export const InitialState: IUserState = {
  profile: null,
  myChannels: null,
  followChannels: null,
  episodes: null,
  myFeeds: null,
  drafts: null,
}

export enum UserActionTypes {
  UPDATE_USER_PROFILE = "@USER/UPDATE_USER_PROFILE",
  UPDATE_USER_FOLLOW_INFO = "@USER/UPDATE_USER_FOLLOW_INFO",
  UPDATE_MY_CHANNELS = "@USER/UPDATE_MY_CHANNELS",
  UPDATE_FOLLOW_CHANNELS = "@USER/UPDATE_FOLLOW_CHANNELS",
  ADD_EPISODES = "@USER/ADD_EPISODES",
  ADD_MYFEEDS = "@USER/ADD_MYFEEDS",
  ADD_DRAFTS = "@USER/ADD_DRAFTS",
  RESET_EPISODES = "@USER/RESET_EPISODES",
  RESET_DRAFTS = "@USER/RESET_DRAFTS",
  RESET = "@USER/RESET",
}

export const updateUserProfile = (user: IUserState["profile"]) =>
  ({
    type: UserActionTypes.UPDATE_USER_PROFILE,
    data: {
      profile: user,
    },
  } as const)

export const updateUserFollowInfo = (
  isFollowing: UserProfile["isFollowedByMe"],
) =>
  ({
    type: UserActionTypes.UPDATE_USER_FOLLOW_INFO,
    data: {
      isFollowedByMe: isFollowing,
    },
  } as const)

export const updateMyChannels = (channels: IUserState["myChannels"]) =>
  ({
    type: UserActionTypes.UPDATE_MY_CHANNELS,
    data: {
      myChannels: channels,
    },
  } as const)

export const updateFollowChannels = (channels: IUserState["followChannels"]) =>
  ({
    type: UserActionTypes.UPDATE_FOLLOW_CHANNELS,
    data: {
      followChannels: channels,
    },
  } as const)

export const addEpisodes = (episodes: IUserState["episodes"]) =>
  ({
    type: UserActionTypes.ADD_EPISODES,
    data: {
      episodes,
    },
  } as const)

export const addMyFeeds = (episodes: IUserState["episodes"]) =>
  ({
    type: UserActionTypes.ADD_MYFEEDS,
    data: {
      myFeeds: episodes,
    },
  } as const)

export const resetEpisodes = () =>
  ({
    type: UserActionTypes.RESET_EPISODES,
    data: {
      episodes: null,
    },
  } as const)

export const addDrafts = (episodes: IUserState["drafts"]) =>
  ({
    type: UserActionTypes.ADD_DRAFTS,
    data: {
      drafts: episodes,
    },
  } as const)

export const resetDrafts = () =>
  ({
    type: UserActionTypes.RESET_DRAFTS,
    data: {
      drafts: null,
    },
  } as const)

export const reset = () =>
  ({
    type: UserActionTypes.RESET,
    data: {},
  } as const)
