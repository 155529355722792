import React from "react"
import styled from "styled-components"
import Color from "../../../style/enum/color"

interface IProps {
  title: string
}

export const EpisodeTitle = (props: IProps) => {
  if (props.title) {
    return <>{props.title}</>
  }
  return <NoTitle>No Title</NoTitle>
}

const NoTitle = styled.span`
  color: ${Color.DIMGRAY};
`
