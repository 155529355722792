import React from "react"
import styled from "styled-components"
import Color from "../../../style/enum/color"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner"

interface IProps {
  verticalPadding?: string
  text?: string
  classNeme?: string
}

export const Loading = (props: IProps) => (
  <Wrapper {...props}>
    <StyledFontAwesomeIcon icon={faSpinner} spin={true} />
    <LoadingText>{props.text || "読み込み中"}</LoadingText>
  </Wrapper>
)

const Wrapper = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${Color.DIMGRAY};
  padding: ${({ verticalPadding }) => verticalPadding || "30vh"} 0;
  box-sizing: border-box;
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  margin-bottom: 15px;
`
const LoadingText = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
`
