import { Dispatch } from "redux"
import { connect } from "react-redux"
import { removeUserData } from "../../../../actions/modalsActionCreator"
import { RootState, ReduxAction } from "../../../../reducers"
import { User } from "../../../../rpcs/dist/UserRPC"

export interface IContainerProps {
  user: User | null
  actions: ActionDispatcher
}

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public removeUserModal() {
    this.dispatch(removeUserData())
  }
}

const mapStateToProps = ({
  modals,
}: RootState): Omit<IContainerProps, "actions"> => ({
  user: modals.user,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
): Pick<IContainerProps, "actions"> => ({
  actions: new ActionDispatcher(dispatch),
})

export const container = connect(mapStateToProps, mapDispatchToProps)
