import { FirebaseSNSLoginStateActions } from "../actions/actions"
import {
  SNSLoginState,
  LoginStateAction,
} from "../actions/firebaseSNSLoginStateActionCreator"

const firebaseSNSLoginState = (
  state: SNSLoginState = SNSLoginState.InitialState,
  action: FirebaseSNSLoginStateActions,
): SNSLoginState => {
  switch (action.type) {
    case LoginStateAction.Changed:
      return action.data
    default:
      return state
  }
}

export default firebaseSNSLoginState
