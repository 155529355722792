import { css, FlattenSimpleInterpolation } from "styled-components"

export enum Size {
  giant = 1170,
  desktop = 992,
  tablet = 768,
  phone = 376,
}

interface IMedia {
  giant: (style: FlattenSimpleInterpolation) => FlattenSimpleInterpolation
  desktop: (style: FlattenSimpleInterpolation) => FlattenSimpleInterpolation
  tablet: (style: FlattenSimpleInterpolation) => FlattenSimpleInterpolation
  phone: (style: FlattenSimpleInterpolation) => FlattenSimpleInterpolation
}

const mq = (size: Size) => (style: FlattenSimpleInterpolation) => css`
  @media (max-width: ${size / 16}em) {
    ${style}
  }
`

export const media: IMedia = {
  giant: mq(Size.giant),
  desktop: mq(Size.desktop),
  tablet: mq(Size.tablet),
  phone: mq(Size.phone),
}
