import React from "react"
import { ButtonWithCharacter } from "../../../../../../components/parts/buttons/button-with-character"
import { RouteComponentProps, withRouter } from "react-router-dom"

type IProps = RouteComponentProps

const Component = (props: IProps) => {
  const onClickButton = () => props.history.push("/signin")

  return (
    <div>
      <ButtonWithCharacter
        text={"マイフィードを見るにはログインして下さい"}
        buttonText={"ログイン/新規登録"}
        buttonAction={onClickButton}
      />
    </div>
  )
}

export const NeedsLogin = withRouter(Component)
