import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link } from "react-router-dom"
import { Button } from "../../../../../../components/parts/buttons/button"
import { Loading } from "../../../../../../components/parts/loading"
import { SpaceWithText } from "../../../../../../components/parts/space-with-text"
import { JSONRPCErrorCatch } from "../../../../../../error/error"
import { JSONRPCClientBuilder } from "../../../../../../request/JSONRPCClientBuilder"
import { UserRPC } from "../../../../../../rpcs/dist/UserRPC"
import Color from "../../../../../../style/enum/color"
import { container, IContainerProps } from "../../../../container"
import { ChannelList } from "../../components/channel-list"

type IProps = IContainerProps & {
  isMe: boolean
}

export class Component extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.getChannels = this.getChannels.bind(this)
  }

  public componentDidMount() {
    if (this.props.user.myChannels === null) {
      this.getChannels()
    }
  }

  public render() {
    const { myChannels } = this.props.user

    // 読み込み中
    if (!myChannels) {
      return <Loading verticalPadding={"15vh"} />
    }

    // チャンネルがない(自分のページ)
    if (myChannels.length === 0 && this.props.isMe) {
      return (
        <SpaceWithText>
          <p>チャンネルがありません</p>
          <Link to={"/channel/new"}>
            <Button backgroundColor={Color.BRAND} color={Color.WHITE}>
              <FontAwesomeIcon icon={faPlusCircle} /> 新規チャンネルを作成
            </Button>
          </Link>
        </SpaceWithText>
      )
    }

    // チャンネルがない(他の人)
    if (myChannels.length === 0) {
      return (
        <SpaceWithText>
          <p>チャンネルがありません</p>
        </SpaceWithText>
      )
    }

    // チャンネルがある
    return <ChannelList channels={myChannels} />
  }

  private async getChannels() {
    const userRPC = JSONRPCClientBuilder.build(UserRPC)
    const res = await userRPC
      .listChannels({
        userId: this.props.user.profile!.id,
      })
      .catch(JSONRPCErrorCatch)
    if (res !== -1) {
      this.props.actions.updateMyChannels(res)
    }
  }
}

export const MyChannel = container(Component)
