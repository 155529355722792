import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"
import Color from "../../../../../style/enum/color"
import { Button } from "../../../../parts/buttons/button"

export const Close = (props: { onClick: () => void }) => (
  <StyledButton
    onClick={props.onClick}
    borderColor={Color.TRANSPARENT}
    color={Color.DIMGRAY}
    backgroundColor={Color.TRANSPARENT}
  >
    <FontAwesomeIcon icon={faTimes} />
    <Text>閉じる</Text>
  </StyledButton>
)

const StyledButton = styled(Button)`
  position: absolute;
  top: -5px;
  right: -8px;
  font-size: 1rem;
`

const Text = styled.div`
  font-size: 0.6rem;
  line-height: 1;
  transform: scale(0.8, 0.8);
  position: relative;
  top: -3px;
`
