import { UnreadNotificationCountActions } from "../actions/actions"
import {
  InitialState,
  IUnreadNotificationCount,
  IUnreadNotificationCountAction,
} from "../actions/unreadNotificationCount"

export const unreadNotificationCount = (
  state: IUnreadNotificationCount = InitialState,
  action: UnreadNotificationCountActions,
): IUnreadNotificationCount => {
  switch (action.type) {
    case IUnreadNotificationCountAction.UPDATE_COUNT:
      return {
        ...state,
        unreadCount: action.data,
      }
    default:
      return state
  }
}
