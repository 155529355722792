import { Notification } from "../../rpcs/dist/UserRPC"

export const initialState = {
  notifications: [],
}

export interface INotification {
  notifications: Notification[]
}

export enum NotificationAction {
  INIT = "@NOTIFICATION/INIT",
  ADD = "@NOTIFICATION/ADD",
}

export const notificationInit = (notification: INotification) =>
  ({
    type: NotificationAction.INIT,
    data: notification,
  } as const)

export const notificationAdd = (notification: INotification) =>
  ({
    type: NotificationAction.ADD,
    data: notification,
  } as const)
