import { CreateWidgetActions } from "../../../actions/actions"
import {
  CreateWidgetActionTypes,
  ICreateWidgetState,
  InitialState,
} from "../../../actions/pages/createWidgetActionCreator"

export const createWidget = (
  state: ICreateWidgetState = InitialState,
  action: CreateWidgetActions,
): ICreateWidgetState => {
  switch (action.type) {
    case CreateWidgetActionTypes.updateEpisode:
      return {
        ...state,
        ...action.data,
      }
    case CreateWidgetActionTypes.changeMode:
      return {
        ...state,
        ...action.data,
      }
    case CreateWidgetActionTypes.updateOptions:
      return {
        ...state,
        ...action.data,
      }
    case CreateWidgetActionTypes.updateWidgetId:
      return {
        ...state,
        ...action.data,
      }

    default:
      return state
  }
}
