import React from "react"
import styled, { css } from "styled-components"
import { Channel } from "../../../rpcs/dist/ChannelRPC"
import Color from "../../../style/enum/color"
import { RequireOne } from "../../../types/util-types"

type IProps = {
  size: number | "auto"
  title: string
  noBorder?: boolean
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & RequireOne<{
  url?: string
  profileImage?: Channel["profileImage"]
}>

export const ChannelIcon = (props: IProps) => {
  let url = null
  if (props.url) {
    url = props.url
  }
  if (props.profileImage) {
    url = props.profileImage.square
  }
  return <StyledIcon url={url!} {...props} />
}

const decideSize = ({ size }: IProps) => {
  if (size === "auto") {
    return css`
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    `
  }
  return css`
    width: ${size}px;
    height: ${size}px;
  `
}

const border = ({ noBorder }: IProps) => {
  if (noBorder) {
    return css``
  } else {
    return css`
      border: 1px solid ${Color.LIGHT_GRAY};
    `
  }
}

const StyledIcon = styled.div<IProps>`
  ${decideSize};
  ${border};
  background-size: cover;
  background-image: url(${({ url }) => url});
  flex-shrink: 0;
`
