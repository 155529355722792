export default {
  endpoints: {
    api: "https://api.chatcast.jp",
    auth: "https://auth.chatcast.jp",
    app: "https://chatcast.jp",
    assets: "https://chatcast.jp",
  },
  firebase: {
    apiKey: "AIzaSyB6Hpzpjq-Rs_KtMQ1uXb4YK0f8aVQ07LI",
    authDomain: "chatcast2-production.firebaseapp.com",
    projectId: "chatcast2-production",
  },
  widgetDomain: "https://chatca.st",
}
