import { SwitchingUserActions } from "../actions/actions"
import {
  InitialState,
  ISwitchingUser,
  SwitchingUserAction,
} from "../actions/switchingUserActionCreator"

export const switchingUser = (
  state: ISwitchingUser = InitialState,
  action: SwitchingUserActions,
): ISwitchingUser => {
  switch (action.type) {
    case SwitchingUserAction.BEGIN_SWITCHING_USER:
    case SwitchingUserAction.END_SWITCHING_USER:
      return action.data
    case SwitchingUserAction.UPDATE_SWITCHING_USER_INFO:
      return {
        ...state,
        debug: action.data,
      }
    default:
      return state
  }
}
