import { doLogout } from "../../actions/loginDataActionCreator"
import { beginSwitchingUser, endSwitchingUser } from "../../actions/switchingUserActionCreator"
import store from "../../config/configureStore"
import { NotificationActionDispatcher } from "../../libs/notifications"
import { configForCurrentEnv } from "../../config/environment"

export enum HTTPStatusError {
  Unauthorized = -9995,
}

export enum JSONRPCErrorCode {
  ResourceNotFoundError = -32000,
  InvalidEmailOrPasswordError = -32001,
  ForbiddenError = -32002,
  RequireCertificateVerificationError = -32003,
  BannedUserError = -32004,
  JWTError = -32005,
  ResourceDuplicationError = -32006,
  RestrictedRoomError = -32007,
  MaintenanceError = -32008,
  UserSwitchBeginError = -32009,
  UserSwitchEndError = -32010,

  HTTPResponsibleBasicError = 32099,

  InvalidRequestError = -32600,
  MethodNotFoundError = -32601,
  InvalidParamsError = -32602,
  InternalError = -32603,

  ParseError = -32700,
}

export class JSONRPCError extends Error {
  public code: number
  public date: Date
  public endpoint?: string
  public showError?: boolean = true

  constructor(e: { code: string; message: string; endpoint?: string; showError?: boolean }) {
    super(e.message)
    this.code = Number(e.code)
    this.endpoint = e.endpoint
    this.date = new Date()
    if (e.showError !== undefined) {
      this.showError = e.showError
    }
  }
}

export const JSONRPCErrorHandle = (err: JSONRPCError) => {
  switch (err.code) {
    case HTTPStatusError.Unauthorized:
      store.dispatch(doLogout())
      NotificationActionDispatcher.LogNotification("ログインの有効時間が過ぎたため、3秒後にログアウトします")
      setTimeout(() => {
        window.location.href = `${configForCurrentEnv.endpoints.app}`
      }, 3000)
      // 以後のエラーは出さない
      return
  }

  if (err.showError) {
    switch (err.code) {
      case JSONRPCErrorCode.ResourceNotFoundError:
        NotificationActionDispatcher.ErrorNotification("リソースが見つかりませんでした")
        break
      case JSONRPCErrorCode.UserSwitchBeginError:
        store.dispatch(beginSwitchingUser(JSON.parse(err.message)))
        NotificationActionDispatcher.LogNotification("ユーザースイッチングしました")
        break
      case JSONRPCErrorCode.UserSwitchEndError:
        store.dispatch(endSwitchingUser())
        NotificationActionDispatcher.LogNotification("ユーザースイッチングを解除しました")
        break
      default:
        NotificationActionDispatcher.ErrorNotification(err.message)
    }
  }
}
