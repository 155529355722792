import { ContentWithUser, IChatRoomSerifMemo } from "../../../types/data-types"
import {
  DesktopTabLabel,
  MobileTabLabel,
} from "../../../pages/chat-room/components/live/room/pages/chat/components/shared/enum/desktop-tab-label"

export const InitialState: IChatRoomLocalData = {
  isInitialized: false,
  mode: null,
  generalError: null,
  previewImage: null,
  removeConfirm: null,
  endConfirm: false,
  surveyModal: false,
  isSaving: false,
  selectedDesktopTab: DesktopTabLabel.Comment,
  selectedMobileTab: MobileTabLabel.Chat,
  batch: {
    chat: 0,
    comment: 0,
    member: 0,
  },
  serifMemo: [],
  chatForm: {
    isSending: false,
    value: "",
  },
  feature: {
    serifMemo: false,
  },
}

export interface IChatRoomLocalData {
  isInitialized: boolean
  mode: "desktop" | "mobile" | null
  generalError: string | null
  previewImage: string | null
  removeConfirm: ContentWithUser | null
  endConfirm: boolean
  surveyModal: boolean
  isSaving: boolean
  selectedDesktopTab: DesktopTabLabel
  selectedMobileTab: MobileTabLabel
  batch: {
    chat: number
    comment: number
    member: number
  }
  serifMemo: IChatRoomSerifMemo[]
  chatForm: {
    isSending: boolean
    value: string
  }
  feature: {
    serifMemo: boolean
  }
}

export enum ChatRoomLocalDataAction {
  UpdateIsInitialized = "@CHAT_ROOM_LOCAL_DATA/UPDATE_IS_INITIALIZED",
  ChangeMode = "@CHAT_ROOM_LOCAL_DATA/CHANGE_MODE",
  Reset = "@CHAT_ROOM_LOCAL_DATA/RESET",
  GeneralError = "@CHAT_ROOM_LOCAL_DATA/GENERAL_ERROR",
  UpdatePreviewImage = "@CHAT_ROOM_LOCAL_DATA/UPDATE_PREVIEW_IMAGE",
  ResetPreviewImage = "@CHAT_ROOM_LOCAL_DATA/RESET_PREVIEW_IMAGE",
  UpdateRemoveConfirm = "@CHAT_ROOM_LOCAL_DATA/UPDATE_REMOVE_CONFIRM",
  ResetRemoveConfirm = "@CHAT_ROOM_LOCAL_DATA/RESET_REMOVE_CONFIRM",
  ShowEndConfirm = "@CHAT_ROOM_LOCAL_DATA/SHOW_END_CONFIRM",
  HideEndConfirm = "@CHAT_ROOM_LOCAL_DATA/HIDE_END_CONFIRM",
  Saving = "@CHAT_ROOM_LOCAL_DATA/SAVING",
  SelectedDesktopTab = "@CHAT_ROOM_LOCAL_DATA/SELECTED_DESKTOP_TAB",
  SelectedMobileTab = "@CHAT_ROOM_LOCAL_DATA/SELECTED_MOBILE_TAB",
  PartialUpdateBatch = "@CHAT_ROOM_LOCAL_DATA/PARTIAL_UPDATE_BATCH",
  ShowSurveyModal = "@CHAT_ROOM_LOCAL_DATA/SHOW_SURVEY_MODAL",
  HideSurveyModal = "@CHAT_ROOM_LOCAL_DATA/CLOSE_SURVEY_MODAL",
  AddSingleSerifMemoFromForm = "@CHAT_ROOM_LOCAL_DATA/ADD_SINGLE_SERIF_MEMO_FROM_FORM",
  AddSerifMemoFromCsv = "@CHAT_ROOM_LOCAL_DATA/ADD_SERIF_MEMO_FROM_Csv",

  PickupSerifMemo = "@CHAT_ROOM_LOCAL_DATA/PICKUP_SERIF_MEMO",
  OnChangeChatTextareaValue = "@CHAT_ROOM_LOCAL_DATA/ON_CHANGE_CHAT_TEXTAREA_VALUE",
  ClearChatTextareaValue = "@CHAT_ROOM_LOCAL_DATA/CLEAR_CHAT_TEXTAREA_VALUE",
  OnSendChatFormText = "@CHAT_ROOM_LOCAL_DATA/ON_SEND_CHAT_FORM_TEXT",
  failedToSendChatFormText = "@CHAT_ROOM_LOCAL_DATA/FAILED_TO_SEND_CHAT_FORM_TEXT",

  OnToggleSerifMemoFeature = "@CHAT_ROOM_LOCAL_DATA/ON_TOGGLE_SERIF_MEMO_FEATURE",
}

export const updateIsInitialized = (isInitialized: IChatRoomLocalData["isInitialized"]) =>
  ({
    type: ChatRoomLocalDataAction.UpdateIsInitialized,
    data: isInitialized,
  } as const)

export const changeMode = (mode: NonNullable<IChatRoomLocalData["mode"]>) =>
  ({
    type: ChatRoomLocalDataAction.ChangeMode,
    data: mode,
  } as const)

export const updatePreviewImage = (base64string: string) =>
  ({
    type: ChatRoomLocalDataAction.UpdatePreviewImage,
    data: base64string,
  } as const)

export const resetPreviewImage = () =>
  ({
    type: ChatRoomLocalDataAction.ResetPreviewImage,
    data: null,
  } as const)

export const updateRemoveConfirm = (message: ContentWithUser) =>
  ({
    type: ChatRoomLocalDataAction.UpdateRemoveConfirm,
    data: message,
  } as const)

export const resetRemoveConfirm = () =>
  ({
    type: ChatRoomLocalDataAction.ResetRemoveConfirm,
    data: null,
  } as const)

export const showEndConfirm = () =>
  ({
    type: ChatRoomLocalDataAction.ShowEndConfirm,
    data: true,
  } as const)

export const hideEndConfirm = () =>
  ({
    type: ChatRoomLocalDataAction.HideEndConfirm,
    data: false,
  } as const)

export const updateSaveState = (isSaving: boolean) =>
  ({
    type: ChatRoomLocalDataAction.Saving,
    data: isSaving,
  } as const)

export const selectedDesktopTab = (tabLabel: DesktopTabLabel) =>
  ({
    type: ChatRoomLocalDataAction.SelectedDesktopTab,
    data: tabLabel,
  } as const)

export const selectedMobileTab = (tabLabel: MobileTabLabel) =>
  ({
    type: ChatRoomLocalDataAction.SelectedMobileTab,
    data: tabLabel,
  } as const)

export const batch = (partialBatch: Partial<IChatRoomLocalData["batch"]>) =>
  ({
    type: ChatRoomLocalDataAction.PartialUpdateBatch,
    data: partialBatch,
  } as const)

export const showSurveyModal = () =>
  ({
    type: ChatRoomLocalDataAction.ShowSurveyModal,
    data: true,
  } as const)

export const hideSurveyModal = () =>
  ({
    type: ChatRoomLocalDataAction.HideSurveyModal,
    data: false,
  } as const)

export const addSingleSerifMemoFromForm = (serifMemo: IChatRoomSerifMemo) =>
  ({
    type: ChatRoomLocalDataAction.AddSingleSerifMemoFromForm,
    data: serifMemo,
  } as const)

export const addSerifMemoFromCsv = (serifMemoArray: IChatRoomSerifMemo[]) =>
  ({
    type: ChatRoomLocalDataAction.AddSerifMemoFromCsv,
    data: serifMemoArray,
  } as const)

export const pickupSerifMemo = (serifMemo: IChatRoomSerifMemo) =>
  ({
    type: ChatRoomLocalDataAction.PickupSerifMemo,
    data: serifMemo,
  } as const)

export const onChangeChatTextareaValue = (text: string) =>
  ({
    type: ChatRoomLocalDataAction.OnChangeChatTextareaValue,
    data: text,
  } as const)

export const onSendChatFormText = () =>
  ({
    type: ChatRoomLocalDataAction.OnSendChatFormText,
  } as const)

export const clearChatTextareaValue = () =>
  ({
    type: ChatRoomLocalDataAction.ClearChatTextareaValue,
  } as const)

export const failedToSendChatFormText = () =>
  ({
    type: ChatRoomLocalDataAction.failedToSendChatFormText,
  } as const)

export const onToggleSerifMemoFeature = () =>
  ({
    type: ChatRoomLocalDataAction.OnToggleSerifMemoFeature,
  } as const)

export const reset = () =>
  ({
    type: ChatRoomLocalDataAction.Reset,
    data: {},
  } as const)

export const generalError = (message: string) =>
  ({
    type: ChatRoomLocalDataAction.GeneralError,
    data: message,
  } as const)
