import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Color from "../../../../style/enum/color"
import { Button } from "../../../parts/buttons/button"
import { Modal, ModalWrapper } from "../../../parts/modal"
import { UserIcon } from "../../../parts/user-icon"
import { container, IContainerProps } from "./container"

export const UserModal = container((props: IContainerProps) => {
  if (props.user) {
    return (
      <StyledModalWrapper onClick={() => props.actions.removeUserModal()}>
        <Modal>
          <Centering>
            <IconWrapper>
              <UserIcon
                size={140}
                title={"ユーザー"}
                url={props.user.profileImage.square}
              />
            </IconWrapper>
            <ScreenName>{props.user.screenName}</ScreenName>
            <Name>@{props.user.name}</Name>
            <Description>
              <div>{props.user.description}</div>
            </Description>
            <Link to={`/user/${props.user.name}`}>
              <Button
                backgroundColor={Color.BRAND}
                color={Color.WHITE}
                small={true}
              >
                ユーザーページを見る
              </Button>
            </Link>
          </Centering>
        </Modal>
      </StyledModalWrapper>
    )
  }
  return null
})

const StyledModalWrapper = styled(ModalWrapper)`
  display: flex;
  justify-content: safe center;
  align-items: safe center;
`

const IconWrapper = styled.div`
  padding: 16px 0;
`

const ScreenName = styled.h2`
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
`

const Name = styled.div`
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  color: ${Color.DIMGRAY};
`

const Centering = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Description = styled.div`
  padding: 16px 0;
`
