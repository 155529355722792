import { EDIT_TYPE, IEditorContent } from "../../../pages/editor/types"
import { Episode, Types } from "../../../rpcs/dist/EpisodeRPC"
import { ContentWithUser } from "../../../types/data-types"
import { CreateContent } from "../../create-content"
import { constructContentWithUser } from "../construct-content-with-user"

export const ConvertEditorEpisodeFromEpisode = (
  res: Episode,
): IEditorContent[] => {
  const newConstants = constructContentWithUser(res)

  // イントロダクションがなければ空のイントロダクションを追加する
  if (!newConstants.some(c => c.type === Types.Introduction)) {
    newConstants.unshift(CreateContent.Introduction(""))
  }

  return newConstants.map(convertEditorContentFromContent)
}

export const convertEditorContentFromContent = (
  content: ContentWithUser,
): IEditorContent => {
  return {
    ...content,
    _editMetaData: {
      type: EDIT_TYPE.SHOW,
    },
  }
}
