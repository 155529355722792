import { ISwitchingUser } from "../../actions/switchingUserActionCreator"
import { LoginData, User } from "../../rpcs/dist/UserRPC"

export const getCurrentUser = (
  loginData: LoginData | null,
  switchingUser: ISwitchingUser,
): User | null => {
  if (loginData && loginData.jwt && loginData.user) {
    if (switchingUser.token && switchingUser.debug) {
      return switchingUser.debug
    }
    return loginData.user
  }
  return null
}
