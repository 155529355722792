import React from "react"
import {
  AutoLoadEpisodes,
  IEpisodeFuncProps,
} from "../../../../../../../components/templates/auto-load-episodes"
import { Wrapper } from "../../../../../../../components/templates/wrapper"
import { JSONRPCClientBuilder } from "../../../../../../../request/JSONRPCClientBuilder"
import { Channel, ChannelRPC } from "../../../../../../../rpcs/dist/ChannelRPC"
import { Episode } from "../../../../../../../rpcs/dist/EpisodeRPC"
import Width from "../../../../../../../style/enum/width"
import { container, IContainerProps } from "../container"

type IProps = {
  channel: Channel
} & IContainerProps

class Component extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.update = this.update.bind(this)
    this.reset = this.reset.bind(this)
    this.getEpisodeFunc = this.getEpisodeFunc.bind(this)
  }

  public render() {
    return (
      <Wrapper maxWidth={Width.EPISODE_LIST}>
        <AutoLoadEpisodes
          episodes={this.props.drafts}
          update={this.update}
          reset={this.reset}
          getEpisodeFunc={this.getEpisodeFunc}
        />
      </Wrapper>
    )
  }

  private update(episodes: Episode[]) {
    this.props.actions.updateDrafts(episodes)
  }

  private reset() {
    this.props.actions.resetDrafts()
  }

  private getEpisodeFunc(props: IEpisodeFuncProps) {
    const channelRPC = JSONRPCClientBuilder.build(ChannelRPC)
    return channelRPC.listDrafts({
      channelId: this.props.channel.id,
      page: props.page,
    })
  }
}

export const Draft = container(Component)
