import React from "react"
import styled from "styled-components"
import { Wrapper } from "../wrapper"
import Color from "../../../style/enum/color"
import { Link } from "react-router-dom"

interface IProps {
  isShow: boolean
}

const ItemList = [
  {
    title: "よくある質問",
    to: "/information/faq",
  },
  {
    title: "運営会社",
    to: "/information/company",
  },
  {
    title: "利用規約",
    to: "/information/tos",
  },
  {
    title: "プライバシーポリシー",
    to: "/information/privacy",
  },
  {
    title: "お問い合わせ",
    to: "/information/contact",
  },
]

export const Footer = React.memo(({ isShow }: IProps) => {
  if (!isShow) {
    return null
  }
  return (
    <Background>
      <Wrapper>
        <div>
          <List>
            {ItemList.map(item => (
              <Item key={item.to}>
                <Link to={item.to}>{item.title}</Link>
              </Item>
            ))}
          </List>
        </div>
        <CopyRight>Copyright &copy; ChatCast All Rights Reserved.</CopyRight>
      </Wrapper>
    </Background>
  )
})

const Background = styled.div`
  color: rgba(0, 0, 0, 0.5);
  padding: 32px 48px;
  background-color: ${Color.THIN_GRAY};
  border-top: 1px solid #eee;
`

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  font-size: 0.85rem;
`

const Item = styled.li`
  & + &:before {
    content: ".";
    margin: 0 10px;
  }
  a {
    color: ${Color.DIMGRAY};
  }
`

const CopyRight = styled.div`
  font-size: 0.8rem;
`
