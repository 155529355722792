/* eslint-disable */
import "reflect-metadata"
import * as JSONRPC from "jsonrpc-compiler/lib/core/jsonrpc"
import { plainToClass } from "class-transformer"
import { readCacheIfNeeded, writeCacheIfNeeded } from "../middlewares/cache"

import { User } from "./UserRPC"
import { ChatRoom } from "./ChatRoomRPC"
import { Type } from "class-transformer"
import { Episode } from "./EpisodeRPC"

export enum PaymentMethod {
  Subscription = 1,
  SemiAnnualPayment = 2,
  AnnualPayment = 3,
}

export class ChannelMember {
  @Type(() => User)
  public user: User
  public isPending: boolean
}

export type Privilege = "LimitedArtcile" | "Widget" | "ScheduledChatRoom" | "Survey" | "TimeExtendable"

export interface BillingPlan {
  planId: number
  widget: boolean // ウィジェット化
  limitedArtcile: boolean // 限定公開
  scheduledChatRoom: boolean
  survey: boolean
  timeExtendable: boolean // チャットルーム時間延長
}

export abstract class BillingPlanStaticInterface {
  static getPlanId() {
    return 0
  }
}

export class FreePlan extends BillingPlanStaticInterface implements BillingPlan {
  public planId = FreePlan.getPlanId()
  public widget = false
  public limitedArtcile = false
  public scheduledChatRoom = false
  public survey = false
  public timeExtendable = false

  static getPlanId() {
    return 1
  }
}

export class BasicPlan implements BillingPlan {
  public planId = BasicPlan.getPlanId()
  public widget = true
  public limitedArtcile = true
  public scheduledChatRoom = true
  public survey = true
  public timeExtendable = true

  static getPlanId() {
    return 2
  }
}

export class BusinessPlan implements BillingPlan {
  public planId = BusinessPlan.getPlanId()
  public widget = true
  public limitedArtcile = true
  public scheduledChatRoom = true
  public survey = true
  public timeExtendable = true

  static getPlanId() {
    return 3
  }
}

export class BillingStatus {
  public paymentMethod?: PaymentMethod
  public plan: BillingPlan
  public expiresAt?: string
}

export class Channel {
  public id: number
  public profileImage: {
    large: string
    medium: string
    thum: string
    square: string
  }
  @Type(() => User)
  public user: User
  public name: string
  public screenName: string
  public description?: string
  public isDefault: boolean
  public isOfficial: boolean
  @Type(() => ChannelMember)
  public members: ChannelMember[]
  public isFollowedByMe?: boolean
  public billingStatus: BillingStatus
}

export class CreateChannelInput {
  public name: string
  public screenName: string
  public description?: string
  public image?: {
    base64EncodedData: string
    contentType: string
  }
}

export class CreateChatRoomInput {
  public scheduled?: {
    // yyyy-MM-DDTHH:mm+09:00
    iso8601StartFrom: string
  }
  public additionalSeconds?: number
  public channelId: number
  public topic?: string
  public passcode?: string
}

export class InviteInput {
  public userId: string
  public channelId: number
}

export class BasicChannelIdInput {
  public channelId: number
}

export class ProfileInput {
  public channelName: string
}

export class BasicChannelIdPaginationInput {
  public channelId: number
  public page?: number
}

export class Empty {}

export class ChannelProfile extends Channel {
  public episodesCount: number
  public followersCount: number
}

export class UpdateChannelInput {
  public id: number
  public screenName?: string
  public description?: string
  public image?: {
    base64EncodedData: string
    contentType: string
  }
  public password?: {
    currentPassword: string
    newPassword: string
    newPasswordForConfirmation: string
  }
}

export class FeaturedChannel {
  public id: number
  public coverPhoto: {
    large: string
    medium: string
    thum: string
    square: string
  }
  public channel: Channel
  public sortNum: string
}

export class FetchLiveScheduleInput {
  public channelId: number
}

interface IOptions {
  timeout: number
  cacheUsable: boolean
}

const optionDefaultValues: IOptions = {
  timeout: 15000,
  cacheUsable: true,
}

export class ChannelRPC {
  constructor(public endpoint: string, public requestHeaders: { [key: string]: string } = {}) {}

  async createChatRoom(input: CreateChatRoomInput, options?: Partial<IOptions>): Promise<ChatRoom> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "createChatRoom",
        input: input,
      })
      if (cached) {
        return cached as ChatRoom
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.createChatRoom", input)

    return new Promise<ChatRoom>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoom, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "createChatRoom",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.createChatRoom",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async createChannel(input: CreateChannelInput, options?: Partial<IOptions>): Promise<Channel> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "createChannel",
        input: input,
      })
      if (cached) {
        return cached as Channel
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.createChannel", input)

    return new Promise<Channel>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Channel, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "createChannel",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.createChannel",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async inviteToChannel(input: InviteInput, options?: Partial<IOptions>): Promise<Channel> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "inviteToChannel",
        input: input,
      })
      if (cached) {
        return cached as Channel
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.inviteToChannel", input)

    return new Promise<Channel>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Channel, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "inviteToChannel",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.inviteToChannel",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async acceptChannelInvite(input: BasicChannelIdInput, options?: Partial<IOptions>): Promise<Channel> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "acceptChannelInvite",
        input: input,
      })
      if (cached) {
        return cached as Channel
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.acceptChannelInvite", input)

    return new Promise<Channel>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Channel, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "acceptChannelInvite",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.acceptChannelInvite",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async revokeChannelInvite(input: BasicChannelIdInput, options?: Partial<IOptions>): Promise<Channel> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "revokeChannelInvite",
        input: input,
      })
      if (cached) {
        return cached as Channel
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.revokeChannelInvite", input)

    return new Promise<Channel>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Channel, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "revokeChannelInvite",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.revokeChannelInvite",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async profile(input: ProfileInput, options?: Partial<IOptions>): Promise<ChannelProfile> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "profile",
        input: input,
      })
      if (cached) {
        return cached as ChannelProfile
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.profile", input)

    return new Promise<ChannelProfile>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChannelProfile, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "profile",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.profile",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async followChannel(input: BasicChannelIdInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "followChannel",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.followChannel", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "followChannel",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.followChannel",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async unfollowChannel(input: BasicChannelIdInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "unfollowChannel",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.unfollowChannel", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "unfollowChannel",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.unfollowChannel",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listFollowers(input: BasicChannelIdPaginationInput, options?: Partial<IOptions>): Promise<User[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "listFollowers",
        input: input,
      })
      if (cached) {
        return cached as User[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.listFollowers", input)

    return new Promise<User[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(User, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "listFollowers",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.listFollowers",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listEpisodes(input: BasicChannelIdPaginationInput, options?: Partial<IOptions>): Promise<Episode[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "listEpisodes",
        input: input,
      })
      if (cached) {
        return cached as Episode[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.listEpisodes", input)

    return new Promise<Episode[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "listEpisodes",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.listEpisodes",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listDrafts(input: BasicChannelIdPaginationInput, options?: Partial<IOptions>): Promise<Episode[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "listDrafts",
        input: input,
      })
      if (cached) {
        return cached as Episode[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.listDrafts", input)

    return new Promise<Episode[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "listDrafts",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.listDrafts",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async updateChannel(input: UpdateChannelInput, options?: Partial<IOptions>): Promise<Channel> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "updateChannel",
        input: input,
      })
      if (cached) {
        return cached as Channel
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.updateChannel", input)

    return new Promise<Channel>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Channel, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "updateChannel",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.updateChannel",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async deleteChannel(input: BasicChannelIdInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "deleteChannel",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.deleteChannel", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "deleteChannel",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.deleteChannel",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listFeaturedChannels(options?: Partial<IOptions>): Promise<FeaturedChannel[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    const cached = await readCacheIfNeeded({
      namespace: "ChannelRPC",
      method: "listFeaturedChannels",
    })
    if (cached) {
      return cached as FeaturedChannel[]
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.listFeaturedChannels")

    return new Promise<FeaturedChannel[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(FeaturedChannel, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "listFeaturedChannels",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.listFeaturedChannels",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async fetchLiveScheduleFor(input: FetchLiveScheduleInput, options?: Partial<IOptions>): Promise<ChatRoom[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "ChannelRPC",
        method: "fetchLiveScheduleFor",
        input: input,
      })
      if (cached) {
        return cached as ChatRoom[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ChannelRPC.fetchLiveScheduleFor", input)

    return new Promise<ChatRoom[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(ChatRoom, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "ChannelRPC",
              method: "fetchLiveScheduleFor",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "ChannelRPC.fetchLiveScheduleFor",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }
}
