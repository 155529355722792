import { RpcResultHistoryActions } from "../actions/actions"
import {
  IRPCResultHistory,
  RPCResultHistoryAction,
} from "../actions/RPCResultHistoryAction"

const rpcResultHistories = (
  state: IRPCResultHistory | null = null,
  action: RpcResultHistoryActions,
): IRPCResultHistory | null => {
  switch (action.type) {
    case RPCResultHistoryAction.Update:
      return action.data

    default:
      return state
  }
}
export default rpcResultHistories
