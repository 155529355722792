import { NotificationActions } from "../../../actions/actions"
import {
  initialState,
  INotification,
  NotificationAction,
} from "../../../actions/pages/notificationActionCreator"

export const notification = (
  state: INotification = initialState,
  action: NotificationActions,
): INotification => {
  switch (action.type) {
    case NotificationAction.INIT:
      return action.data
    case NotificationAction.ADD:
      return {
        notifications: state.notifications.concat(action.data.notifications),
      }
    default:
      return state
  }
}
