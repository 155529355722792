import { createStore, applyMiddleware, compose } from "redux"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import ReduxThunk from "redux-thunk"
import { LoginDataAction } from "../actions/loginDataActionCreator"

import { ReduxAction, rootReducer, RootState } from "../reducers"
import LogRocket from "logrocket"

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [
    "loginData",
    "firebaseSNSLoginState",
    "unlockTokens",
    "switchingUser",
  ],
}

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const resetReducerForLogout = (state: RootState, action: ReduxAction) => {
  if (action.type === LoginDataAction.Logout) {
    // @ts-ignore
    state = undefined
  }
  return rootReducer(state, action)
}

const persistedReducer = persistReducer(
  rootPersistConfig,
  resetReducerForLogout,
)
const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(...[ReduxThunk, LogRocket.reduxMiddleware()]),
  ),
)

export const persistor = persistStore(store)
export default store
