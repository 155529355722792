import styled, { css } from "styled-components"
import { HeaderHeight } from "../style/variables"
import Color from "../../../../style/enum/color"
import { media } from "../../../../style/const/media-query"
export const Background = styled.div<{
  fixed?: boolean
}>`
  height: ${HeaderHeight}px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.WHITE};
  padding: 0 15px;
  border-bottom: 1px solid ${Color.LIGHT_GRAY};
  box-sizing: border-box;
  ${({ fixed }) => fixed && fixedStyled};
  ${media.phone(css`
    padding: 0 10px;
  `)};
`

const fixedStyled = css`
  position: fixed;
  z-index: 1;
`
