import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Color from "../../../../style/enum/color"
import { Close } from "../components/auth-close-button"
import { AuthFrame } from "../components/auth-frame"
import { AuthMethodLink } from "../components/auth-method-link"
import { AuthSnsConnectLink } from "../components/auth-sns-connect-link"
import { Form } from "./form/form"
import { IFormField } from "./form/form-meta"

interface IProps {
  submit: (values: IFormField) => void
  onClickSignInButton: () => void
  onClose?: () => void
  isModal?: boolean
}

const AuthMethod = "新規登録"

export const SignUp = (props: IProps) => (
  <AuthFrame title={AuthMethod} isModal={props.isModal}>
    <Form onSubmit={props.submit} />
    {props.onClose && <Close onClick={props.onClose} />}
    <AuthSnsConnectLink connectType={AuthMethod} />
    <AuthMethodLink>
      <StyledButton onClick={props.onClickSignInButton}>
        ログインする
      </StyledButton>
      <Link to={"/password-reset"} onClick={props.onClose}>
        <StyledButton>パスワードを忘れた</StyledButton>
      </Link>
    </AuthMethodLink>
  </AuthFrame>
)

export const StyledButton = styled.span`
  cursor: pointer;
  color: ${Color.LIGHT_BLACK};
`
