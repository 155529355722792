import { ContentWithUser } from "../../types/data-types"

export enum EDIT_TYPE {
  SHOW,
  MENU,
  EDIT,
  REMOVE,
}

export type IEditorContent = ContentWithUser & {
  _editMetaData: {
    type: EDIT_TYPE
  }
}
