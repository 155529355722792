/* eslint-disable */
import "reflect-metadata"
import * as JSONRPC from "jsonrpc-compiler/lib/core/jsonrpc"
import { plainToClass } from "class-transformer"
import { readCacheIfNeeded, writeCacheIfNeeded } from "../middlewares/cache"

import { User, UpdateProfileImage } from "./UserRPC"
import { ChatRoom } from "./ChatRoomRPC"
import { Channel } from "./ChannelRPC"

export enum Visiblity {
  Draft = 1,
  Public = 2,
  Limited = 3,
}

export class Tag {
  public id: number
  public tagName: string
}

export class Episode {
  public id: number
  public displayId: string
  public chatRoom: ChatRoom
  public user?: User
  public channel?: Channel
  public title: string
  public description: string
  public content: string
  public visiblity: Visiblity
  public publishedAt: string
  public publicListable: boolean
  public version: string
  public isAlreadySentNotification: boolean
  public numberOfLikes?: number
  public tags?: Tag[]
  public pickedUsers?: User[]
  public isEditable?: boolean
  public isWidgetable?: boolean
  public isLiked?: boolean
  public metadata?: Metadata
}

export class Content {
  public uuid: string
  public postedAt: string | null
  public userId: string | null
  public pickedUserId?: string
  public type: Types
  public quote?: Quote
  public blockStyle: number[]
  public blocks: Block[]
  public survey?: {
    id: number
  }
}

export enum Types {
  Message = 0,
  Annotation = 1,
  Introduction = 2,
  Pickup = 3,
  Tweet = 4, // 廃止
  Headline = 5,
  Quotation = 6,
  Survey = 7,
}

export class Quote {
  source: string
  relation: string
}

export class Block {
  key: string
  text: string
  inlineStyleRanges: InlineStyleRanges[]
}

export class InlineStyleRanges {
  offset: number
  length: number
  style: number
}

export class Metadata {
  customUserInfoList: Array<Pick<User, "id"> & Partial<User>>
}

export class PaginationInput {
  // episode.id
  public page: number
}

export enum EpisodeSavingAction {
  Publish = 1,
  Update = 2,
  Draft = 3,
  Limited = 4,
}

export class UpdateEpisodeInput {
  public displayId: string
  public title?: string
  public content?: string
  public channelName?: string | null
  public action: EpisodeSavingAction
  public tags?: string[]
  public metadata?: string
}

export class DeleteInput {
  public displayId: string
}

export class Empty {}

export class SimpleEpisodeInput {
  public displayId: string
}

export class ListLikedUserInput {
  public displayId: string
  public page?: number
}

export class ListEpisodeInput {
  public userId: string
  public page?: number
}

export class ListEpisodesByTagInput {
  public tag: string
  public page?: number
}

export class OverwriteAnonymousUserInfoInput {
  public displayId: string
  public user: {
    id: string
    profileImage: UpdateProfileImage
  }
}

interface IOptions {
  timeout: number
  cacheUsable: boolean
}

const optionDefaultValues: IOptions = {
  timeout: 15000,
  cacheUsable: true,
}

export class EpisodeRPC {
  constructor(public endpoint: string, public requestHeaders: { [key: string]: string } = {}) {}

  async newEpisodes(input?: PaginationInput, options?: Partial<IOptions>): Promise<Episode[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "newEpisodes",
        input: input,
      })
      if (cached) {
        return cached as Episode[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.newEpisodes", input)

    return new Promise<Episode[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "newEpisodes",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.newEpisodes",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async recommends(options?: Partial<IOptions>): Promise<Episode[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    const cached = await readCacheIfNeeded({
      namespace: "EpisodeRPC",
      method: "recommends",
    })
    if (cached) {
      return cached as Episode[]
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.recommends")

    return new Promise<Episode[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "recommends",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.recommends",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listPublicEpisodes(input: ListEpisodeInput, options?: Partial<IOptions>): Promise<Episode[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "listPublicEpisodes",
        input: input,
      })
      if (cached) {
        return cached as Episode[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.listPublicEpisodes", input)

    return new Promise<Episode[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "listPublicEpisodes",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.listPublicEpisodes",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async updateEpisode(input: UpdateEpisodeInput, options?: Partial<IOptions>): Promise<Episode> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "updateEpisode",
        input: input,
      })
      if (cached) {
        return cached as Episode
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.updateEpisode", input)

    return new Promise<Episode>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "updateEpisode",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.updateEpisode",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async deleteEpisode(input: DeleteInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "deleteEpisode",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.deleteEpisode", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "deleteEpisode",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.deleteEpisode",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async fetchEpisodeDetail(input: SimpleEpisodeInput, options?: Partial<IOptions>): Promise<Episode> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "fetchEpisodeDetail",
        input: input,
      })
      if (cached) {
        return cached as Episode
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.fetchEpisodeDetail", input)

    return new Promise<Episode>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "fetchEpisodeDetail",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.fetchEpisodeDetail",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async doLike(input: SimpleEpisodeInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "doLike",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.doLike", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "doLike",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.doLike",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async doUnlike(input: SimpleEpisodeInput, options?: Partial<IOptions>): Promise<Empty> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "doUnlike",
        input: input,
      })
      if (cached) {
        return cached as Empty
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.doUnlike", input)

    return new Promise<Empty>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Empty, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "doUnlike",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.doUnlike",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listLikedUser(input: ListLikedUserInput, options?: Partial<IOptions>): Promise<User[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "listLikedUser",
        input: input,
      })
      if (cached) {
        return cached as User[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.listLikedUser", input)

    return new Promise<User[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(User, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "listLikedUser",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.listLikedUser",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async listEpisodesByTag(input: ListEpisodesByTagInput, options?: Partial<IOptions>): Promise<Episode[]> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "listEpisodesByTag",
        input: input,
      })
      if (cached) {
        return cached as Episode[]
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.listEpisodesByTag", input)

    return new Promise<Episode[]>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(Episode, obj.result)

          const result = <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "listEpisodesByTag",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.listEpisodesByTag",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }

  async overwriteAnonymousUserInfo(input: OverwriteAnonymousUserInfoInput, options?: Partial<IOptions>): Promise<User> {
    const mergeDefaultOptions: IOptions = { ...optionDefaultValues, ...options }

    if (mergeDefaultOptions.cacheUsable) {
      const cached = await readCacheIfNeeded({
        namespace: "EpisodeRPC",
        method: "overwriteAnonymousUserInfo",
        input: input,
      })
      if (cached) {
        return cached as User
      }
    }
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "EpisodeRPC.overwriteAnonymousUserInfo", input)

    return new Promise<User>((resolve, reject) => {
      function reqListener() {
        if (oReq.status === 401) {
          reject({ code: -9995, message: "認証に失敗しました" })
        } else if (oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: "現在メンテナンス中です。" })
        } else if (oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error)
          } catch (error) {
            return reject(oReq.responseText)
          }
        }
        const obj = JSON.parse(oReq.responseText)
        if (obj.result) {
          const out = plainToClass(User, obj.result)

          const result = (<any>out)[0] ? (<any>out)[0] : <any>out
          writeCacheIfNeeded(
            {
              namespace: "EpisodeRPC",
              method: "overwriteAnonymousUserInfo",
            },
            result,
          )
          resolve(result)
        } else if (obj.error) {
          reject({
            ...obj.error,
            endpoint: "EpisodeRPC.overwriteAnonymousUserInfo",
          })
        } else {
          resolve()
        }
      }

      function reqErrorListener() {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: "通信に失敗しました。" })
        } else {
          // 通信エラー
          reject({ code: -9999, message: "サーバーとの通信に失敗しました。" })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: "接続がタイムアウトしました。" })
      }

      var oReq = new XMLHttpRequest()
      oReq.addEventListener("load", reqListener)
      oReq.addEventListener("error", reqErrorListener)
      oReq.addEventListener("timeout", reqTimeoutListener)
      oReq.open("POST", this.endpoint + "/jsonrpc")
      oReq.timeout = mergeDefaultOptions.timeout
      oReq.setRequestHeader("Content-Type", "application/json")
      for (let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key])
      }
      oReq.send(jrpcBody.toString())
    })
  }
}
