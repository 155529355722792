import styled from "styled-components"
import Color from "../../../style/enum/color"

export const Headline = styled.h3<{
  horizontalMargin?: boolean
}>`
  font-weight: normal;
  font-size: 1.17rem;
  margin: 32px 0 4px;
  margin-left: ${({ horizontalMargin }) => (horizontalMargin ? 16 : 0)}px;
  margin-right: ${({ horizontalMargin }) => (horizontalMargin ? 16 : 0)}px;
  color: ${Color.LIGHT_BLACK};
`
