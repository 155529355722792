import React from "react"
import { Loading } from "../../../../../../components/parts/loading"
import { SpaceWithText } from "../../../../../../components/parts/space-with-text"
import { JSONRPCErrorCatch } from "../../../../../../error/error"
import { JSONRPCClientBuilder } from "../../../../../../request/JSONRPCClientBuilder"
import { UserRPC } from "../../../../../../rpcs/dist/UserRPC"
import { container, IContainerProps } from "../../../../container"
import { ChannelList } from "../../components/channel-list"

type IProps = IContainerProps

export class Component extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.getChannelsFromRPCIfneeded = this.getChannelsFromRPCIfneeded.bind(this)
  }

  public componentDidMount() {
    this.getChannelsFromRPCIfneeded()
  }

  public render() {
    const { followChannels } = this.props.user

    // 読み込み中
    if (!followChannels) {
      return <Loading verticalPadding={"15vh"} />
    }

    // チャンネルがない
    if (followChannels.length === 0) {
      return <SpaceWithText>チャンネルがありません</SpaceWithText>
    }

    // チャンネルがある
    return <ChannelList channels={followChannels} />
  }

  private async getChannelsFromRPCIfneeded() {
    if (this.props.user.followChannels === null) {
      const userRPC = JSONRPCClientBuilder.build(UserRPC)
      const res = await userRPC
        .listFollowingChannels({
          userId: this.props.user.profile!.id,
        })
        .catch(JSONRPCErrorCatch)
      if (res !== -1) {
        this.props.actions.updateFollowChannels(res)
      }
    }
  }
}

export const FollowChannel = container(Component)
