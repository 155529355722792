import { combineReducers } from "redux"
import { channel } from "./channel"
import { chatRoom } from "./chat-room"
import { editor } from "./editor"
import { episodeReducer } from "./episode"
import { top } from "./top"
import user from "./user"
import { recentEpisode } from "./recent-episode"
import { createRoom } from "./create-room"
import { offair } from "./offair"
import { createWidget } from "./create-widget"
import { notification } from "./notification"
import { tag } from "./tag"

export const pageReducer = combineReducers({
  chatRoom,
  editor,
  episode: episodeReducer,
  user,
  channel,
  top,
  recentEpisode,
  createRoom,
  offair,
  createWidget,
  notification,
  tag,
})

export type IRootPagesState = ReturnType<typeof pageReducer>
