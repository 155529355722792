export enum DesktopTabLabel {
  Comment = 0,
  Member = 1,
  Profile = 2,
  RoomSetting = 3,
  SerifMemo = 4,
}

export enum MobileTabLabel {
  Chat = 0,
  Comment = 1,
  Member = 2,
  Setting = 3,
}
