import React from "react"
import {
  AutoLoadEpisodes,
  IEpisodeFuncProps,
} from "../../../../../../components/templates/auto-load-episodes"
import { JSONRPCClientBuilder } from "../../../../../../request/JSONRPCClientBuilder"
import { Episode } from "../../../../../../rpcs/dist/EpisodeRPC"
import { UserRPC } from "../../../../../../rpcs/dist/UserRPC"
import { container, IContainerProps } from "../../../../container"

type IProps = IContainerProps

export class Component extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.update = this.update.bind(this)
    this.reset = this.reset.bind(this)
    this.getEpisodeFunc = this.getEpisodeFunc.bind(this)
  }

  public render() {
    const { drafts } = this.props.user

    // チャンネルがある
    return (
      <AutoLoadEpisodes
        episodes={drafts}
        update={this.update}
        reset={this.reset}
        getEpisodeFunc={this.getEpisodeFunc}
      />
    )
  }

  private update(res: Episode[]) {
    this.props.actions.addDrafts(res)
  }

  private reset() {
    this.props.actions.resetDrafts()
  }

  private getEpisodeFunc(props: IEpisodeFuncProps) {
    const userRPC = JSONRPCClientBuilder.build(UserRPC)
    return userRPC.listDrafts({
      page: props.page,
    })
  }
}

export const Draft = container(Component)
