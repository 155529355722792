import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Color from "../../../style/enum/color"
import { Button } from "../../parts/buttons/button"
import { Wrapper } from "../wrapper"

export const NeedsLogin = () => {
  return (
    <StyledWrapper defaultHorizontalPadding={true}>
      <Title>ログインが必要なページです</Title>
      <Description>
        <p>このページを表示するにはログインが必要です</p>
      </Description>
      <Links>
        <Link to={"/signin"}>
          <Button>ログイン</Button>
        </Link>
        <Link to={"/signup"}>
          <Button backgroundColor={Color.BRAND} color={Color.WHITE}>
            新規登録
          </Button>
        </Link>
      </Links>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Wrapper)`
  padding: 20vh;
`

const Title = styled.h1`
  text-align: center;
  font-size: 1.6rem;
`

const Description = styled.div`
  text-align: center;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  > * {
    margin: 16px 8px;
  }
`
