import { User } from "../rpcs/dist/UserRPC"

export interface IModals {
  signin: boolean
  signup: boolean
  image: string | null
  user: User | null
}

export const initModals: IModals = {
  signin: false,
  signup: false,
  image: null,
  user: null,
}

export enum ModalsAction {
  AUTH_UPDATE = "@MODAL/AUTH_UPDATE",
  SET_IMAGE_URL = "@MODAL/SET_IMAGE_URL",
  REMOVE_IMAGE_URL = "@MODAL/REMOVE_IMAGE_URL",
  SET_USER_DATA = "@MODAL/SET_USER_DATA",
  REMOVE_USER_DATA = "@MODAL/REMOVE_USER_DATA",
}

export const updateModal = (
  data: Partial<Pick<IModals, "signin" | "signup">>,
) =>
  ({
    type: ModalsAction.AUTH_UPDATE,
    data,
  } as const)

export const setImageUrlForImageModal = (data: string) =>
  ({
    type: ModalsAction.SET_IMAGE_URL,
    data: {
      image: data,
    },
  } as const)

export const removeImageModal = () =>
  ({
    type: ModalsAction.REMOVE_IMAGE_URL,
    data: {
      image: null,
    },
  } as const)

export const setUserData = (user: User) =>
  ({
    type: ModalsAction.SET_USER_DATA,
    data: {
      user,
    },
  } as const)

export const removeUserData = () =>
  ({
    type: ModalsAction.REMOVE_USER_DATA,
    data: {
      user: null,
    },
  } as const)
