import store from "../config/configureStore"
import { isProduction } from "../libs/check-env"
import { getCurrentUser } from "../libs/get-current-user"
import { NotificationActionDispatcher } from "../libs/notifications"
import { Slack } from "../libs/slack"
import { JSONRPCError, JSONRPCErrorHandle } from "./jsonrpc-error"
import LogRocket from "logrocket"

type validErrorTypes = JSONRPCError | Error
type Options = {
  debugComment?: string
}

export const ThrowError = (err: validErrorTypes, options?: Options): -1 => {
  if (err instanceof JSONRPCError) {
    JSONRPCErrorHandle(err)
  } else {
    // ログロケットエラー通知
    LogRocket.captureException(err)
    NotificationActionDispatcher.ErrorNotification(err.message)
  }
  // Slackエラー通知
  const state = store.getState()
  const user = getCurrentUser(state.loginData, state.switchingUser)
  const userInfo = user ? `[${user.id}] ${user.screenName} (@${user.name}) isAnonymous: ${user.isAnonymous}` : ""
  if (isProduction()) {
    Slack(
      `${err.message}\n` +
        `${window.location.href}\n` +
        "```" +
        userInfo +
        "```\n```" +
        JSON.stringify(err, null, "  ") +
        "```",
    )
  }
  console.error(err)
  return -1
}

export const JSONRPCErrorCatch = (err: { code: string; message: string; showError?: boolean }) => {
  return ThrowError(new JSONRPCError(err))
}
