export interface IUnreadNotificationCount {
  unreadCount: number
}

export enum IUnreadNotificationCountAction {
  UPDATE_COUNT = "@UNREAD_NOTIFICATION_COUNT/UPDATE_COUNT",
}

export const InitialState = {
  unreadCount: 0,
}

export const updateCount = (count: number) =>
  ({
    type: IUnreadNotificationCountAction.UPDATE_COUNT,
    data: count,
  } as const)
