import { WindowSizeActions } from "../actions/actions"
import {
  InitialState,
  IWindowSizeState,
  WindowSizeAction,
} from "../actions/windowSizeActionCreator"

const windowSize = (
  state: IWindowSizeState = InitialState,
  action: WindowSizeActions,
) => {
  switch (action.type) {
    case WindowSizeAction.RESIZE:
      return action.data
    default:
      return state
  }
}

export default windowSize
