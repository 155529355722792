import { CreateRoomActions } from "../../../actions/actions"
import {
  CreateRoomActionTypes,
  ICreateRoomState,
  InitialState,
} from "../../../actions/pages/createRoomActionCreator"

export const createRoom = (
  state: ICreateRoomState = InitialState,
  action: CreateRoomActions,
): ICreateRoomState => {
  switch (action.type) {
    case CreateRoomActionTypes.UPDATE_CHANNELS:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}
