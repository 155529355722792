import firebase from "firebase/app"
import { configForCurrentEnv } from "./config/environment"
import { SetupGa } from "./libs/ga"
import { SetupLogrocket } from "./libs/setup-logrocket"
import { User } from "./rpcs/dist/UserRPC"
import { ChatRoom, PersonalitySession } from "./rpcs/dist/ChatRoomRPC"

SetupGa()
SetupLogrocket()
firebase.initializeApp(configForCurrentEnv.firebase)

declare module "./rpcs/dist/ChatRoomRPC" {
  interface ChatRoom {
    isOwner(user: User | null): boolean
    isPersonality(
      personalitySessions: PersonalitySession[],
      user: User | null,
    ): boolean
    isGuest(
      personalitySessions: PersonalitySession[],
      user: User | null,
    ): boolean
  }
}

ChatRoom.prototype.isOwner = function(
  this: ChatRoom,
  user: User | null,
): boolean {
  if (user && this.owner) {
    return this.owner.id === user.id
  }

  return false
}

ChatRoom.prototype.isPersonality = function(
  this: ChatRoom,
  personalitySessions: PersonalitySession[],
  user: User | null,
): boolean {
  if (user && personalitySessions) {
    return !!personalitySessions.find(p => p.user.id === user.id)
  }

  return false
}

ChatRoom.prototype.isGuest = function(
  this: ChatRoom,
  personalitySessions: PersonalitySession[],
  user: User | null,
): boolean {
  return !this.isPersonality(personalitySessions, user)
}
