import { Action, combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import { AllActions } from "../actions/actions"
import loginData from "./loginData"
import firebaseSNSLoginState from "./firebaseSNSLoginState"
import { pageReducer } from "./pages"
import windowSize from "./windowSize"
import { alert } from "./alert"
import { modals } from "./modals"
import { unlockTokens } from "./unlockTokens"
import { unreadNotificationCount } from "./unreadNotificationCount"
import rpcResultHistories from "./rpcResultHistories"
import { switchingUser } from "./switchingUser"

export const rootReducer = combineReducers({
  loginData,
  form: formReducer,
  windowSize,
  alert,
  modals,
  pages: pageReducer,
  firebaseSNSLoginState,
  unlockTokens,
  unreadNotificationCount,
  rpcResultHistories,
  switchingUser,
})

export type ReduxAction = Action<AllActions>
export type RootState = ReturnType<typeof rootReducer>
