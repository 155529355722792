import React from "react"
import { LogoSp } from "../../../parts/logo/logoSp"
import { HeaderLogoWidthForSP } from "../style/variables"
import { Login } from "./components/login"
import { Background } from "../components/background"
export const UnAuthenticated = () => (
  <Background>
    <LogoSp width={HeaderLogoWidthForSP} />
    <Login />
  </Background>
)
