import { connect } from "react-redux"
import { Dispatch } from "redux"
import { updateCount } from "../../../../../actions/unreadNotificationCount"
import { RootState, ReduxAction } from "../../../../../reducers"

export interface IContainerProps {
  unreadCount: number
  actions: ActionDispatcher
}

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public update(unreadNotificationCount: number) {
    this.dispatch(updateCount(unreadNotificationCount))
  }
}

const mapStateToProps = ({
  unreadNotificationCount,
}: RootState): Omit<IContainerProps, "actions"> => ({
  unreadCount: unreadNotificationCount.unreadCount,
})

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch),
})

export const container = connect(mapStateToProps, mapDispatchToProps)
