import React from "react"
import styled from "styled-components"
import Color from "../../../style/enum/color"

interface IProps {
  className?: string
}

export const DraftMark = (props: IProps) => (
  <StyledDiv {...props}>下書き</StyledDiv>
)

const StyledDiv = styled.div`
  background-color: ${Color.BRAND};
  color: ${Color.WHITE};
  padding: 4px 8px;
  font-size: 0.7rem;
  border-radius: 5px;
  display: inline-block;
  align-self: center;
`
