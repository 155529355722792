import React from "react"
import { connect } from "react-redux"
import { Size } from "../../../style/const/media-query"
import { RootState } from "../../../reducers"
import { ReactNode } from "react"

interface IProps {
  innerWidth: number
  children?: ReactNode
}

const PCComponent = (props: IProps) => {
  if (props.innerWidth >= Size.tablet) {
    if (props.children) {
      return <>{props.children}</>
    }
  }
  return null
}

const SPComponent = (props: IProps) => {
  if (props.innerWidth < Size.tablet) {
    return <>{props.children}</>
  } else {
    return null
  }
}

function mapStateToProps({ windowSize }: RootState) {
  return {
    innerWidth: windowSize.innerWidth,
  }
}

export const ResponsivePC = connect(mapStateToProps)(PCComponent)
export const ResponsiveSP = connect(mapStateToProps)(SPComponent)
