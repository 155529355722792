import { Store } from "redux"
import { REHYDRATE } from "redux-persist/es/constants"
import { LoginData } from "../rpcs/dist/UserRPC"

export type LoginDataState = LoginData | null
export const InitialState: LoginDataState = null

export enum LoginDataAction {
  Load = "@LOGIN/DATA_LOAD",
  Save = "@LOGIN/DATA_SAVE",
  Logout = "@LOGIN/DATA_LOGOUT",
  UpdateUserInfo = "@LOGIN/DATA_UpdateUserInfo",
  RegisterFromEmail = "@LOGIN/REGISTER_FROM_EMAIL",
  LoginFromEmail = "@LOGIN/LOGIN_FROM_EMAIL",
  RegisterFromTwitter = "@LOGIN/REGISTER_FROM_TWITTER",
  RegisterFromFacebook = "@LOGIN/REGISTER_FROM_FACEBOOK",
}

export const doLogin = (newLoginData: LoginData) =>
  ({
    type: LoginDataAction.Save,
    data: newLoginData,
  } as const)

export const doLogout = () =>
  ({
    type: LoginDataAction.Logout,
    data: null,
  } as const)

export const getLoginDataFrom = (store: Store) =>
  ({
    type: LoginDataAction.Load,
    data: store.getState().loginData,
  } as const)

export const updateUserInfo = (newUserInfo: Partial<LoginData>) =>
  ({
    type: LoginDataAction.UpdateUserInfo,
    data: newUserInfo,
  } as const)

export const registerFromEmail = (loginData: LoginData) =>
  ({
    type: LoginDataAction.RegisterFromEmail,
    data: loginData,
  } as const)

export const loginFromEmail = (loginData: LoginData) =>
  ({
    type: LoginDataAction.LoginFromEmail,
    data: loginData,
  } as const)

export const registerFromTwitter = (loginData: LoginData) =>
  ({
    type: LoginDataAction.RegisterFromTwitter,
    data: loginData,
  } as const)

export const registerFromFacebook = (loginData: LoginData) =>
  ({
    type: LoginDataAction.RegisterFromFacebook,
    data: loginData,
  } as const)

export const rehydrate = (loginData?: LoginData) =>
  ({
    type: REHYDRATE,
    payload: {
      loginData,
    },
  } as const)
