import { combineReducers } from "redux"
import { IChatRoomSyncData } from "../../../actions/pages/chat-room/actions"
import { IChatRoomLocalData } from "../../../actions/pages/chat-room/localDataActionCreator"
import { localData } from "./local-data"
import { syncData } from "./sync-data"

export interface IChatRoomState {
  syncData: IChatRoomSyncData
  localData: IChatRoomLocalData
}

export const chatRoom = combineReducers({
  syncData,
  localData,
})
