import { IValidation } from "./validation-setting"

export const ValidationGenerator = (
  input: string | undefined | null,
  validation: IValidation,
): string | null => {
  if (validation.isRequired) {
    if (!input) {
      return "必須項目です"
    }
  } else {
    if (!input) {
      return null
    }
  }

  if (validation.length && input.length > validation.length.max) {
    return `${validation.length.max}文字以内で入力して下さい`
  }

  if (validation.length && input.length < validation.length.min) {
    return `${validation.length.min}文字以上で入力して下さい`
  }

  if (validation.pattern && !validation.pattern.regexp.test(input)) {
    return validation.pattern.error
  }

  return null
}
