import React from "react"
import { isProduction } from "../../../libs/check-env"

interface IProps {
  children?: React.ReactNode
}

export const Debug = (props: IProps) => {
  if (!isProduction()) {
    return <>{props.children}</>
  }
  return null
}
