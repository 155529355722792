import { ValidationGenerator } from "../../../../../form/validation/validation-generator"
import { UserValidationSetting } from "../../../../../form/validation/validation-setting"
import { IFormField } from "./form-meta"

export const validate = (values: Partial<IFormField>) => {
  const errors: Partial<IFormField> = {}

  const UserEmailVal = ValidationGenerator(
    values.email,
    UserValidationSetting.email,
  )

  if (UserEmailVal) {
    errors.email = UserEmailVal
  }

  if (!values.password) {
    errors.password = "必須項目です"
  }

  return errors
}
