import React from "react"
import styled from "styled-components"
import Color from "../../../style/enum/color"

interface IProps {
  children: React.ReactNode
}

export const SpaceWithText = ({ children }: IProps) => (
  <Wrapper>
    <Title>{children}</Title>
  </Wrapper>
)

const Title = styled.div`
  font-weight: bold;
  color: ${Color.DIMGRAY};
  margin-bottom: 8px;
`

const Wrapper = styled.div`
  padding: 10vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
