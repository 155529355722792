import React from "react"
import { ThrowError } from "../../../../error/error"
import { NotificationActionDispatcher } from "../../../../libs/notifications"
import { ModalWrapper } from "../../../parts/modal"
import { container, IAuthContainer } from "../../auth/components/container"
import { SignUp } from "../../auth/signup"
import { IFormField } from "../../auth/signup/form/form-meta"
import { IModalContainerProps } from "../index"

type IProps = IAuthContainer & IModalContainerProps

class Component extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.showSignIn = this.showSignIn.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  public render() {
    return (
      <ModalWrapper>
        <SignUp
          onClickSignInButton={this.showSignIn}
          submit={this.submit}
          onClose={this.onClose}
          isModal={true}
        />
      </ModalWrapper>
    )
  }

  private showSignIn() {
    this.props.actions.updateModal({
      signup: false,
      signin: true,
    })
  }

  private onClose() {
    this.props.actions.updateModal({
      signup: false,
    })
  }

  private submit = (values: IFormField) => {
    this.props.sessionActions.registerFromEmail(
      {
        name: values.name,
        screenName: values.name,
        email: values.email,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      },
      (success, e) => {
        if (success) {
          NotificationActionDispatcher.LogNotification("登録が完了しました")
        } else {
          ThrowError(e)
        }
      },
    )
  }
}

export const SignUpModal = container(Component)
