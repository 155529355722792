import React from "react"
import { Wrapper } from "../../../components/templates/wrapper"
import { SUPPORT_MAIL_ADDRESS } from "../../../config/generalInformation"
import Width from "../../../style/enum/width"
import { Headline } from "../../../components/parts/headline/headline"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { StaticWrapper, StaticTitle, StaticContent } from "../components/style"

const FaqList = [
  {
    q: "ChatCastの推奨環境は何ですか？",
    a:
      "【OS】 Mac OS X 10.7以降, Windows 7, Windows 8, windows 10  <br>【ブラウザ】Google Chrome 最新版, Safari 最新版",
  },
  {
    q: "全てのチャットが自動的に投稿されてしまいますか？",
    a:
      "自動的に投稿される事はありません。<br>チャット終了後に投稿または下書き保存する事が可能です。",
  },
  {
    q: "チャットルームに制限時間はありますか？",
    a:
      "一つのチャットルームは30分で自動的に閉じます。<br>(有料オプションにて延長可能です[mail]までお問い合わせください。)",
  },
  {
    q: "１つのアカウントで複数のチャンネルを作る事はできますか？",
    a:
      "新しいチャンネルを作りたい場合は、プロフィールページのチャンネルタブよりチャンネル作成ボタンを選択していただく事で可能です。",
  },
  {
    q: "チャットルームに閲覧制限をかける事は可能ですか？",
    a:
      "チャットページのルーム設定タブにある鍵マークをクリックする事でパスロックする事が可能です。<br>またルーム作成ページにて事前にパスワードをかけることも可能です。閲覧制限後は設定したパスワードを知らない人はチャットルームに入る事ができません。",
  },
  {
    q: "一度公開した後にエピソードを編集または削除することは可能ですか？",
    a:
      "エピソードページにある編集アイコン・削除アイコンをクリックしてください。",
  },
  {
    q: "ChatCastのチャットルームの一回の時間を増やすことはできますか？",
    a:
      "有料オプションとして最大２時間まで一回のチャットルーム時間を延長する機能をご用意しております。ご興味がある方は[mail]までご連絡ください。",
  },
  {
    q: "ChatCastで作った記事を、自社のサイトやメディアに貼ることはできますか？",
    a:
      "有料オプションとして他サイトに貼ることが可能なウィジェット機能をご用意しております。ご興味がある方は[mail]までご連絡ください。",
  },
  {
    q: "記事作成自体をお願いすることも可能ですか？",
    a:
      "もちろん可能です。作成した記事は様々な形でご利用いただけるよう納品いたします。<br>[mail]までお気軽にお問い合わせください。",
  },
  {
    q: "ChatCastを使ってオンラインイベントをやりたいのですが。",
    a:
      "ChatCastを使ったユーザーとのコミュニケーションを取るためのQAセッションやトークセッション等のオンラインイベントを数多く実施してきました。ご興味ある方は一度事例含めお話しさせて頂きますので[mail]までご連絡ください。",
  },
]

export const FAQIndex = () => (
  <Wrapper maxWidth={Width.STATIC} defaultHorizontalPadding={true}>
    <Headline>
      <FontAwesomeIcon icon={faQuestionCircle} /> FAQ
    </Headline>
    {FaqList.map(faq => (
      <StaticWrapper>
        <StaticTitle dangerouslySetInnerHTML={{ __html: faq.q }} />
        <StaticContent
          dangerouslySetInnerHTML={{
            __html: faq.a.replace(
              /\[mail\]/g,
              `<a href="mailto: ${SUPPORT_MAIL_ADDRESS}">${SUPPORT_MAIL_ADDRESS}</a>`,
            ),
          }}
        />
      </StaticWrapper>
    ))}
  </Wrapper>
)
