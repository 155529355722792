import React from "react"
import styled from "styled-components"
import { Wrapper } from "../../../components/templates/wrapper"
import { SUPPORT_MAIL_ADDRESS } from "../../../config/generalInformation"
import Width from "../../../style/enum/width"
import { Headline } from "../../../components/parts/headline/headline"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie"
import { StaticWrapper } from "../components/style"

export const ContactIndex = () => {
  return (
    <Wrapper maxWidth={Width.STATIC} defaultHorizontalPadding={true}>
      <Headline>
        <FontAwesomeIcon icon={faUserTie} /> お問い合わせ
      </Headline>
      <StaticWrapper>
        <Padding>
          <p>お問い合わせは下記メールアドレスにお願いいたします</p>
          <StyledP>
            ご連絡先:{" "}
            <a href={`mailto: ${SUPPORT_MAIL_ADDRESS}`}>
              {SUPPORT_MAIL_ADDRESS}
            </a>
          </StyledP>
        </Padding>
      </StaticWrapper>
    </Wrapper>
  )
}

const StyledP = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`

const Padding = styled.div`
  padding: 16px 32px;
`
