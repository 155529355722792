import React from "react"
import { HeaderPC } from "./pc"
import { HeaderSP } from "./sp"
import { ResponsivePC, ResponsiveSP } from "../../parts/responsive"

interface IProps {
  isShow: boolean
}

export const Header = ({ isShow }: IProps) => {
  if (!isShow) {
    return null
  }

  return (
    <>
      <ResponsivePC>
        <HeaderPC />
      </ResponsivePC>
      <ResponsiveSP>
        <HeaderSP />
      </ResponsiveSP>
    </>
  )
}
