import React from "react"
import styled from "styled-components"
import { User } from "../../../../rpcs/dist/UserRPC"
import { LogoPc } from "../../../parts/logo/logoPc"
// import { SearchBox } from "./components/search-box"
import { Background } from "../components/background"
import { NoShrink } from "./components/no-shrink"
import { Notification } from "../components/notification"
import { ChatStartButton } from "../components/chat-start-button"
import { HeaderUserICon } from "../components/header-user-icon"
import { HeaderLogoSize } from "../style/variables"

interface IProps {
  currentUser: User
}

export const Authenticated = ({ currentUser }: IProps) => (
  <Background>
    <NoShrink>
      <LogoPc width={HeaderLogoSize} />
    </NoShrink>
    {/*<SearchBoxWrapper>*/}
    {/*  <SearchBox />*/}
    {/*</SearchBoxWrapper>*/}
    <Flex>
      <ChatStartButton />
      <Notification />
      <HeaderUserICon currentUser={currentUser} />
    </Flex>
  </Background>
)
// const SearchBoxWrapper = styled.div`
//   flex-basis: 400px;
//   flex-shrink: 1;
//   padding: 0 10px;
// `
const Flex = styled(NoShrink)`
  display: flex;
  align-items: center;
`
