import React from "react"
import { Button, IButtonProps } from "../../parts/buttons/button"
import Color from "../../../style/enum/color"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export interface ISendButtonProps {
  isSending: boolean
  text: string
  sendingText: string
  onClick?: any
  buttonProps?: IButtonProps
  sendingButtonProps?: IButtonProps
}

export const SendButton = (props: ISendButtonProps) => {
  if (!props.isSending) {
    return (
      <Button
        borderColor={Color.BRAND}
        backgroundColor={Color.BRAND}
        color={Color.WHITE}
        type={"submit"}
        {...props.buttonProps}
        {...props}
      >
        {props.text}
      </Button>
    )
  } else {
    return (
      <Button
        borderColor={Color.DIMGRAY}
        backgroundColor={Color.DIMGRAY}
        color={Color.WHITE}
        disabled={true}
        {...props.sendingButtonProps}
        {...props}
      >
        <FontAwesomeIcon icon={faSpinner} spin={true} /> {props.sendingText}
      </Button>
    )
  }
}
