import { EditorActions } from "../../../actions/actions"
import {
  EditorActionTypes,
  InitialState,
} from "../../../actions/pages/editor/editorActionCreator"
import { ConvertEditorEpisodeFromEpisode } from "../../../libs/converter/convert-editor-episode-from-episode"
import { EDIT_TYPE, IEditorContent } from "../../../pages/editor/types"

const getIndexFromUuid = (
  contents: IEditorContent[] | null,
  uuid: string,
): number => {
  return (contents || []).findIndex(content => content.uuid === uuid)
}

const ArrayInsert = <T>(arr: T[], index: number, insertContent: T): T[] => {
  return [...arr.slice(0, index + 1), insertContent, ...arr.slice(index + 1)]
}

const ArraySwap = <T>(arr: T[], index1: number, index2: number): T[] => {
  return [
    ...arr.slice(0, index1),
    arr[index2],
    ...arr.slice(index1 + 1, index2),
    arr[index1],
    ...arr.slice(index2 + 1),
  ]
}

export default (
  state = InitialState.currentEditingState.contents,
  action: EditorActions,
): IEditorContent[] | null => {
  switch (action.type) {
    case EditorActionTypes.FETCH_EPISODE_DATA:
      return ConvertEditorEpisodeFromEpisode(action.data)

    case EditorActionTypes.CLICK_CONTENT:
      return (state || []).map(content => {
        const uuid = action.data
        if (content._editMetaData.type === EDIT_TYPE.MENU) {
          content._editMetaData.type = EDIT_TYPE.SHOW
        }
        if (content.uuid === uuid) {
          content._editMetaData.type = EDIT_TYPE.MENU
        }
        return content
      })

    case EditorActionTypes.CLICK_RESTORE_REMOVE_CONTENT:
      return (state || []).map(content => {
        const uuid = action.data
        if (content.uuid === uuid) {
          content._editMetaData.type = EDIT_TYPE.SHOW
        }
        return content
      })

    case EditorActionTypes.CLICK_MENU_CLOSE:
      return (state || []).map(content => {
        if (content._editMetaData.type === EDIT_TYPE.MENU) {
          content._editMetaData.type = EDIT_TYPE.SHOW
        }
        return content
      })

    case EditorActionTypes.INSERT_CONTENT: {
      const index = getIndexFromUuid(state, action.data.uuidPrevInsert)
      // 追加コンテンツ以外はメニュー閉じる
      return ArrayInsert(state || [], index, action.data.content).map(c => {
        if (
          c._editMetaData.type !== EDIT_TYPE.SHOW &&
          c.uuid !== action.data.content.uuid
        ) {
          c._editMetaData.type = EDIT_TYPE.SHOW
        }
        return c
      })
    }

    case EditorActionTypes.CLICK_MENU_REMOVE_CONTENT:
      return (state || []).map(content => {
        if (action.data === content.uuid) {
          content._editMetaData.type = EDIT_TYPE.REMOVE
        }
        return content
      })

    case EditorActionTypes.CLICK_MENU_MOVE_UP_CONTENT: {
      const index = getIndexFromUuid(state, action.data)
      return ArraySwap(state || [], index - 1, index)
    }

    case EditorActionTypes.CLICK_MENU_MOVE_DOWN_CONTENT: {
      const index = getIndexFromUuid(state, action.data)
      return ArraySwap(state || [], index, index + 1)
    }

    case EditorActionTypes.UPDATE_ANONYMOUS_USER_PROFILE_IMAGE:
      return (state || []).map(content => {
        if (content.userId === action.data.id) {
          return {
            ...content,
            _editMetaData: {
              ...content._editMetaData,
            },
            user: action.data,
          }
        }
        return content
      })

    case EditorActionTypes.CLICK_CONTENT_EDITOR:
      return (state || []).map(c => {
        if (c.uuid === action.data) {
          c._editMetaData.type = EDIT_TYPE.EDIT
        }
        return c
      })

    case EditorActionTypes.SAVE_CONTENT:
      return (state || []).map(c => {
        if (c.uuid === action.data.uuid) {
          return {
            ...action.data,
            _editMetaData: {
              ...action.data._editMetaData,
              type: EDIT_TYPE.SHOW,
            },
          }
        }
        return c
      })

    case EditorActionTypes.SAVE_WITH_SHALLOW_REMOVE_CONTENT:
      return (state || []).map(c => {
        if (c.uuid === action.data) {
          c._editMetaData.type = EDIT_TYPE.REMOVE
        }
        return c
      })

    case EditorActionTypes.SAVE_WITH_REMOVE_CONTENT:
      return (state || []).filter(c => c.uuid !== action.data)

    case EditorActionTypes.CANCEL_CONTENT:
      return (state || []).map(c => {
        if (c.uuid === action.data) {
          c._editMetaData.type = EDIT_TYPE.SHOW
        }
        return c
      })

    case EditorActionTypes.CANCEL_WITH_REMOVE_CONTENT:
      return (state || []).filter(c => c.uuid !== action.data)

    case EditorActionTypes.LEAVE_THE_PAGE:
      return InitialState.currentEditingState.contents

    default:
      return state
  }
}
