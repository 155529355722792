import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import firebase from "firebase/app"
import "firebase/auth"
import { History } from "history"
import React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import styled from "styled-components"
import { SNSLoginState } from "../../../../../actions/firebaseSNSLoginStateActionCreator"
import Color from "../../../../../style/enum/color"
import { Button } from "../../../../parts/buttons/button"
import { WaitingForSnsConnect } from "./components/waiting-for-sns-connect"
import { container, IContainerProps } from "./container"

export type IProps = {
  connectType: string
  history: History
} & RouteComponentProps<{
  key: string
}> &
  IContainerProps

class Component extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.shouldDoAuthProcess = this.shouldDoAuthProcess.bind(this)
    this.handleFacebookLoginButtonClicked = this.handleFacebookLoginButtonClicked.bind(this)
    this.handleTwitterLoginButtonClicked = this.handleTwitterLoginButtonClicked.bind(this)
  }

  public shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
    const loginState = nextProps.firebaseSNSLoginState
    if (loginState === SNSLoginState.FaceBookLoginStarted || loginState === SNSLoginState.TwitterLoginStarted) {
      return false
    }
    return true
  }

  public render() {
    return (
      <>
        {this.shouldDoAuthProcess() && <WaitingForSnsConnect {...this.props} />}
        <SnsWrapper>
          <SnsDescription>
            ソーシャルアカウントで
            {this.props.connectType}
          </SnsDescription>
          <SnsButtonWrapper>
            <ButtonWrapper>
              <Twitter
                backgroundColor={Color.TWITTER}
                color={Color.WHITE}
                borderColor={Color.TRANSPARENT}
                onClick={this.handleTwitterLoginButtonClicked}
              >
                <FontAwesomeIcon icon={faTwitter} /> Twitter
              </Twitter>
            </ButtonWrapper>
            <ButtonWrapper>
              <Facebook
                backgroundColor={Color.FACEBOOK}
                color={Color.WHITE}
                borderColor={Color.TRANSPARENT}
                onClick={this.handleFacebookLoginButtonClicked}
              >
                <FontAwesomeIcon icon={faFacebook} /> Facebook
              </Facebook>
            </ButtonWrapper>
          </SnsButtonWrapper>
        </SnsWrapper>
      </>
    )
  }

  private async handleFacebookLoginButtonClicked() {
    this.props.actions.firebaseSnsLoginStateChange(SNSLoginState.FaceBookLoginStarted)
    const provider = new firebase.auth.FacebookAuthProvider()
    provider.addScope("public_profile")
    provider.addScope("email")
    const result = await firebase
      .auth()
      .signInWithRedirect(provider)
      .catch((e) => {
        console.log(90, e)
      })
    console.log(92, result)
  }

  private async handleTwitterLoginButtonClicked() {
    this.props.actions.firebaseSnsLoginStateChange(SNSLoginState.TwitterLoginStarted)
    const provider = new firebase.auth.TwitterAuthProvider()
    await firebase.auth().signInWithRedirect(provider)
  }

  private shouldDoAuthProcess(): boolean {
    switch (this.props.firebaseSNSLoginState) {
      case SNSLoginState.FaceBookLoginStarted:
      case SNSLoginState.TwitterLoginStarted:
        return true
    }
    return false
  }
}

const SnsWrapper = styled.div`
  border-top: 1px solid ${Color.LIGHT_GRAY};
  border-bottom: 1px solid ${Color.LIGHT_GRAY};
  padding: 20px 0;
  margin: 20px 0;
`

const SnsDescription = styled.div`
  text-align: center;
  font-size: 0.7rem;
`

const SnsButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0 10px;
`

const Twitter = styled(Button)`
  width: 100%;
  flex-shrink: 1;
`

const Facebook = styled(Button)`
  width: 100%;
  flex-shrink: 1;
`

const ButtonWrapper = styled.div`
  width: 48%;
  display: flex;
  flex-shrink: 1;
`

export const AuthSnsConnectLink = withRouter(container(Component))
