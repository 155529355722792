import { faEnvelope, faUnlock } from "@fortawesome/free-solid-svg-icons"
import { IStyledFieldProps } from "../../../../parts/styled-field/styled-input-field"
import { LoginInput } from "../../../../../rpcs/dist/UserRPC"

export const FormId = "SignInIndex"

export type IFormField = LoginInput

export const formData: Array<IStyledFieldProps<keyof LoginInput>> = [
  {
    name: "email",
    title: "メールアドレス",
    icon: faEnvelope,
    type: "email",
    placeholder: "メールアドレス",
  },
  {
    name: "password",
    title: "パスワード",
    icon: faUnlock,
    type: "password",
    placeholder: "パスワード",
  },
]
