import { SlackPostUrl } from "../../config/generalInformation"

export const Slack = async (text: string) => {
  await xhr(SlackPostUrl, { text })
}

const xhr = (url: string, ops: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const oReq = new XMLHttpRequest()
    oReq.addEventListener("load", resolve)
    oReq.addEventListener("error", reject)
    oReq.addEventListener("timeout", reject)
    oReq.open("POST", url)
    oReq.setRequestHeader(
      "content-type",
      "application/x-www-form-urlencoded;charset=UTF-8",
    )
    oReq.send(`payload=${JSON.stringify(ops)}`)
  })
}
