import { Content } from "../../../rpcs/dist/EpisodeRPC"
import { LoginData, User } from "../../../rpcs/dist/UserRPC"
import { ContentWithUser, IGuestMessage } from "../../../types/data-types"
import { LoginDataAction } from "../../loginDataActionCreator"
import {
  ChatRoom,
  ChatRoomJoinQueue,
  PersonalitySession,
} from "../../../rpcs/dist/ChatRoomRPC"
import { IUnlockToken } from "../../unlockTokenActionCreator"

export interface IChatRoomSyncData {
  basicInfo: ChatRoom
  timelineMessages: ContentWithUser[]
  guestMessages: IGuestMessage[]
  personalitySessions: PersonalitySession[]
  totalViews: number
  currentViews: number
  typingUsers: User[]
  joinQueue: ChatRoomJoinQueue[]
  hasPendingJoinRequest: boolean
  hasValidPrivilegeToAccess: boolean
}

export enum ChatRoomAction {
  Reset = "@CHAT_ROOM/Reset",
  RoomInfoUpdated = "@CHAT_ROOM/RoomInfoUpdated",
  SyncMainAddedMessagesUntilUnsubscribe = "@CHAT_ROOM/SyncMainAddedMessagesUntilUnsubscribe",
  SyncMainRemovedMessagesUntilUnsubscribe = "@CHAT_ROOM/SyncMainRemovedMessagesUntilUnsubscribe",
  SyncMainModifiedMessagesUntilUnsubscribe = "@CHAT_ROOM/SyncMainModifiedMessagesUntilUnsubscribe",
  SyncGuestMessagesUntilUnsubscribe = "@CHAT_ROOM/SyncGuestMessagesUntilUnsubscribe",
  SendMainMessage = "@CHAT_ROOM/SendMainMessage",
  SendGuestMessage = "@CHAT_ROOM/SendGuestMessage",
  SyncUserInformationWithFireStore = "@CHAT_ROOM/SyncUserInformationWithFireStore",
  DidPersonalitySessionsUpdated = "@CHAT_ROOM/DidPersonalitySessionsUpdated",
  DidNumOfCurrentViewsUpdated = "@CHAT_ROOM/DidNumOfCurrentViewsUpdated",
  DidNumOfTotalViewsUpdated = "@CHAT_ROOM/DidNumOfTotalViewsUpdated",
  DidSomeUsersTyped = "@CHAT_ROOM/DidSomeUsersTyped",
  DidNewJoinQueueUpdated = "@CHAT_ROOM/DidNewJoinQueueUpdated",
  DidJoinQueuePopped = "@CHAT_ROOM/DidJoinQueuePopped",
  DidPendingJoinRequestStatusUpdated = "@CHAT_ROOM/DidPendingJoinRequestStatusUpdated",
  DidPersonalitySessionAdded = "@CHAT_ROOM/DidPersonalitySessionAdded",
  DidPersonalitySessionRemoved = "@CHAT_ROOM/DidPersonalitySessionRemoved",
  DidNumberOfLikesUpdated = "@CHAT_ROOM/DidNumberOfLikesUpdated",
  DidRemaningTimeUpdated = "@CHAT_ROOM/DidRemaningTimeUpdated",
  ShowUpUnlockPage = "@CHAT_ROOM/ShowUpUnlockPage",
  AllowToAccess = "@CHAT_ROOM/AllowToAccess",
  DenyToAccess = "@CHAT_ROOM/DenyToAccess",
  DidUnlockedRoom = "@CHAT_ROOM/DidUnlockedRoom",
  BasicInfoFromPoller = "@CHAT_ROOM/BasicInfoFromPoller",
  SyncUsersInformationUntilUnsubscribe = "@CHAT_ROOM/SyncUsersInformationUntilUnsubscribe",
}

export const reset = () =>
  ({
    type: ChatRoomAction.Reset,
    data: {},
  } as const)

export const roomInfoUpdated = (chatRoom: ChatRoom) =>
  ({
    type: ChatRoomAction.RoomInfoUpdated,
    data: chatRoom,
  } as const)

export const didPersonalitySessionsUpdated = (sessions: PersonalitySession[]) =>
  ({
    type: ChatRoomAction.DidPersonalitySessionsUpdated,
    data: sessions,
  } as const)

export const allowToAccess = () =>
  ({
    type: ChatRoomAction.AllowToAccess,
  } as const)

export const showUpUnlockPage = (room: ChatRoom) =>
  ({
    type: ChatRoomAction.ShowUpUnlockPage,
    data: room,
  } as const)

export const syncMainAddedMessagesUntilUnsubscribe = (
  messages: ContentWithUser[],
) =>
  ({
    type: ChatRoomAction.SyncMainAddedMessagesUntilUnsubscribe,
    data: messages,
  } as const)

export const syncMainRemovedMessagesUntilUnsubscribe = (
  removedDocumentData: Content[],
) =>
  ({
    type: ChatRoomAction.SyncMainRemovedMessagesUntilUnsubscribe,
    data: removedDocumentData,
  } as const)

export const syncMainModifiedMessagesUntilUnsubscribe = (
  modifiedDocumentData: Content[],
) =>
  ({
    type: ChatRoomAction.SyncMainModifiedMessagesUntilUnsubscribe,
    data: modifiedDocumentData,
  } as const)

export const syncGuestMessagesUntilUnsubscribe = (messages: IGuestMessage[]) =>
  ({
    type: ChatRoomAction.SyncGuestMessagesUntilUnsubscribe,
    data: messages,
  } as const)

export const didNumOfTotalViewsUpdated = (value: number) =>
  ({
    type: ChatRoomAction.DidNumOfTotalViewsUpdated,
    data: value,
  } as const)

export const sendMainMessage = (message: ContentWithUser) =>
  ({
    type: ChatRoomAction.SendMainMessage,
    data: message,
  } as const)

export const sendGuestMessage = (message: IGuestMessage) =>
  ({
    type: ChatRoomAction.SendGuestMessage,
    data: message,
  } as const)

export const updateUserInfo = (plainRegisteredUserInfo: User) =>
  ({
    type: LoginDataAction.UpdateUserInfo,
    data: {
      user: plainRegisteredUserInfo,
    },
  } as const)

export const syncUsersInformationUntilUnsubscribe = (data: User) =>
  ({
    type: ChatRoomAction.SyncUsersInformationUntilUnsubscribe,
    data,
  } as const)

export const chatRoomLoginDataActionSave = (anonymous: LoginData) =>
  ({
    type: LoginDataAction.Save,
    data: anonymous,
  } as const)

export const didSomeUsersTyped = (currentTypingUsers: User[]) =>
  ({
    type: ChatRoomAction.DidSomeUsersTyped,
    data: currentTypingUsers,
  } as const)

export const didPendingJoinRequestStatusUpdated = (req: boolean) =>
  ({
    type: ChatRoomAction.DidPendingJoinRequestStatusUpdated,
    data: req,
  } as const)

export const didPersonalitySessionAdded = (sessions: PersonalitySession[]) =>
  ({
    type: ChatRoomAction.DidPersonalitySessionAdded,
    data: sessions,
  } as const)

export const didJoinQueuePopped = (user: User) =>
  ({
    type: ChatRoomAction.DidJoinQueuePopped,
    data: user,
  } as const)

export const didPersonalitySessionRemoved = (user: User) =>
  ({
    type: ChatRoomAction.DidPersonalitySessionRemoved,
    data: user,
  } as const)

export const didUnlockedRoom = (unlockTokens: IUnlockToken) =>
  ({
    type: ChatRoomAction.DidUnlockedRoom,
    data: unlockTokens,
  } as const)

export const basicInfoFromPoller = (item: Partial<ChatRoom>) =>
  ({
    type: ChatRoomAction.BasicInfoFromPoller,
    data: item,
  } as const)

export const didNewJoinQueueUpdated = (queues: ChatRoomJoinQueue[]) =>
  ({
    type: ChatRoomAction.DidNewJoinQueueUpdated,
    data: queues,
  } as const)

export const didNumOfCurrentViewsUpdated = (num: number) =>
  ({
    type: ChatRoomAction.DidNumOfCurrentViewsUpdated,
    data: num,
  } as const)

export const didRemaningTimeUpdated = (item: number) =>
  ({
    type: ChatRoomAction.DidRemaningTimeUpdated,
    data: {
      remainingTime: item,
    },
  } as const)
