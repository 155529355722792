import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { UserIcon } from "../components/parts/user-icon"
import { RootState } from "../reducers"
import Color from "../style/enum/color"

const mapStateToProps = ({ switchingUser }: RootState) => ({
  switchingUser,
})

const Component = React.memo((props: Pick<RootState, "switchingUser">) => {
  if (props.switchingUser.token && props.switchingUser.debug) {
    const user = props.switchingUser.debug
    return (
      <Notice>
        <StyledUserIcon
          title={"ユーザーアイコン"}
          size={20}
          url={user.profileImage.square}
        />
        {user.screenName} (@{user.name}) さんとして操作しています
      </Notice>
    )
  }
  return null
})

export const SwitchingUserNotice = connect(mapStateToProps)(Component)

const Notice = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: ${Color.RED};
  color: ${Color.WHITE};
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledUserIcon = styled(UserIcon)`
  margin-right: 4px;
`
