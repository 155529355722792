import { configForCurrentEnv } from "../../config/environment"
import { Channel } from "../../rpcs/dist/ChannelRPC"
import { ChatRoom } from "../../rpcs/dist/ChatRoomRPC"
import { Episode } from "../../rpcs/dist/EpisodeRPC"
import { User } from "../../rpcs/dist/UserRPC"
import { ContextAccount } from "../context-account"

export const EpisodeUrl = (
  episode: Pick<Episode, "displayId" | "user" | "channel">,
  withDomain: boolean = false,
): string => {
  const domain = withDomain ? configForCurrentEnv.endpoints.app : ""

  if (episode.channel) {
    return `${domain}/channel/${episode.channel.name}/episode/${episode.displayId}`
  }

  if (episode.user) {
    return `${domain}/user/${episode.user.name}/episode/${episode.displayId}`
  }

  return ""
}

export const ChatRoomUrl = (chatRoom: ChatRoom): string => {
  const contextAccount = new ContextAccount(chatRoom.owner, chatRoom.channel)
  return `${contextAccount.getAccountUrl()}/live/${chatRoom.displayId}`
}

export const OffairUrl = (
  displayId: string,
  user?: User,
  channel?: Channel,
): string => {
  const baseUrl = `/live/${displayId}/offair`
  if (channel) {
    return `${baseUrl}?channel=${channel.name}`
  }
  if (user) {
    return `${baseUrl}?user=${user.name}`
  }
  return baseUrl
}
