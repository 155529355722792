import React, { PropsWithChildren, useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { GaPageView } from "../libs/ga"
import { UpdateTitle } from "../libs/update-title"

export const Component = (
  props: RouteComponentProps & PropsWithChildren<{}>,
) => {
  const [prevLocation, setLocation] = useState<
    RouteComponentProps["location"] | null
  >(null)

  // ページ遷移時にスクロール状態をリセット
  useEffect(() => {
    if (props.location !== prevLocation) {
      window.scrollTo(0, 0)
    }
    setLocation(props.location)
  }, [props.location, prevLocation, setLocation])

  useEffect(() => {
    GaPageView(props)
  }, [props])

  useEffect(() => {
    UpdateTitle()
  })

  return <>{props.children}</>
}

export const PageTransition = withRouter(Component)
