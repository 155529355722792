import "./initializer"
import React from "react"
import ReactDOM from "react-dom"
import "./style/css/bootstrap-reboot.css"
import "./style/css/index.css"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import store, { persistor } from "./config/configureStore"
import throttle from "lodash-es/throttle"
import { resizeWindow } from "./actions/windowSizeActionCreator"
import { RouteIndex } from "./routes"

window.addEventListener(
  "resize",
  throttle(
    () => store.dispatch(resizeWindow(window.innerWidth, window.innerHeight)),
    250,
  ),
)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouteIndex />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement,
)
