import { User } from "../rpcs/dist/UserRPC"

export const InitialState: ISwitchingUser = {
  token: null,
  debug: null,
}

export interface ISwitchingUser {
  token: string | null
  debug: User | null
}

export enum SwitchingUserAction {
  BEGIN_SWITCHING_USER = "@SwitchingUserAction/BEGIN_SWITCHING_USER",
  END_SWITCHING_USER = "@SwitchingUserAction/END_SWITCHING_USER",
  UPDATE_SWITCHING_USER_INFO = "@SwitchingUserAction/UPDATE_SWITCHING_USER_INFO",
}

export const beginSwitchingUser = (switchingUser: ISwitchingUser) =>
  ({
    type: SwitchingUserAction.BEGIN_SWITCHING_USER,
    data: switchingUser,
  } as const)

export const endSwitchingUser = () =>
  ({
    type: SwitchingUserAction.END_SWITCHING_USER,
    data: {
      token: null,
      debug: null,
    },
  } as const)

export const updateSwitchingUserInfo = (user: User) =>
  ({
    type: SwitchingUserAction.UPDATE_SWITCHING_USER_INFO,
    data: user,
  } as const)
