import { IEditorContent } from "../../../pages/editor/types"
import { Channel } from "../../../rpcs/dist/ChannelRPC"
import { Episode } from "../../../rpcs/dist/EpisodeRPC"
import { User } from "../../../rpcs/dist/UserRPC"

export interface IEditorState {
  fetchData: {
    episode: Episode | null
    channels: Channel[] | null
    anonymousUsers: User[] | null
  }
  currentEditingState: {
    title: string | null
    contents: IEditorContent[] | null
    tags: string[] | null
    selectedChannelId: Channel["id"] | null
    isSending: boolean
    surveyModal: IEditorContent["uuid"] | null
  }
}

export const InitialState: IEditorState = {
  fetchData: {
    episode: null,
    channels: null,
    anonymousUsers: null,
  },
  currentEditingState: {
    title: null,
    contents: null,
    tags: null,
    selectedChannelId: null,
    isSending: false,
    surveyModal: null,
  },
}

export enum EditorActionTypes {
  FETCH_EPISODE_DATA = "@EDITOR/FETCH_EPISODE_DATA",
  FETCH_CHANNEL_DATA = "@EDITOR/FETCH_CHANNEL_DATA",
  SELECT_POST_ACCOUNT = "@EDITOR/SELECT_POST_ACCOUNT",
  CLICK_CONTENT = "@EDITOR/CLICK_CONTENT",
  CLICK_MENU_MOVE_UP_CONTENT = "@EDITOR/CLICK_MENU_MOVE_UP_CONTENT",
  CLICK_MENU_MOVE_DOWN_CONTENT = "@EDITOR/CLICK_MENU_MOVE_DOWN_CONTENT",
  CLICK_MENU_REMOVE_CONTENT = "@EDITOR/CLICK_MENU_REMOVE_CONTENT",
  CLICK_MENU_SURVEY = "@EDITOR/CLICK_MENU_SURVEY",
  CLICK_MENU_CLOSE = "@EDITOR/CLICK_MENU_CLOSE",
  CLICK_RESTORE_REMOVE_CONTENT = "@EDITOR/CLICK_RESTORE_REMOVE_CONTENT",
  CLICK_CONTENT_EDITOR = "@EDITOR/CLICK_CONTENT_EDITOR",
  SAVE_CONTENT = "@EDITOR/SAVE_CONTENT",
  SAVE_WITH_SHALLOW_REMOVE_CONTENT = "@EDITOR/SAVE_WITH_SHALLOW_REMOVE_CONTENT",
  SAVE_WITH_REMOVE_CONTENT = "@EDITOR/SAVE_WITH_REMOVE_CONTENT",
  CANCEL_CONTENT = "@EDITOR/CANCEL_CONTENT",
  CANCEL_WITH_REMOVE_CONTENT = "@EDITOR/CANCEL_WITH_REMOVE_CONTENT",
  UPDATE_ANONYMOUS_USER_PROFILE_IMAGE = "@EDITOR/UPDATE_ANONYMOUS_USER_PROFILE_IMAGE",

  INSERT_CONTENT = "@EDITOR/INSERT_CONTENT",
  UPDATE_TITLE = "@EDITOR/UPDATE_TITLE",
  ADD_TAG = "@EDITOR/ADD_TAG",
  REMOVE_TAG = "@EDITOR/REMOVE_TAG",
  CLOSE_SURVEY_MODAL = "@EDITOR/CLOSE_SURVEY_MODAL",
  LEAVE_THE_PAGE = "@EDITOR/LEAVE_THE_PAGE",
  SENDING = "@EDITOR/SENDING",
  SENDING_FAILED = "@EDITOR/SENDING_FAILED",
}

export const updateTitle = (title: string) =>
  ({
    type: EditorActionTypes.UPDATE_TITLE,
    data: {
      title,
    },
  } as const)

export const addTag = (tag: string) =>
  ({
    type: EditorActionTypes.ADD_TAG,
    data: {
      tag,
    },
  } as const)

export const removeTag = (tag: string) =>
  ({
    type: EditorActionTypes.REMOVE_TAG,
    data: {
      tag,
    },
  } as const)

export const updateAnonymousUserProfileImage = (user: User) =>
  ({
    type: EditorActionTypes.UPDATE_ANONYMOUS_USER_PROFILE_IMAGE,
    data: user,
  } as const)

export const fetchEpisodeData = (episode: Episode) =>
  ({
    type: EditorActionTypes.FETCH_EPISODE_DATA,
    data: episode,
  } as const)

export const fetchChannelData = (channels: Channel[]) =>
  ({
    type: EditorActionTypes.FETCH_CHANNEL_DATA,
    data: channels,
  } as const)

export const selectPostAccount = (postAccount: Channel["id"] | null) =>
  ({
    type: EditorActionTypes.SELECT_POST_ACCOUNT,
    data: postAccount,
  } as const)

export const clickContent = (uuid: string) =>
  ({
    type: EditorActionTypes.CLICK_CONTENT,
    data: uuid,
  } as const)

export const insertContent = (
  content: IEditorContent,
  uuidPrevInsert: string,
) =>
  ({
    type: EditorActionTypes.INSERT_CONTENT,
    data: {
      content,
      uuidPrevInsert,
    },
  } as const)

export const clickMenuRemoveContent = (uuid: string) =>
  ({
    type: EditorActionTypes.CLICK_MENU_REMOVE_CONTENT,
    data: uuid,
  } as const)

export const clickMenuMoveUpContent = (uuid: string) =>
  ({
    type: EditorActionTypes.CLICK_MENU_MOVE_UP_CONTENT,
    data: uuid,
  } as const)

export const clickMenuMoveDownContent = (uuid: string) =>
  ({
    type: EditorActionTypes.CLICK_MENU_MOVE_DOWN_CONTENT,
    data: uuid,
  } as const)

export const clickMenuSurvey = (uuid: string) =>
  ({
    type: EditorActionTypes.CLICK_MENU_SURVEY,
    data: uuid,
  } as const)

export const clickMenuClose = () =>
  ({
    type: EditorActionTypes.CLICK_MENU_CLOSE,
  } as const)

export const clickRestoreRemoveContent = (uuid: string) =>
  ({
    type: EditorActionTypes.CLICK_RESTORE_REMOVE_CONTENT,
    data: uuid,
  } as const)

export const clickContentEditor = (uuid: string) =>
  ({
    type: EditorActionTypes.CLICK_CONTENT_EDITOR,
    data: uuid,
  } as const)

export const saveContent = (content: IEditorContent) =>
  ({
    type: EditorActionTypes.SAVE_CONTENT,
    data: content,
  } as const)

export const saveWithShallowRemoveContent = (uuid: string) =>
  ({
    type: EditorActionTypes.SAVE_WITH_SHALLOW_REMOVE_CONTENT,
    data: uuid,
  } as const)

export const saveWithRemoveContent = (uuid: string) =>
  ({
    type: EditorActionTypes.SAVE_WITH_REMOVE_CONTENT,
    data: uuid,
  } as const)

export const cancelContent = (uuid: string) =>
  ({
    type: EditorActionTypes.CANCEL_CONTENT,
    data: uuid,
  } as const)

export const cancelWithRemoveContent = (uuid: string) =>
  ({
    type: EditorActionTypes.CANCEL_WITH_REMOVE_CONTENT,
    data: uuid,
  } as const)

export const closeSurveyModal = () =>
  ({
    type: EditorActionTypes.CLOSE_SURVEY_MODAL,
  } as const)

export const leaveThePage = () =>
  ({
    type: EditorActionTypes.LEAVE_THE_PAGE,
  } as const)

export const sending = () =>
  ({
    type: EditorActionTypes.SENDING,
  } as const)

export const sendingFailed = () =>
  ({
    type: EditorActionTypes.SENDING_FAILED,
  } as const)
