import { OffairActions } from "../../../actions/actions"
import {
  InitialState,
  IOffair,
  OffairActionTypes,
} from "../../../actions/pages/offairActionCreator"

export const offair = (
  state: IOffair = InitialState,
  action: OffairActions,
): IOffair => {
  switch (action.type) {
    case OffairActionTypes.FETCH_CLOSE_ROOM:
    case OffairActionTypes.FETCH_ROOM_CONTRIBUTOR:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}
