import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Button } from "../../../../../components/parts/buttons/button"
import { Loading } from "../../../../../components/parts/loading"
import { SpaceWithText } from "../../../../../components/parts/space-with-text"
import { Episode } from "../../../../../components/templates/episode"
import { Wrapper } from "../../../../../components/templates/wrapper"
import { JSONRPCErrorCatch } from "../../../../../error/error"
import { JSONRPCClientBuilder } from "../../../../../request/JSONRPCClientBuilder"
import { EpisodeRPC } from "../../../../../rpcs/dist/EpisodeRPC"
import Width from "../../../../../style/enum/width"
import { IContainerProps } from "../../../container"

export class NewEpisode extends React.Component<IContainerProps> {
  constructor(props: IContainerProps) {
    super(props)
    this.getNewEpisodeIfneeded = this.getNewEpisodeIfneeded.bind(this)
  }

  public componentDidMount() {
    this.getNewEpisodeIfneeded()
  }

  public render() {
    const { recentEpisodes } = this.props.top

    // 読み込み中
    if (!recentEpisodes) {
      return <Loading verticalPadding={"15vh"} />
    }

    // エピソードがない
    if (recentEpisodes.length === 0) {
      return (
        <SpaceWithText>
          <p>エピソードがありません</p>
        </SpaceWithText>
      )
    }
    return (
      <Wrapper maxWidth={Width.EPISODE_LIST}>
        {recentEpisodes.map(e => (
          <Episode key={e.id} episode={e} />
        ))}
        <ReadMore>
          <Link to={"/recent_episodes"}>
            <Button>もっと見る</Button>
          </Link>
        </ReadMore>
      </Wrapper>
    )
  }

  private async getNewEpisodeIfneeded() {
    if (!this.props.top.recentEpisodes) {
      const episodeRPC = JSONRPCClientBuilder.build(EpisodeRPC)
      const res = await episodeRPC.newEpisodes().catch(JSONRPCErrorCatch)
      if (res !== -1) {
        this.props.actions.updateRecentEpisodes(res)
      }
    }
  }
}

const ReadMore = styled.div`
  text-align: center;
  padding: 64px;
`
