import { TopActions } from "../../../actions/actions"
import {
  InitialState,
  ITopState,
  TopActionTypes,
} from "../../../actions/pages/topActionCreator"

export const top = (
  state: ITopState = InitialState,
  action: TopActions,
): ITopState => {
  switch (action.type) {
    case TopActionTypes.UPDATE_RECENT_EPISODES:
    case TopActionTypes.UPDATE_RECOMMEND_EPISODES:
    case TopActionTypes.UPDATE_MYFEED_EPISODES:
    case TopActionTypes.UPDATE_LIVE:
      return { ...state, ...action.data }
    case TopActionTypes.RESET:
      return InitialState
    default:
      return state
  }
}
