import { UserActions } from "../../../actions/actions"
import {
  InitialState,
  IUserState,
  UserActionTypes,
} from "../../../actions/pages/userActionCreator"

const user = (
  state: IUserState = InitialState,
  action: UserActions,
): IUserState => {
  switch (action.type) {
    case UserActionTypes.UPDATE_USER_PROFILE:
    case UserActionTypes.UPDATE_MY_CHANNELS:
    case UserActionTypes.UPDATE_FOLLOW_CHANNELS:
      return { ...state, ...action.data }

    case UserActionTypes.UPDATE_USER_FOLLOW_INFO:
      return {
        ...state,
        profile: {
          ...state.profile!,
          ...action.data,
        },
      }

    case UserActionTypes.ADD_EPISODES:
      return {
        ...state,
        episodes: (state.episodes || []).concat(action.data.episodes || []),
      }

    case UserActionTypes.ADD_MYFEEDS:
      return {
        ...state,
        myFeeds: (state.myFeeds || []).concat(action.data.myFeeds || []),
      }

    case UserActionTypes.ADD_DRAFTS:
      return {
        ...state,
        drafts: (state.drafts || []).concat(action.data.drafts || []),
      }

    case UserActionTypes.RESET_EPISODES:
    case UserActionTypes.RESET_DRAFTS:
      return {
        ...state,
        ...action.data,
      }

    case UserActionTypes.RESET:
      return InitialState

    default:
      return state
  }
}

export default user
