import { Channel } from "../../rpcs/dist/ChannelRPC"
import { ChatRoom } from "../../rpcs/dist/ChatRoomRPC"
import { Episode } from "../../rpcs/dist/EpisodeRPC"
import { User } from "../../rpcs/dist/UserRPC"

export const InitialState: IOffair = {
  episode: null,
  chatRoom: null,
  channel: null,
  user: null,
}

export interface IOffair {
  episode: Episode | null
  chatRoom: ChatRoom | null
  channel: Channel | null
  user: User | null
}

export enum OffairActionTypes {
  FETCH_CLOSE_ROOM = "@OFFAIR/FETCH_CLOSE_ROOM",
  FETCH_ROOM_CONTRIBUTOR = "@OFFAIR/FETCH_ROOM_CONTRIBUTOR",
  RESET = "@OFFAIR/RESET",
}

export const fetchCloseRoom = (data: Pick<IOffair, "episode" | "chatRoom">) =>
  ({
    type: OffairActionTypes.FETCH_CLOSE_ROOM,
    data,
  } as const)

export const fetchRoomContributor = (
  user: User | null,
  channel: Channel | null,
) =>
  ({
    type: OffairActionTypes.FETCH_ROOM_CONTRIBUTOR,
    data: {
      user,
      channel,
    },
  } as const)
