import { Dispatch } from "redux"
import { connect } from "react-redux"
import { removeImageModal } from "../../../../actions/modalsActionCreator"
import { RootState, ReduxAction } from "../../../../reducers"

export interface IContainerProps {
  image: string | null
  actions: ActionDispatcher
}

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public removeImageModal() {
    this.dispatch(removeImageModal())
  }
}

const mapStateToProps = ({ modals }: RootState) => ({
  image: modals.image,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch),
})

export const container = connect(mapStateToProps, mapDispatchToProps)
