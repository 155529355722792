export interface IWindowSizeState {
  innerWidth: number
  innerHeight: number
}

export const InitialState: IWindowSizeState = {
  innerWidth: window.innerWidth || 0,
  innerHeight: window.innerHeight || 0,
}

export enum WindowSizeAction {
  RESIZE = "@WINDOW/RESIZE",
}

export const resizeWindow = (innerWidth: number, innerHeight: number) =>
  ({
    type: WindowSizeAction.RESIZE,
    data: {
      innerWidth,
      innerHeight,
    },
  } as const)
