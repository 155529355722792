import { faHeadset } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"
import { ChatRoom } from "../../../../../../../rpcs/dist/ChatRoomRPC"
import Color from "../../../../../../../style/enum/color"
import { LiveItemWrapper } from "../../live"
import { LiveItem } from "../live-item"

interface IProps {
  chatRooms: ChatRoom[]
}

export const Live = (props: IProps) => {
  if (props.chatRooms.length === 0) {
    return null
  }

  const waitingForStart = props.chatRooms.filter(r => r.startedAt === null)
  const started = props.chatRooms.filter(r => r.startedAt !== null)

  return (
    <div>
      <Title>
        <FontAwesomeIcon icon={faHeadset} /> Live中
      </Title>
      <LiveItemWrapper>
        {started.map(room => (
          <LiveItem key={room.id} chatRoom={room} />
        ))}
        {waitingForStart.map(room => (
          <LiveItem key={room.id} chatRoom={room} />
        ))}
      </LiveItemWrapper>
    </div>
  )
}

const Title = styled.h3`
  font-size: 1rem;
  color: ${Color.DIMGRAY};
`
