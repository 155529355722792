import React from "react"
import { bindActionCreators, Dispatch } from "redux"
import styled from "styled-components"
import { connect } from "react-redux"
import {
  IAlert,
  AlertPopActionCreator,
} from "../../../actions/alertActionCreator"
import { HeaderHeight } from "../header/style/variables"
import { Item } from "./item"
import { RootState } from "../../../reducers"

interface IProps {
  alert: IAlert[]
  remove: (uid: number) => void
}

const Component = ({ alert, remove }: IProps) => {
  if (alert.length === 0) {
    return null
  }

  return (
    <Wrapper>
      {alert.map(item => (
        <Item key={item.uid} remove={remove} {...item} />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: ${HeaderHeight}px;
  right: 0;
  z-index: 2;
`

const mapStateToProps = ({ alert }: RootState) => ({
  alert,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: bindActionCreators(AlertPopActionCreator, dispatch),
})

export const Alert = connect(mapStateToProps, mapDispatchToProps)(Component)
