import { Field, reduxForm, InjectedFormProps } from "redux-form"
import { IStyledFieldProps } from "../../../../parts/styled-field/styled-input-field"
import { ICheckboxProps } from "../../components/auth-agreement-check"
import { formData, FormId, IFormField } from "./form-meta"
import { validate } from "./validation"
import React from "react"
import { AuthSendButton } from "../../components/auth-send-button"

export class FormComponent extends React.Component<
  InjectedFormProps<{}, IFormField>
> {
  public render() {
    const { handleSubmit, submitting } = this.props
    return (
      <form onSubmit={handleSubmit}>
        {formData.map((data, i) => (
          <Field<IStyledFieldProps | ICheckboxProps> key={i} {...data} />
        ))}
        <AuthSendButton
          isSending={submitting}
          text={"登録する"}
          sendingText={"登録中です"}
          onClick={handleSubmit}
        />
      </form>
    )
  }
}

export const Form = reduxForm<{}, Partial<IFormField>>({
  form: FormId,
  validate,
})(FormComponent)
