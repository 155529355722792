import {
  RegisterFromEmailInput,
  UserRPC,
  LoginInput,
  RegisterFromSNSInput,
} from "../rpcs/dist/UserRPC"
import { JSONRPCClientBuilder } from "../request/JSONRPCClientBuilder"
import { Dispatch } from "redux"
import {
  loginFromEmail,
  registerFromEmail,
  registerFromFacebook,
  registerFromTwitter,
} from "./loginDataActionCreator"

export interface ISessionActionCreator {
  [key: string]: any

  registerFromEmail(
    input: RegisterFromEmailInput,
    didFinish?: (success: boolean, error?: any) => void,
  ): void

  loginFromEmail(
    input: LoginInput,
    didFinish?: (success: boolean, error?: any) => void,
  ): void

  registerFromTwitter(
    input: RegisterFromSNSInput,
    didFinish?: (success: boolean, error?: any) => void,
  ): void

  registerFromFacebook(
    input: RegisterFromSNSInput,
    didFinish?: (success: boolean, error?: any) => void,
  ): void
}

const actionCreators: ISessionActionCreator = {
  registerFromEmail(
    input: RegisterFromEmailInput,
    didFinish?: (success: boolean, error?: any) => void,
  ) {
    const rpc = JSONRPCClientBuilder.build(UserRPC)
    return (dispatch: Dispatch) => {
      rpc
        .registerFromEmail(input)
        .then(loginData => {
          dispatch(registerFromEmail(loginData))
          if (didFinish) {
            didFinish(true)
          }
        })
        .catch(error => {
          if (didFinish) {
            didFinish(false, error)
          }
        })
    }
  },

  loginFromEmail(
    input: LoginInput,
    didFinish?: (success: boolean, error?: any) => void,
  ) {
    const rpc = JSONRPCClientBuilder.build(UserRPC)
    return (dispatch: Dispatch) => {
      rpc
        .loginFromEmail(input)
        .then(loginData => {
          dispatch(loginFromEmail(loginData))
          if (didFinish) {
            didFinish(true)
          }
        })
        .catch(error => {
          if (didFinish) {
            didFinish(false, error)
          }
        })
    }
  },

  registerFromTwitter(
    input: RegisterFromSNSInput,
    didFinish?: (success: boolean, error?: any) => void,
  ) {
    const rpc = JSONRPCClientBuilder.build(UserRPC)
    return (dispatch: Dispatch) => {
      rpc
        .registerFromTwitter(input)
        .then(loginData => {
          dispatch(registerFromTwitter(loginData))
          if (didFinish) {
            didFinish(true)
          }
        })
        .catch(error => {
          if (didFinish) {
            didFinish(false, error)
          }
        })
    }
  },

  registerFromFacebook(
    input: RegisterFromSNSInput,
    didFinish?: (success: boolean, error?: any) => void,
  ) {
    const rpc = JSONRPCClientBuilder.build(UserRPC)
    return (dispatch: Dispatch) => {
      rpc
        .registerFromFacebook(input)
        .then(loginData => {
          dispatch(registerFromFacebook(loginData))
          if (didFinish) {
            didFinish(true)
          }
        })
        .catch(error => {
          if (didFinish) {
            didFinish(false, error)
          }
        })
    }
  },
}

export default actionCreators
