import React from "react"
import { Wrapper } from "../../../components/templates/wrapper"
import { SUPPORT_MAIL_ADDRESS } from "../../../config/generalInformation"
import Width from "../../../style/enum/width"
import { Headline } from "../../../components/parts/headline/headline"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticWrapper, StaticTitle, StaticContent } from "../components/style"
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding"

const Items = [
  {
    title: "会社名",
    content: "株式会社 iDEAKITT",
  },
  {
    title: "連絡先",
    content: SUPPORT_MAIL_ADDRESS,
  },
  {
    title: "業務内容",
    content:
      "Webサービス/スマートフォンアプリの企画、設計、開発、運営 UI/UXを強みとしたコンサルティング業務 各種イベント・セミナーの企画・制作・運営",
  },
  {
    title: "代表取締役",
    content: "藤田 遼平",
  },
  {
    title: "取締役",
    content: "日向徳旭",
  },
]

export const CompanyIndex = () => (
  <Wrapper maxWidth={Width.STATIC} defaultHorizontalPadding={true}>
    <Headline>
      <FontAwesomeIcon icon={faBuilding} /> 運営会社
    </Headline>
    {Items.map(item => (
      <StaticWrapper>
        <StaticTitle dangerouslySetInnerHTML={{ __html: item.title }} />
        <StaticContent dangerouslySetInnerHTML={{ __html: item.content }} />
      </StaticWrapper>
    ))}
  </Wrapper>
)
