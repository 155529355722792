import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Modals } from "../components/templates/modals"
import { ErrorBoundary } from "./error-boundary"
import routes, { IDefaultRoute } from "./routes"
import { Header } from "../components/templates/header"
import { Footer } from "../components/templates/footer"
import { Alert } from "../components/templates/alert"
import { AsyncLoader } from "./async-loader"
import { PageTransition } from "./page-transition"
import { SwitchingUserNotice } from "./switching-user-notice"

const RouteHeader = (route: IDefaultRoute) => {
  const render = () =>
    React.createElement(Header, { isShow: route.isShowHeader })
  return (
    <Route
      key={"Header"}
      path={route.path}
      exact={route.exact}
      render={render}
    />
  )
}

const RouteFooter = (route: IDefaultRoute) => {
  const render = () =>
    React.createElement(Footer, { isShow: route.isShowFooter })
  return (
    <Route
      key={"Footer"}
      path={route.path}
      exact={route.exact}
      render={render}
    />
  )
}

const RouteMain = (route: IDefaultRoute, index: number) => (
  <Route
    key={index}
    path={route.path}
    exact={route.exact}
    component={AsyncLoader(route)}
  />
)

export const RouteIndex = () => {
  return (
    <ErrorBoundary>
      <Router>
        <Alert />
        <Modals />
        <PageTransition>
          <Switch>{routes.map(RouteHeader)}</Switch>
          <Switch>{routes.map(RouteMain)}</Switch>
          <Switch>{routes.map(RouteFooter)}</Switch>
        </PageTransition>
      </Router>
      <SwitchingUserNotice />
    </ErrorBoundary>
  )
}
