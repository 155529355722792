import React from "react"
import { getCurrentUser } from "../../../../libs/get-current-user"
import { isAuthenticatedFromUser } from "../../../../libs/is-authenticated-from-user"
import { User } from "../../../../rpcs/dist/UserRPC"
import { Authenticated } from "./authenticated"
import { UnAuthenticated } from "./unauthenticated"
import { connect } from "react-redux"
import { RootState } from "../../../../reducers"

interface IProps {
  currentUser: User | null
}

export const Component = ({ currentUser }: IProps) => {
  if (isAuthenticatedFromUser(currentUser)) {
    return <Authenticated currentUser={currentUser!} />
  } else {
    return <UnAuthenticated />
  }
}

const mapStateToProps = ({ loginData, switchingUser }: RootState): IProps => ({
  currentUser: getCurrentUser(loginData, switchingUser),
})

export const HeaderPC = connect(mapStateToProps)(Component)
