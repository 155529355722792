export enum LoginStateAction {
  Changed = "changed",
}

export enum SNSLoginState {
  InitialState = "@LoginState/INITIAL_STATE",
  FaceBookLoginStarted = "@LoginState/FACEBOOK_LOGIN_STARTED",
  FaceBookLoginCallbacked = "@LoginState/FACEBOOK_LOGIN_CALLBACKED",
  TwitterLoginStarted = "@LoginState/TWITTER_LOGIN_STARTED",
  TwitterLoginCallbacked = "@LoginState/TWITTER_LOGIN_CALLBACKED",
}

export const firebaseSnsLoginStateChange = (state: SNSLoginState) =>
  ({
    type: LoginStateAction.Changed,
    data: state,
  } as const)
