import React from "react"
import { Link } from "react-router-dom"
import { Loading } from "../../../../../components/parts/loading"
import { IContainerProps } from "../../../container"
import styled from "styled-components"
import { ButtonWithCharacter } from "../../../../../components/parts/buttons/button-with-character"
import { LiveSchedule } from "./components/live-schedule"
import { Live as LiveNow } from "./components/live"

export const Live = (props: IContainerProps) => {
  const { chatRoom } = props.top.lives

  if (!chatRoom) {
    return <Loading verticalPadding={"15vh"} />
  }

  if (chatRoom.length === 0) {
    return (
      <Link to={"/create_room"}>
        <ButtonWithCharacter
          text={"配信中のチャットはありません"}
          buttonText={"チャットを配信する"}
        />
      </Link>
    )
  }

  const lives = chatRoom.filter(
    c => new Date(c.startedAt!).getTime() <= new Date().getTime(),
  )
  const liveSchedules = chatRoom.filter(
    c => new Date(c.startedAt!).getTime() > new Date().getTime(),
  )

  return (
    <>
      <LiveNow chatRooms={lives} />
      <LiveSchedule chatRooms={liveSchedules} />
    </>
  )
}

export const LiveItemWrapper = styled.div`
  margin-top: -10px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
`
