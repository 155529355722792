import { genKey } from "draft-js"
import uuid from "uuid"
import { ContentWithUser, IGuestMessage } from "../../types/data-types"
import { Block, Types } from "../../rpcs/dist/EpisodeRPC"
import { User } from "../../rpcs/dist/UserRPC"
import { convertOBlocksFromText } from "../converter/block-converter/from-text"

export const CreateContent = {
  Message: (user: User, text: string): ContentWithUser => {
    return {
      uuid: genKey(),
      userId: user.id,
      type: Types.Message,
      user,
      postedAt: new Date().toISOString(),
      blockStyle: [],
      blocks: convertOBlocksFromText(text),
    }
  },
  Annotation: (text: string): ContentWithUser => {
    return {
      uuid: genKey(),
      postedAt: null,
      userId: null,
      type: Types.Annotation,
      blockStyle: [],
      blocks: convertOBlocksFromText(text),
    }
  },
  Introduction: (text: string): ContentWithUser => {
    return {
      uuid: genKey(),
      postedAt: null,
      userId: null,
      type: Types.Introduction,
      blockStyle: [],
      blocks: convertOBlocksFromText(text),
    }
  },
  Pickup: (user: User, pickedUser: User, blocks: Block[]): ContentWithUser => {
    return {
      uuid: genKey(),
      userId: user.id,
      type: Types.Pickup,
      user,
      postedAt: new Date().toISOString(),
      blockStyle: [],
      blocks,
      pickedUserId: pickedUser.id,
      pickedUser,
    }
  },
  Headline: (text: string): ContentWithUser => {
    return {
      uuid: genKey(),
      postedAt: null,
      userId: null,
      type: Types.Headline,
      blockStyle: [],
      blocks: convertOBlocksFromText(text),
    }
  },
  Quotation: (text: string): ContentWithUser => {
    return {
      uuid: genKey(),
      postedAt: null,
      userId: null,
      type: Types.Quotation,
      blockStyle: [],
      blocks: convertOBlocksFromText(text),
      quote: {
        source: "",
        relation: "",
      },
    }
  },
  Survey: (user: User | null, surveyId: number): ContentWithUser => {
    return {
      uuid: genKey(),
      userId: user ? user.id : null,
      type: Types.Survey,
      user,
      postedAt: new Date().toISOString(),
      blockStyle: [],
      blocks: [],
      survey: {
        id: surveyId,
      },
    }
  },
}

export const CreateGuestMessage = (user: User, text: string): IGuestMessage => {
  return {
    uuid: uuid.v4(),
    userId: user.id,
    user,
    postedAt: new Date().toISOString(),
    blockStyle: [],
    blocks: convertOBlocksFromText(text),
  }
}
